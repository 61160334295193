import "twin.macro"
import { gql } from "@apollo/client"
import { capitalize } from "lodash-es"
import pluralize from "pluralize"

import StoreTile from "./StoreTile"

import {
  formatPrice,
  formatPriceMinRange,
  joinWithCommaAnd,
} from "@/common/format"
import { DETAILS_PRODUCT_FRAGMENT } from "@/common/queries"
import { shouldScaleImage } from "@/store/ProductImage"
import { flex_gift_product_price_type } from "@/types/graphql-types"
import { Store_ProductStoreTileFragment } from "@/types/graphql-types"

interface Props {
  product: Store_ProductStoreTileFragment
  onClick: () => void
  href: string
  brandValuesFilterValue?: string[]
  minDisplayPrice: number
  showShippingPrice?: boolean
  showBrandName?: boolean
  isGiftCard?: boolean
}

export default function ProductStoreTile({
  onClick,
  href,
  brandValuesFilterValue,
  product,
  minDisplayPrice,
  showBrandName,
  showShippingPrice,
  isGiftCard,
}: Props) {
  const primaryImageUrl = product?.productImages?.[0]?.imageLarge?.url

  const variantsLabel =
    product.variantGroups && product.variantGroups?.length > 0
      ? capitalize(
          joinWithCommaAnd(
            product.variantGroups.map(({ name }) =>
              pluralize.plural(name.toLowerCase()),
            ),
          ),
        )
      : product.variantsLabel && product.variantsNumSelectable
      ? capitalize(
          `${product.variants.length} ${pluralize.plural(
            product.variantsLabelDowncase || "variant",
          )}`,
        )
      : null

  return (
    <StoreTile
      onClick={onClick}
      href={href}
      imageUrl={primaryImageUrl}
      priceLabel={formattedPrice(product, minDisplayPrice)}
      shippingLabel={getShippingLabel(product)}
      name={product.name}
      subtitle={product.subtitleShort || product.subtitle}
      brandName={showBrandName ? product.brand.name : null}
      brandValues={product.brand.brandValues}
      brandValuesFilterValue={brandValuesFilterValue}
      showShippingPrice={showShippingPrice}
      variantsLabel={variantsLabel}
      isGiftCardProduct={isGiftCard}
      product={product}
      shippingCountryGroup={product.shippingCountryGroup}
      shouldScale={shouldScaleImage(
        product.productImages?.[0]?.imageLarge,
        product.imagesScalable,
      )}
    />
  )
}

const formattedPrice = (
  product: Store_ProductStoreTileFragment,
  _totalPriceMin: number,
) => {
  if (product.isFlexGift) {
    return `${formatPrice(product.price)}+`
  }

  return formatPriceMinRange(product.price, product.price)
}

function getShippingLabel(product: Store_ProductStoreTileFragment) {
  if (
    product.brand.shippingPrice === null ||
    product.brand.shippingPrice === undefined
  ) {
    return null
  }

  const freeShippingMinimumGiftOption =
    (product.brand.freeShippingMinimum &&
      product.price &&
      product.price >= product.brand.freeShippingMinimum) ||
    false

  let shippingLabel = formatPrice(product.brand.shippingPrice)

  if (
    product.isFlexGift &&
    product.swapStoreSettings?.priceType ===
      flex_gift_product_price_type.VARIABLE
  ) {
    shippingLabel = "Included"
  } else if (
    !freeShippingMinimumGiftOption &&
    product.brand.freeShippingMinimum &&
    product.brand.shippingPrice !== 0
  ) {
    shippingLabel = formatPrice(product.brand.shippingPrice) + " or free"
  } else if (
    product.brand.shippingPrice === 0 ||
    freeShippingMinimumGiftOption
  ) {
    shippingLabel = "Free"
  }

  return shippingLabel
}

export const PRODUCT_STORE_TILE_FRAGMENT = gql`
  fragment Store_ProductStoreTile on Product {
    id
    name
    subtitle
    description
    price
    isFlexGift
    slug
    brand {
      id
      name
      brandValues
      shippingPrice
      freeShippingMinimum
      slug
    }
    productImages {
      id
      imageLarge {
        url
        width
        height
      }
    }
    swapStoreSettings {
      priceType
    }
    ...Details_Product
  }

  ${DETAILS_PRODUCT_FRAGMENT}
`
