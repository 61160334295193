import GiftOptionShippingLabel from "./GiftOptionShippingLabel"
import ShippingTag from "../components/shipping/ShippingTag/ShippingTag"
import { useShippingCountriesGroup } from "../hooks/useShippingCountriesGroup"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { GiftOptionType } from "@/store/types"

interface Props {
  giftOption: GiftOptionType
}

const GiftOptionShipping = ({ giftOption }: Props) => {
  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")

  const {
    showShippingTag,
    displayGlobalShippingTag,
    allDomesticCountries,
    multipleCountriesSelected,
  } = useShippingCountriesGroup({
    shippingCountryGroup: giftOption.shippingCountryGroup,
  })

  const swapStoreSettings = giftOption.isFlexGift
    ? giftOption.products[0]?.swapStoreSettings
    : null

  const freeShippingMinimumGiftOption =
    giftOption.brand.freeShippingMinimum &&
    giftOption.priceMin &&
    giftOption.priceMin >= giftOption.brand.freeShippingMinimum

  const shippingPrice = freeShippingMinimumGiftOption
    ? 0
    : giftOption.brand.shippingPrice || 0

  return (
    <>
      {showShippingTag && (
        <div tw="pt-6">
          <ShippingTag
            shippingCountryGroup={giftOption.shippingCountryGroup}
            shippingPrice={shippingPrice}
            withProUpsell={!hasProPlan}
          />
        </div>
      )}

      {!multipleCountriesSelected && (
        <GiftOptionShippingLabel
          giftOption={giftOption}
          swapStorePriceType={swapStoreSettings?.priceType || null}
          withGlobalShipping={displayGlobalShippingTag}
          withDomesticShipping={!!allDomesticCountries?.length}
        />
      )}
    </>
  )
}

export default GiftOptionShipping
