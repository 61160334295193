import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from "react"
import tw from "twin.macro"

import CountryListFilterButton from "./components/CountryListFilterButton"
import CountryListSelector from "./components/CountryListSelector/CountryListSelector"
import { sortCountries } from "../utils/sortCountries"

import { useToggle } from "@/common/hooks/useToogle"
import TractorBeam from "@/common/TractorBeam"
import TransitionHideable from "@/common/TransitionHideable"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  countries: Record<"domestic" | "global", ShippingCountry[]>
  withProUpsell?: boolean
  withinScrollable?: boolean
  selectedCountries: ShippingCountry[]
  setSelectedCountries: Dispatch<SetStateAction<ShippingCountry[]>>
}

const ShippingCountryListFilter = ({
  countries,
  selectedCountries,
  setSelectedCountries,
  withProUpsell,
  withinScrollable = false,
}: Props) => {
  const ref = useRef(null)
  const [open, toggleOpen, setOpen] = useToggle(false)

  // If loaded with #global, then trigger open and remove hash
  useEffect(() => {
    if (window.location.hash === "#global") {
      setOpen(true)

      // This component can be rendered multiple times, especially when using
      // StrictMode. This causes the hash to be removed and not available on
      // the next render. So, only clear it after a delay.
      setTimeout(() => {
        if (window.location.hash === "#global") {
          window.history.replaceState({}, "", window.location.pathname)
        }
      }, 500)
    }
  }, [])

  const onSelectSingleCountry = (country: ShippingCountry) => {
    setSelectedCountries([country])
    setOpen(false)
  }

  const onSelectMultipleCountries = (country: ShippingCountry) => {
    setSelectedCountries((countries) => {
      if (countries.map((c) => c.code).includes(country.code)) {
        // If only one country is selected, don't allow to deselect it
        if (countries.length === 1) return countries

        return countries.filter((c) => c.code !== country.code)
      }

      const newCountries = [...new Set([...countries, country])]
      const domesticCountries = newCountries.filter((country) =>
        groupedCountries.domestic.includes(country),
      )
      const globalCountries = newCountries.filter((country) =>
        groupedCountries.global.includes(country),
      )

      return [
        ...sortCountries(domesticCountries),
        ...sortCountries(globalCountries),
      ]
    })
  }

  const groupedCountries = useMemo(
    () => ({
      domestic: sortCountries(countries.domestic || []),
      global: sortCountries(countries.global || []),
    }),
    [countries],
  )

  const countryListProps = {
    groupedCountries,
    selectedCountries,
    onSelectSingleCountry,
    onSelectMultipleCountries,
    withProUpsell,
  }

  return (
    <>
      <div
        css={[
          tw`fixed top-0 right-0 bg-white z-10`,
          open ? tw`opacity-40 h-screen w-screen` : tw`opacity-0 h-0 w-0`,
          tw`transition-opacity duration-500 ease-out`,
        ]}
        onClick={() => setOpen(false)}
      />
      <div ref={ref}>
        <TractorBeam anchor={ref} withinScrollable={withinScrollable}>
          <TransitionHideable
            hidden={!open}
            twBase={[
              tw`transition-all duration-200 ease-out`,
              tw`absolute w-max top-[calc(100% + 8px)] right-0`,
              tw`z-[1300]`,
            ]}
            twHidden={[tw`opacity-0 scale-90`]}
            twVisible={tw`opacity-100 scale-100`}
          >
            <CountryListSelector {...countryListProps} />
          </TransitionHideable>
        </TractorBeam>
        <CountryListFilterButton
          countries={selectedCountries}
          open={open}
          onOpen={toggleOpen}
        />
      </div>
    </>
  )
}

export default ShippingCountryListFilter
