import { useState } from "react"
import ReactPlaceholder from "react-placeholder"
import tw from "twin.macro"

import GiftOptionProducts from "./GiftOptionProducts"
import { isFullData } from "./utils/isFullData"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { DelayedComponent } from "@/store/components/DelayedComponent"
import { GalleryPlaceholder } from "@/store/components/GalleryPlaceholder"
import { Store_GiftOptionPreviewFragment } from "@/types/graphql-types"

type ProductMode = "default" | "wide" | "side"

interface GiftOptionProductDisplayProps {
  giftOption: Store_GiftOptionPreviewFragment
  priceFilterMin: number
  priceFilterMax: number | null
  loading?: boolean
}
function GiftOptionProductDisplay({
  giftOption,
  priceFilterMin,
  priceFilterMax,
  loading,
}: GiftOptionProductDisplayProps) {
  const defaultProductMode: ProductMode = "wide"
  const [giftOptionProductMode, setGiftOptionProductMode] =
    useState<ProductMode>(defaultProductMode)

  const { hasFeature } = useFeatureAccess()

  if (!loading && giftOption.products.length === 0) {
    return (
      <div tw="pt-16 flex flex-col items-center gap-3 text-base font-normal">
        <div tw="text-black">
          No products were found that ship to all of your selected countries.
        </div>
        <div tw="text-gray-500">Try selecting fewer countries.</div>
      </div>
    )
  }

  if (loading || !isFullData(giftOption)) {
    return (
      <div tw="pt-0 pb-24 px-4 pt-12">
        <div tw="px-1 lg:px-10">
          <div tw="grid lg:p-8 lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:p-0 gap-x-10 gap-y-14">
            <DelayedComponent waitBeforeShow={100}>
              <ReactPlaceholder
                showLoadingAnimation={true}
                ready={false}
                customPlaceholder={<GalleryPlaceholder cols={3} rows={2} />}
              >
                <></>
              </ReactPlaceholder>
            </DelayedComponent>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div tw="pt-0 pb-24">
      <GiftOptionProducts
        giftOption={giftOption}
        priceFilterMin={priceFilterMin}
        priceFilterMax={priceFilterMax}
      />
      {hasFeature("gift_option_product_revamp") && (
        <div tw="px-4 py-8 flex flex-row items-center justify-center">
          {["default", "wide", "side"].map((setting) => (
            <button
              onClick={() =>
                setGiftOptionProductMode(setting as "default" | "wide" | "side")
              }
              tw="px-4 py-1"
              css={[
                tw`capitalize text-gray-600 transition-colors hover:bg-gray-100 rounded-full mx-1`,
                giftOptionProductMode === setting &&
                  tw`bg-gray-200 text-gray-900`,
              ]}
            >
              {setting}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default GiftOptionProductDisplay
