import { defaults } from "lodash-es"
import { Dispatch, SetStateAction } from "react"
import { useHistory } from "react-router-dom"
import { Location } from "react-router-dom-v5-compat"

import useStoreContext from "./hooks/useStoreContext"
import { Product } from "../common/GlobalState"
import {
  PathRealm,
  extractBasePath,
  generateRealmPath,
  getRealmFromPath,
  getSegmentFromRealm,
} from "../common/realm"

import { ProductListFragment } from "@/types/graphql-types"

export const CART_TAGS_STORE_V2 = "store_v2"

export interface GenerateBrowseUrlParams {
  categorySlug?: string | null
  giftOptionSlug?: string | null
  pathRealm?: PathRealm
  categoryIsFeatured?: boolean | null
  categoryIsCustom?: boolean | null
  customSlug?: string | null
  ignoreCategory?: boolean
}

export interface GeneratePdpUrlParams {
  productSlug?: string | null
  brandSlug?: string | null
  pathRealm?: PathRealm
}

// There are two ways to go a PDP - one instance through a Gift Option (where we have the full fragment)
// and one where we just have the URL (product slug and brand slug). This type holds the data for both ways.
export type ProductData =
  | ProductListFragment
  | {
      slug: string
      brand: {
        slug: string
      }
    }

const BROWSE_REGEX =
  /\/browse(?:(?:\/category\/(?<categorySlug>[a-zA-z0-9-]+))?(\/gift-option\/(?<giftOptionSlug>[a-zA-z0-9-]+))?|\/brands\/(?<brandSlug>[a-zA-z0-9-]+)\/(?<productSlug>[a-zA-z0-9-]+))?/

// Hook to pull and generate urls for store/browse page
// virtualLocationFromParam and setVirtualLocationFromParam can be passed in if
// the hook is being used outside of StoreContextProvider (e.g. StoreV2 itself)
export const useBrowseUrls = (
  useVirtualUrlFromParam = false,
  virtualLocationFromParam?: Location,
  setVirtualLocationFromParam?: Dispatch<SetStateAction<Location<any>>>,
) => {
  const history = useHistory()
  const serchParams = new URLSearchParams(history.location.search)

  const {
    virtualLocation: virtualLocationFromContext,
    setVirtualLocation: setVirtualLocationFromContext,
    useVirtualUrl: useVirtualUrlFromContext,
  } = useStoreContext()

  const useVirtualUrl = useVirtualUrlFromContext || useVirtualUrlFromParam
  const virtualLocation = virtualLocationFromParam || virtualLocationFromContext
  const setVirtualLocation =
    setVirtualLocationFromParam || setVirtualLocationFromContext

  function setVirtualPath(path: string) {
    setVirtualLocation({
      pathname: path,
      key: parameterize(path),
      state: null,
      search: "",
      hash: "",
    })
  }

  const getPath = () => {
    if (useVirtualUrl) {
      return virtualLocation.pathname
    } else {
      return history.location.pathname
    }
  }

  const pushPath = (path: string, state?: Record<string, string>) => {
    if (useVirtualUrl) {
      setVirtualPath(path)
    } else {
      history.push({
        pathname: path,
        search: serchParams.toString(),
        ...state,
      })
    }
  }

  const replacePath = (path: string) => {
    if (useVirtualUrl) {
      setVirtualPath(path)
    } else {
      history.replace({
        pathname: path,
        search: serchParams.toString(),
      })
    }
  }

  const getPartsFromUrl = (): {
    categorySlug: null | string
    giftOptionSlug: null | string
    brandSlug: null | string
    productSlug: null | string
  } =>
    defaults(getPath()?.match(BROWSE_REGEX)?.groups ?? {}, {
      categorySlug: null,
      giftOptionSlug: null,
      brandSlug: null,
      productSlug: null,
    })

  const isGiftOptionUrl = () => {
    const parts = getPartsFromUrl()
    return !!parts.giftOptionSlug
  }

  const isPDPUrl = () => {
    const parts = getPartsFromUrl()
    return !!parts.brandSlug && !!parts.productSlug
  }

  const isBrowseUrl = (path = extractBasePath(getPath())) =>
    !!path.match(/^\/browse(\/.+)?$/)

  const isCustomSelected = (path = extractBasePath(getPath())) =>
    !!path.match(/^\/browse\/custom/)

  const isSearchSelected = (path = extractBasePath(getPath())) =>
    !!path.match(/^\/browse\/search/)

  const customPath = (path = extractBasePath(getPath())) =>
    path.match(/^\/browse\/custom\/(?<path>.*)$/)?.groups?.path

  // Generate a url based on slugs/ids
  // If we pass in null, we unset the slug/id. If we
  // don't pass in, we resuse whatever slug/id currently in the url.
  const generateBrowseUrl = ({
    categorySlug,
    giftOptionSlug,
    customSlug,
    categoryIsFeatured = false,
    categoryIsCustom = false,
    pathRealm = getRealmFromPath(getPath()),
    ignoreCategory = false,
  }: GenerateBrowseUrlParams) => {
    let url = "/browse"

    const parts = getPartsFromUrl()

    if (categoryIsCustom) {
      url = [url, "custom", customSlug].filter(Boolean).join("/")
      return generateRealmPath(pathRealm, url)
    }

    if (categorySlug !== null && !categoryIsFeatured && !ignoreCategory) {
      // prioritize slug over id
      const categoryPart = categorySlug ?? parts.categorySlug
      if (categoryPart) {
        url += `/category/${categoryPart.toLowerCase()}`
      }
    }

    if (giftOptionSlug !== null) {
      // prioritize slug over id
      const giftOptionPart = giftOptionSlug ?? parts.giftOptionSlug
      if (giftOptionPart) {
        url += `/gift-option/${giftOptionPart.toLowerCase()}`
      }
    }
    // Preserve realm for path
    return generateRealmPath(pathRealm, url)
  }

  const generatePdpUrl = ({
    productSlug,
    brandSlug,
    pathRealm = getRealmFromPath(getPath()),
  }: GeneratePdpUrlParams) => {
    let url = "/browse"
    if (productSlug && brandSlug) {
      url += `/brands/${brandSlug}/${productSlug}`
    }

    return generateRealmPath(pathRealm, url)
  }

  const generateProductUrl = (product: Product) => {
    const isGiftCardOrFlexGift =
      product.brandName === "Gift Cards" || product.isFlexGift

    // Store V2: Gift cards and flex gifts in Store V2 link to the PDP
    if (
      isGiftCardOrFlexGift &&
      product.cartTags?.includes(CART_TAGS_STORE_V2)
    ) {
      return generatePdpUrl({
        productSlug: product.slug,
        brandSlug: product.brandSlug,
      })
    }

    return !product.cartEligible || product.isBook
      ? generateBrowseUrl({ giftOptionSlug: product.giftOptionSlug })
      : generatePdpUrl({
          productSlug: product.slug,
          brandSlug: product.brandSlug,
        })
  }

  return {
    getPartsFromUrl,
    generateBrowseUrl,
    generatePdpUrl,
    generateProductUrl,
    getPath,
    replacePath,
    pushPath,
    isPDPUrl,
    isGiftOptionUrl,
    isBrowseUrl,
    isCustomSelected,
    isSearchSelected,
    customPath,
    virtualLocation,
  }
}

function parameterize(str: string) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^a-zA-Z0-9-]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "")
}

// Generate a synthetic location object for use with react-router in the shape
// of the Location interface
export function generateSyntheticLocation(pathname: string) {
  return {
    pathname: pathname,
    key: parameterize(pathname),
    state: {},
    search: "",
    hash: "",
  }
}

// To lookup the featured category in our category lookup table
const IS_FEATURED_CATEGORY_KEY = "**is-featured**-no-match-slug-no-match-id"

export const getFeaturedCategoryKey = (realm: PathRealm) =>
  `**${getSegmentFromRealm(realm)}**${IS_FEATURED_CATEGORY_KEY}`

export const generateMetaDescription = (
  brandName?: string,
  description?: string | null,
) => {
  let meta = `Gift${
    brandName ? ` ${brandName}` : ""
  } with only an email or phone number.`
  if (description) {
    meta = `${meta} ${description}`
  }

  return meta
}
