import { useQuery } from "@apollo/client"
import { keyBy, pick } from "lodash-es"
import { useCallback, useMemo } from "react"

import { createHookContext } from "./createHookContext"
import { SHIPPING_COUNTRIES_QUERY } from "../queries/ShippingCountriesQuery"

import {
  CountryCodeEnum,
  ShippingCountriesQuery,
  ShippingCountriesQueryVariables,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

export const DEFAULT_COUNTRY = {
  code: CountryCodeEnum.US,
  name: "United States",
  groups: [ShippingCountryGroupEnum.domestic],
}
const GLOBAL_COUNTRY = {
  code: CountryCodeEnum.GLOBAL,
  name: "Global",
  groups: [ShippingCountryGroupEnum.global, ShippingCountryGroupEnum.gift_card],
}

const useShippingCountriesLocal = () => {
  const { data } = useQuery<
    ShippingCountriesQuery,
    ShippingCountriesQueryVariables
  >(SHIPPING_COUNTRIES_QUERY)

  const shippingCountries = useMemo(
    () =>
      data?.shippingCountries
        ? [...data.shippingCountries, GLOBAL_COUNTRY]
        : [DEFAULT_COUNTRY],
    [data],
  )

  const indexedCountries = useMemo(
    () => keyBy(shippingCountries, "code"),
    [shippingCountries],
  )

  const findCountryByCode = useCallback(
    (code?: CountryCodeEnum) => indexedCountries[code || ""],
    [indexedCountries],
  )

  const findCountryByCodes = useCallback(
    (codes?: CountryCodeEnum[]) => codes?.map((code) => indexedCountries[code]),
    [indexedCountries],
  )

  const expandShippingCountries = useCallback(
    (countryCodes: CountryCodeEnum[]) => {
      return Object.values(pick(indexedCountries, countryCodes))
    },
    [indexedCountries],
  )

  return {
    shippingCountries,
    expandShippingCountries,
    findCountryByCode,
    findCountryByCodes,
  }
}

export const {
  Provider: ShippingCountriesProvider,
  useHook: useShippingCountries,
} = createHookContext("useShippingCountries", useShippingCountriesLocal)
