import CountryGroupOption from "./components/CountryGroupOption"
import LeafIconSrc from "./icons/leaf.svg"
import { sortCountries } from "../utils/sortCountries"

import { CountryFlag } from "@/common/CountryFlag"
import { formatPrice } from "@/common/format"
import { CountryCodeEnum, ShippingCountry } from "@/types/graphql-types"

interface Props {
  shippingPrice: number
  domesticCountries: ShippingCountry[]
  globalCountries: ShippingCountry[]
  withProUpsell: boolean
  onDomesticOptionClick?: () => void
  onGlobalOptionClick?: () => void
}

const MultipleCountriesShippingTag = ({
  domesticCountries,
  globalCountries,
  shippingPrice,
  withProUpsell,
  onDomesticOptionClick,
  onGlobalOptionClick,
}: Props) => {
  const formattedPrice =
    shippingPrice === 0 ? "Free" : formatPrice(shippingPrice)

  const hasDomesticCountries = domesticCountries.length > 0
  const hasGlobalCountries = globalCountries.length > 0

  return (
    <div tw="bg-white border border-gray-200 rounded-lg text-sm font-medium overflow-hidden">
      {hasDomesticCountries && (
        <CountryGroupOption
          tw="text-[#219653]"
          title="Domestic shipping"
          description={`${formattedPrice} shipping`}
          icon={<img src={LeafIconSrc} alt="leaf icon" />}
          countries={sortCountries(domesticCountries)}
          onClick={onDomesticOptionClick}
        />
      )}

      {hasDomesticCountries && hasGlobalCountries && (
        <div tw="h-px bg-gray-200" />
      )}

      {hasGlobalCountries && (
        <CountryGroupOption
          tw="text-gray-600"
          title={`Ships globally${withProUpsell ? " with Pro" : ""}`}
          description={`${formattedPrice} shipping + up to $50 in duties, taxes, and freight`}
          icon={<CountryFlag code={CountryCodeEnum.GLOBAL} size="m" />}
          countries={sortCountries(globalCountries)}
          onClick={onGlobalOptionClick}
        />
      )}
    </div>
  )
}

export default MultipleCountriesShippingTag
