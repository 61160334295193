import { useRef, useState } from "react"
import tw, { styled } from "twin.macro"

import { CountryFlag } from "@/common/CountryFlag"
import TractorBeam from "@/common/TractorBeam"
import TransitionHideable from "@/common/TransitionHideable"
import { useShippingCountriesSelector } from "@/store/hooks/useShippingCountriesSelector"
import { CountryCodeEnum } from "@/types/graphql-types"

interface Props {
  domesticCountryCodes: CountryCodeEnum[]
  hidden?: boolean
  children: React.ReactNode
}

const DomesticCountriesPopover = ({
  domesticCountryCodes,
  hidden,
  children,
}: Props) => {
  const { findCountryByCodes, selectedShippingCountryCodes } =
    useShippingCountriesSelector()

  const ref = useRef(null)

  const [open, setOpen] = useState(false)

  const multipleCountries = domesticCountryCodes.length > 1

  const domesticCountrySelected =
    selectedShippingCountryCodes.filter((code) =>
      domesticCountryCodes.includes(code),
    ).length > 0

  if (!domesticCountrySelected) return null

  if (hidden) return <>{children}</>

  const domesticCountries = findCountryByCodes(domesticCountryCodes)

  const openOnHover = () => multipleCountries && setOpen(true)
  const closeOnHover = () => multipleCountries && setOpen(false)

  return (
    <div ref={ref}>
      <TractorBeam anchor={ref} withinScrollable>
        <TransitionHideable
          hidden={!open}
          twBase={[
            tw`transition-all duration-200 ease-out origin-top`,
            tw`absolute w-max top-[calc(100% + 4px)] left-0`,
            tw`z-[1500]`,
          ]}
          twHidden={[tw`opacity-0 scale-90`]}
          twVisible={tw`opacity-100 scale-100`}
        >
          <DropdownContainer
            tw="flex flex-col gap-3 w-[350px]"
            onMouseEnter={openOnHover}
            onMouseLeave={closeOnHover}
          >
            <p tw="text-sm font-medium">Ships domestically to</p>

            <div tw="flex flex-col gap-2">
              {domesticCountries?.map((country) => (
                <div tw="flex gap-3 items-center" key={country.code}>
                  <CountryFlag code={country.code} size="m" />
                  <span tw="text-base font-normal">{country.name}</span>
                </div>
              ))}
            </div>

            <p tw="text-sm font-medium text-gray-500">
              All countries have the same shipping price
            </p>
          </DropdownContainer>
        </TransitionHideable>
      </TractorBeam>

      <div tw="w-max" onMouseEnter={openOnHover} onMouseLeave={closeOnHover}>
        {children}
      </div>
    </div>
  )
}

const DropdownContainer = styled.div`
  ${tw`p-5 rounded-xl border border-gray-150 bg-white`}

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.02), 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
`

export default DomesticCountriesPopover
