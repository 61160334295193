import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react"

import { useShippingCountriesSelector } from "./hooks/useShippingCountriesSelector"
import { useCurrentGift } from "../common/hooks/currentGift"
import {
  MAX_CART_ITEMS,
  MAX_CART_PRODUCTS,
  useGiftCart,
} from "../common/hooks/giftData"

import { useAlert } from "@/common/providers/AlertProvider"
import {
  CountryCodeEnum,
  ShippingCountryGroupFragment,
} from "@/types/graphql-types"

// Couldn't get TS working
// @ts-ignore
const CartAddContext = createContext<{
  cartReplaceModalOpen: boolean
  cartReplaceModalExplanation: string
  onAddItemCancelled: () => void
  onAddItemConfirmed: () => void
  requestAddItem: (params: {
    cartEligible: boolean
    callback: () => void
    itemName: string
    shippingCountryGroup?: ShippingCountryGroupFragment
  }) => void
  cartItemName: string
}>()

export const CartAddProvider = ({ children }: { children: ReactNode }) => {
  const [cartReplaceModalOpen, setCartReplaceModalOpen] = useState(false)
  const [cartReplaceModalExplanation, setCartReplaceModalExplanation] =
    useState("")
  const [cartReplaceCallback, setCartReplaceCallback] = useState<
    null | (() => void)
  >(null)
  const [cartItemName, setCartItemName] = useState("")

  const [currentGift] = useCurrentGift()
  const { canAddProductToCart, cartIsMaxQuantity, canShipNewProduct } =
    useGiftCart()
  const { selectedShippingCountryCodes } = useShippingCountriesSelector()
  const { openAlert } = useAlert()

  const requestAddItem = useCallback(
    ({
      cartEligible,
      callback,
      itemName,
      shippingCountryGroup,
    }: {
      cartEligible: boolean
      callback: () => void
      itemName: string
      shippingCountryGroup?: ShippingCountryGroupFragment
    }) => {
      const cartCanShipNewProduct =
        shippingCountryGroup && !canShipNewProduct(shippingCountryGroup)
      const shipsToDomesticCountries = shippingCountryGroup?.domestic?.filter(
        (code) => code !== CountryCodeEnum.US,
      ).length
      const addingMoreGlobalRelayProducts =
        currentGift.cart.length >= 1 &&
        shippingCountryGroup?.globalRelay?.some((code) =>
          selectedShippingCountryCodes.includes(code),
        )

      if (
        (cartEligible && canAddProductToCart) ||
        currentGift.cart.length === 0
      ) {
        if (cartIsMaxQuantity) {
          window.alert(
            `${MAX_CART_PRODUCTS} products and ${MAX_CART_ITEMS} quantity maximum can be sent in a gift.`,
          )
        } else if (cartCanShipNewProduct) {
          if (shipsToDomesticCountries) {
            setCartReplaceModalExplanation(
              "Items that ship domestically can't be combined with other shipping methods",
            )
          } else {
            setCartReplaceModalExplanation(
              "The other items in your cart use different shipping methods than this product.",
            )
          }
          setCartItemName(itemName)
          setCartReplaceModalOpen(true)
          setCartReplaceCallback(() => callback)
        } else if (addingMoreGlobalRelayProducts) {
          openAlert({
            variant: "warning",
            title:
              "Only one Global Shipping item can be sent per gift at this time",
            content: (
              <>
                Do you want to add
                <span tw="font-semibold"> {itemName}</span>
                <br /> anyway to use US shipping?
              </>
            ),
            cancel: {
              text: "No, go back",
            },
            confirm: {
              text: "Yes, add anyway",
              onClick: callback,
            },
          })
        } else {
          callback()
        }
      } else {
        setCartReplaceModalExplanation(
          "Alcohol, gift cards, custom stores, and gift collections can only be sent on their own.",
        )
        setCartItemName(itemName)
        setCartReplaceModalOpen(true)
        setCartReplaceCallback(() => callback)
      }
    },
    [
      setCartReplaceModalOpen,
      setCartReplaceCallback,
      setCartItemName,
      canShipNewProduct,
      currentGift.cart.length,
      canAddProductToCart,
      cartIsMaxQuantity,
      selectedShippingCountryCodes,
      openAlert,
    ],
  )

  const onAddItemConfirmed = useCallback(() => {
    setCartReplaceModalOpen(false)
    if (cartReplaceCallback) {
      cartReplaceCallback()
      setCartReplaceCallback(null)
    }
  }, [setCartReplaceModalOpen, setCartReplaceCallback, cartReplaceCallback])

  const onAddItemCancelled = useCallback(() => {
    setCartReplaceModalOpen(false)
    setCartReplaceCallback(null)
  }, [setCartReplaceModalOpen, setCartReplaceCallback])

  const value = {
    cartReplaceModalOpen,
    cartReplaceModalExplanation,
    onAddItemCancelled,
    onAddItemConfirmed,
    requestAddItem,
    cartItemName,
  }

  return (
    <CartAddContext.Provider value={value}>{children}</CartAddContext.Provider>
  )
}

export const useCartAdd = () => {
  const context = useContext(CartAddContext)

  if (context === undefined) {
    throw new Error("useCartAdd must be used within UIContext")
  }

  return context
}
