import * as Sentry from "@sentry/react"
import { compact, pick } from "lodash-es"
import { useCallback, useMemo } from "react"

import { useGiftCart } from "@/common/hooks/giftData"
import { useShippingCountries } from "@/common/hooks/useShippingCountries"
import { findShippingGroupIntersection } from "@/common/utils/findShippingGroupIntersection"
import {
  CountryCodeEnum,
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

export const useGiftShippingCountries = () => {
  const { currentGift, shippingClasses, hasFlexGiftItem, hasCustomStore } =
    useGiftCart()
  const { shippingCountries, expandShippingCountries } = useShippingCountries()

  const tier = currentGift.internationalShippingTier
  const swapType = currentGift.swapType

  const domesticCountryCodes = useMemo(() => {
    return shippingCountries
      .filter((country) =>
        country.groups.includes(ShippingCountryGroupEnum.domestic),
      )
      .map((country) => country.code)
  }, [shippingCountries])

  const fullGlobalCountryCodes = useMemo(() => {
    return shippingCountries
      .filter((country) =>
        country.groups.includes(ShippingCountryGroupEnum.global),
      )
      .map((country) => country.code)
  }, [shippingCountries])

  const shippingGroup = useMemo(() => {
    try {
      const shippingCountryGroups = currentGift.cart.map(
        (product) => product.shippingCountryGroup,
      )
      return findShippingGroupIntersection(shippingCountryGroups)
    } catch (error) {
      // This is failing when the user already had a product added to the bag
      // that was saved before the new shipping country groups were added.
      Sentry.captureException(error)
      window.localStorage?.removeItem("__goody_send_autosave")
      return {}
    }
  }, [currentGift.cart])

  const shipsToCountries = useMemo(() => {
    if (hasFlexGiftItem || hasCustomStore) {
      switch (tier) {
        case InternationalShippingTierEnum.disabled:
          return domesticCountryCodes.filter(
            (code) => code === CountryCodeEnum.US,
          )
        case InternationalShippingTierEnum.standard:
          return domesticCountryCodes
        case InternationalShippingTierEnum.full:
          return [...domesticCountryCodes, CountryCodeEnum.GLOBAL]
      }
    }

    const shipToIntersection = pick(shippingGroup, shippingClasses)

    if (shipToIntersection?.globalRelay?.length > 0) {
      shipToIntersection.globalRelay = [CountryCodeEnum.GLOBAL]
    }

    return Object.values(shipToIntersection).flat().sort().reverse()
  }, [
    shippingGroup,
    shippingClasses,
    domesticCountryCodes,
    hasFlexGiftItem,
    hasCustomStore,
    tier,
  ])

  const cartIsGloballyEligible = shippingGroup?.globalRelay?.length > 0

  const swapsToCountries = useMemo(() => {
    if (
      swapType === GiftSwapTypeEnum.swap_disabled ||
      hasFlexGiftItem ||
      hasCustomStore
    ) {
      return []
    }

    const swapsToDomesticCountries = domesticCountryCodes.filter(
      (code) => !shipsToCountries.includes(code),
    )

    if (tier === InternationalShippingTierEnum.standard) {
      return swapsToDomesticCountries
    }

    if (
      tier === InternationalShippingTierEnum.full &&
      !cartIsGloballyEligible
    ) {
      return [...swapsToDomesticCountries, CountryCodeEnum.GLOBAL]
    }

    return []
  }, [
    tier,
    swapType,
    cartIsGloballyEligible,
    shipsToCountries,
    domesticCountryCodes,
    hasFlexGiftItem,
    hasCustomStore,
  ])

  const shipsToGlobalCountries = useMemo(
    () =>
      (shippingGroup?.globalRelay ?? []).filter(
        (code) =>
          code !== CountryCodeEnum.GLOBAL && !shipsToCountries.includes(code),
      ),
    [shippingGroup, shipsToCountries],
  )
  const swapsToGlobalCountries = useMemo(() => {
    return fullGlobalCountryCodes.filter(
      (code) =>
        code !== CountryCodeEnum.GLOBAL &&
        !shipsToGlobalCountries.includes(code),
    )
  }, [shipsToGlobalCountries, fullGlobalCountryCodes])

  const parseCountriesToSummaryText = useCallback(
    (countryCodes: CountryCodeEnum[]) => {
      const countryNames = expandShippingCountries(countryCodes).map(
        (country) =>
          country.code === CountryCodeEnum.US
            ? CountryCodeEnum.US
            : country.name,
      )
      return new Intl.ListFormat().format(countryNames)
    },
    [expandShippingCountries],
  )

  const shippingSummaryText = useMemo(() => {
    switch (tier) {
      case InternationalShippingTierEnum.disabled:
        return "Disabled"
      case InternationalShippingTierEnum.standard:
        return compact([
          parseCountriesToSummaryText(shipsToCountries),
          parseCountriesToSummaryText(swapsToCountries),
        ]).join(" + swap options to ")
      case InternationalShippingTierEnum.full:
        return "Global"
      default:
        return null
    }
  }, [tier, shipsToCountries, swapsToCountries, parseCountriesToSummaryText])

  const shipsToCountriesForStandardTier = useMemo(() => {
    if (hasFlexGiftItem) {
      return domesticCountryCodes
    }

    const countries = (shippingGroup?.["domestic"] || []).sort().reverse()

    if (countries.length === 0) {
      return domesticCountryCodes
    }

    return countries
  }, [hasFlexGiftItem, shippingGroup, domesticCountryCodes])

  const swapsToCountriesForStandardTier = useMemo(() => {
    if (swapType === GiftSwapTypeEnum.swap_disabled || hasFlexGiftItem) {
      return []
    }

    return domesticCountryCodes.filter(
      (code) => !shipsToCountriesForStandardTier.includes(code),
    )
  }, [
    swapType,
    shipsToCountriesForStandardTier,
    domesticCountryCodes,
    hasFlexGiftItem,
  ])

  const standardTierMessage = useMemo(() => {
    let shipsToCountryListText = parseCountriesToSummaryText(
      shipsToCountriesForStandardTier,
    )
    if (shipsToCountryListText === "US") {
      shipsToCountryListText = "the US"
    }

    const swapsToCountryListText = parseCountriesToSummaryText(
      swapsToCountriesForStandardTier,
    )

    const shipsToText = `Domestic shipping to ${shipsToCountryListText}`
    const swapsToText = swapsToCountryListText
      ? `, and swap options available for ${swapsToCountryListText}`
      : ""

    return `${shipsToText}${swapsToText}. No additional fees.`
  }, [
    shipsToCountriesForStandardTier,
    swapsToCountriesForStandardTier,
    parseCountriesToSummaryText,
  ])

  return {
    shipsToCountries,
    swapsToCountries,
    shippingSummaryText,
    cartIsGloballyEligible,
    shipsToGlobalCountries,
    swapsToGlobalCountries,
    standardTierMessage,
  }
}
