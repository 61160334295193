import { intersection } from "lodash-es"
import tw from "twin.macro"

import { DomesticShippingTag } from "../DomesticShippingTag"
import { GlobalShippingTag } from "../GlobalShippingTag"

import ToggleSwitch from "@/common/components/ToggleSwitch"
import { CountryFlag } from "@/common/CountryFlag"
import {
  CountryCodeEnum,
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

interface Props {
  selectedCountries: ShippingCountry[]
  domesticOnly: boolean
  toogleDomesticOnly: () => void
}

export const ShippingOptionsBanner = ({
  selectedCountries,
  domesticOnly,
  toogleDomesticOnly,
}: Props) => {
  const multipleCountries = selectedCountries.length > 1
  const countryName = multipleCountries
    ? "these countries"
    : selectedCountries[0].name

  const onlyGlobalCountrySelected =
    !multipleCountries && selectedCountries[0].code === CountryCodeEnum.GLOBAL

  const includeGroup = (group: ShippingCountryGroupEnum) => {
    return intersection(
      ...selectedCountries.map((country) => country.groups),
    ).includes(group)
  }

  const domesticExplanation = `Denotes gifts that ship domestically within ${countryName}. Cheaper, faster, and more environmentally friendly.`

  const includeDomesticGroup = includeGroup(ShippingCountryGroupEnum.domestic)
  const includeGlobalGroup =
    includeGroup(ShippingCountryGroupEnum.global) || multipleCountries

  const nonUSCountry =
    selectedCountries.find((country) => country.code !== CountryCodeEnum.US) ||
    selectedCountries[0]

  return (
    <div tw="grid grid-cols-1 gap-12 md:gap-6 md:grid-cols-3 items-start justify-start rounded-xl border border-solid border-gray-150 p-7">
      <div tw="flex flex-col gap-3">
        <span tw="text-base text-gray-500 font-medium">
          {!multipleCountries && includeDomesticGroup
            ? "Shipping options for"
            : `Showing gifts that ship to ${multipleCountries ? "all of" : ""}`}
        </span>
        <div tw="flex flex-col gap-4">
          {selectedCountries.map((country) => (
            <div tw="flex items-center gap-3" key={country.code}>
              <CountryFlag code={country.code} size="l" />
              <span tw="text-2xl text-gray-900 font-normal">
                {country.name}
              </span>
            </div>
          ))}
        </div>
      </div>

      {includeDomesticGroup && (
        <div css={[tw`flex flex-col`, !includeGlobalGroup && tw`col-span-2`]}>
          <DomesticShippingTag country={nonUSCountry} />
          <Text css={[tw`mt-4`, includeGlobalGroup && tw`max-w-[290px]`]}>
            {domesticExplanation}
          </Text>

          <div tw="flex flex-row items-center gap-3 mt-3">
            <ToggleSwitch
              width={32}
              height={20}
              isOn={domesticOnly}
              onClick={toogleDomesticOnly}
            />
            <div tw="text-sm text-gray-450 font-normal">
              Show only domestic shipping gifts
            </div>
          </div>
        </div>
      )}

      {includeGlobalGroup && (
        <div css={[tw`flex flex-col`, !includeDomesticGroup && tw`col-span-2`]}>
          <GlobalShippingTag />
          <Text css={[tw`mt-4`, includeDomesticGroup && tw`max-w-[310px]`]}>
            Ships cross-border from the United States to{" "}
            {onlyGlobalCountrySelected ? (
              <span tw="text-gray-700 font-semibold underline">
                global countries
              </span>
            ) : (
              countryName
            )}
            . Import duties, tax, and freight apply, up to $50 (Goody pays for
            any remainder over $50).
          </Text>
        </div>
      )}

      {!includeGlobalGroup &&
        includeGroup(ShippingCountryGroupEnum.gift_card) && (
          <div tw="col-span-2 self-center">
            <Text>Goody supports gift cards for {countryName}.</Text>
          </div>
        )}
    </div>
  )
}

const Text = tw.span`text-base text-gray-600 font-normal`
