import { gql } from "@apollo/client"

import { GIFT_OPTION_DATA_FRAGMENT } from "./GiftOptionDataFragment"

export const GIFT_OPTION_BY_SLUG_QUERY = gql`
  query Store_GiftOption_GiftOptionSlug(
    $slug: String!
    $shippingCountries: [CountryCodeEnum!]
  ) {
    giftOption(slug: $slug) {
      ...Store_GiftOptionData
    }
    inactiveGiftOption: giftOption(
      slug: $slug
      filters: { status: [INACTIVE] }
    ) {
      id
      slug
    }
  }
  ${GIFT_OPTION_DATA_FRAGMENT}
`
