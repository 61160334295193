import tw from "twin.macro"

import GiftCardProBanner from "./GiftCardProBanner"

import { track } from "@/common/analytics"
import useStoreContext from "@/store/hooks/useStoreContext"
import { StoreTilesList } from "@/store/storeTile/StoreTilesList"
import { giftOptionIsGiftCard } from "@/store/utils"
import { Store_GiftOptionDataFragment } from "@/types/graphql-types"

interface Props {
  giftOption: Store_GiftOptionDataFragment
  products: Store_GiftOptionDataFragment["products"]
}

export default function GiftOptionProductsList({
  products,
  giftOption,
}: Props) {
  const { pushNewUrl, setSelectedProductPreview } = useStoreContext()

  return (
    <div tw="px-1 lg:px-10">
      {giftOptionIsGiftCard(giftOption) && (
        <div tw="flex flex-row items-center justify-center">
          <GiftCardProBanner variant="index" />
        </div>
      )}
      <div
        tw="grid lg:p-8 lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:p-0 gap-x-10 gap-y-14"
        css={[
          giftOptionIsGiftCard(giftOption)
            ? tw`gap-x-6 lg:gap-x-10 gap-y-10 lg:gap-y-14 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`
            : null,
        ]}
      >
        <StoreTilesList
          brandValues={[]}
          list={products}
          loading={false}
          nextPageIsLoading={false}
          decideIfNextPageShouldLoad={() => {}}
          minDisplayPrice={0}
          selectedCategory={null}
          pushNewUrl={pushNewUrl}
          hasStartedLoading={true}
          onSelectProduct={(product) => {
            setSelectedProductPreview(product)

            track("Store - Gift Option - Click Product", {
              productID: product.id,
              productSlug: product.slug,
              productBrandSlug: product.brand.slug,
              productBrand: product.brand.name,
              productName: product.name,
              giftOptionID: giftOption.id,
              giftOptionSlug: giftOption.slug,
              giftOptionName: giftOption.name,
            })
          }}
          showProductShippingPrice={false}
          isGiftCard={giftOptionIsGiftCard(giftOption)}
        />
      </div>
    </div>
  )
}
