import { useState } from "react"
import tw, { styled } from "twin.macro"

import { CountryGroup, SearchInput } from "./components"
import { CountryListProUpsell } from "./components/CountryListProUpsell"
import { ShippingCountryFilterBaseProps } from "../../types"

import { ShippingCountry } from "@/types/graphql-types"

interface Dictionary<T> {
  [index: string]: T
}

interface Props extends ShippingCountryFilterBaseProps {
  groupedCountries: Dictionary<ShippingCountry[]>
  withProUpsell?: boolean
}

const CountryListSelector = ({
  groupedCountries,
  selectedCountries,
  onSelectSingleCountry,
  onSelectMultipleCountries,
  withProUpsell,
}: Props) => {
  const [searchText, setSearchText] = useState("")

  const filteredCountries = (countries: ShippingCountry[]) => {
    if (!searchText) {
      return countries
    } else {
      return countries.filter((country) =>
        country.name.toLowerCase().includes(searchText.toLowerCase().trim()),
      )
    }
  }

  return (
    <Container tw="absolute right-0 flex">
      {withProUpsell && (
        <div tw="w-80 border-r border-gray-150">
          <CountryListProUpsell />
        </div>
      )}

      <div tw="overflow-y-auto w-80 relative max-h-[485px]">
        <SearchInput value={searchText} onChange={setSearchText} />

        {["domestic", "global"].map((group) => {
          const countries = filteredCountries(groupedCountries[group] || [])
          return (
            countries?.length > 0 && (
              <CountryGroup
                key={group}
                variant={group as "domestic" | "global"}
                countries={countries}
                selectedCountries={selectedCountries}
                onSelectSingleCountry={onSelectSingleCountry}
                onSelectMultipleCountries={onSelectMultipleCountries}
                withProUpsell={withProUpsell}
              />
            )
          )
        })}
      </div>
    </Container>
  )
}

const Container = styled.div`
  ${tw`rounded-xl border border-solid border-gray-150 bg-white w-max`}
  box-shadow: 0px 12px 24px 0px rgba(20, 0, 0, 0.08);
`

export default CountryListSelector
