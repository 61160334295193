import { CountryCodeEnum, ShippingCountry } from "@/types/graphql-types"

const EXCLUDED_COUNTRY_GROUPS = ["GIFT_CARD"]
const SORTED_COUNTRIES = [CountryCodeEnum.GLOBAL, CountryCodeEnum.US]

export const sortCountries = (countries: ShippingCountry[]) => {
  return countries
    .slice()
    .sort((a, b) =>
      SORTED_COUNTRIES.includes(a.code)
        ? -1
        : SORTED_COUNTRIES.includes(b.code)
          ? 1
          : a.name.localeCompare(b.name),
    )
    .filter((country) => !EXCLUDED_COUNTRY_GROUPS.includes(country.code))
}
