import { useState } from "react"
import tw from "twin.macro"

import DomesticCountriesPopover from "./components/DomesticCountriesPopover"
import DomesticShippingModal from "../../modals/DomesticShippingModal"
import GlobalShippingModal from "../../modals/GlobalShippingModal"
import { DomesticShippingTag } from "../DomesticShippingTag"
import { GlobalShippingTag } from "../GlobalShippingTag"
import MultipleCountriesShippingTag from "../MultipleCountriesShippingTag/MultipleCountriesShippingTag"

import { useShippingCountriesGroup } from "@/store/hooks/useShippingCountriesGroup"
import { ShippingCountryGroup } from "@/types/graphql-types"

interface Props {
  shippingCountryGroup: ShippingCountryGroup
  variant?: "compact" | "full"
  withProUpsell?: boolean
  shippingPrice?: number
  withDomesticPopover?: boolean
}

const ShippingTag = ({
  shippingCountryGroup,
  shippingPrice = 0,
  withProUpsell = false,
  variant = "full",
  withDomesticPopover = false,
}: Props) => {
  const noAction = variant === "compact"
  const [openDomesticModal, setOpenDomesticModal] = useState(false)
  const [openGlobalModal, setOpenGlobalModal] = useState(false)

  const {
    showShippingTag,
    displayDomesticShippingTag,
    displayGlobalShippingTag,
    selectedDomesticCountries,
    selectedGlobalCountries,
    multipleCountriesSelected,
    domesticTagLabel,
    domesticCountry,
  } = useShippingCountriesGroup({ shippingCountryGroup })

  const showMultipleCountries =
    variant !== "compact" && multipleCountriesSelected

  const openDomestic = noAction ? undefined : () => setOpenDomesticModal(true)
  const openGlobal = noAction ? undefined : () => setOpenDomesticModal(true)

  if (!showShippingTag && !withDomesticPopover) return null

  return (
    <div tw="flex flex-col gap-2">
      {!showMultipleCountries &&
        domesticCountry &&
        (displayDomesticShippingTag || withDomesticPopover) && (
          <DomesticCountriesPopover
            domesticCountryCodes={shippingCountryGroup.domestic ?? []}
            hidden={!withDomesticPopover}
          >
            <DomesticShippingTag
              country={domesticCountry}
              onClick={openDomestic}
              customLabel={domesticTagLabel}
              twStyle={tw`flex-shrink`}
            />
          </DomesticCountriesPopover>
        )}

      {!showMultipleCountries && displayGlobalShippingTag && (
        <GlobalShippingTag showProUpsell={withProUpsell} onClick={openGlobal} />
      )}

      {showMultipleCountries && (
        <MultipleCountriesShippingTag
          withProUpsell={withProUpsell}
          domesticCountries={selectedDomesticCountries}
          globalCountries={selectedGlobalCountries}
          shippingPrice={shippingPrice}
          onDomesticOptionClick={openDomestic}
          onGlobalOptionClick={openGlobal}
        />
      )}

      <DomesticShippingModal
        open={openDomesticModal}
        onClose={() => setOpenDomesticModal(false)}
      />
      <GlobalShippingModal
        open={openGlobalModal}
        onClose={() => setOpenGlobalModal(false)}
        withProUpsell={withProUpsell}
      />
    </div>
  )
}

export default ShippingTag
