import { gql } from "@apollo/client"

import { PAGE_INFO_FRAGMENT } from "./PageInfoFragment"
import { EDITORIAL_CTAS_FRAGMENT } from "../editorial/EditorialCTAs"
import { GIFT_OPTION_PREVIEW_FRAGMENT } from "../GiftOption/graphql/GiftOptionPreviewFragment"
import { GIFT_OPTION_STORE_TILE_FRAGMENT } from "../storeTile/GiftOptionStoreTile"
import { PRODUCT_STORE_TILE_FRAGMENT } from "../storeTile/ProductStoreTile"

import { IMAGE_FRAGMENT } from "@/graphql"

const GIFT_OPTION_NODE_FRAGMENT = gql`
  fragment Store_GiftOptionNode on GiftOption {
    id
    name
    slug
    subtitle
    isFlexGift
    primaryImage {
      imageLarge {
        ...Image
      }
    }
    prices
    priceMin
    priceMax
    recommendedOccasionIds
    ...Store_GiftOptionPreview
    ...Store_GiftOptionStoreTile
  }
  ${GIFT_OPTION_STORE_TILE_FRAGMENT}
  ${GIFT_OPTION_PREVIEW_FRAGMENT}
  ${IMAGE_FRAGMENT}
`

export const STOREFRONT_QUERY = gql`
  query Store_Storefront(
    $brandValues: [String!]
    $categoryID: ID
    $categorySlug: String
    $searchQuery: String
    $priceMin: Int
    $priceMax: Int
    $afterCursor: String
    $first: Int!
    $defaultCategorySegment: CategorySegment
    $shippingCountries: [CountryCodeEnum!]
    $showOnlyDomesticShipping: Boolean
    $productMode: Boolean!
    $segment: BusinessSegment!
  ) {
    giftOptionsConnection(
      brandValues: $brandValues
      categoryId: $categoryID
      categorySlug: $categorySlug
      searchQuery: $searchQuery
      priceMin: $priceMin
      priceMax: $priceMax
      after: $afterCursor
      first: $first
      defaultCategorySegment: $defaultCategorySegment
      shippingCountries: $shippingCountries
      showOnlyDomesticShipping: $showOnlyDomesticShipping
      segment: $segment
    ) @skip(if: $productMode) {
      pageInfo {
        ...PageInfo
      }
      nodes {
        ...Store_GiftOptionNode
      }
    }
    productsConnection(
      brandValues: $brandValues
      categoryId: $categoryID
      categorySlug: $categorySlug
      priceMin: $priceMin
      priceMax: $priceMax
      after: $afterCursor
      first: $first
      shippingCountries: $shippingCountries
      showOnlyDomesticShipping: $showOnlyDomesticShipping
      segment: $segment
    ) @include(if: $productMode) {
      pageInfo {
        ...PageInfo
      }
      nodes {
        ...Store_ProductStoreTile
      }
    }
    category(
      id: $categoryID
      slug: $categorySlug
      defaultCategorySegment: $defaultCategorySegment
    ) {
      id
      slug
      editorialItems(storePlatform: web) {
        ...Editorial_CTAs
      }
    }
  }
  ${GIFT_OPTION_NODE_FRAGMENT}
  ${EDITORIAL_CTAS_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  ${PRODUCT_STORE_TILE_FRAGMENT}
`
