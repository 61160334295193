import { Link, LinkProps } from "react-router-dom-v5-compat"

import useStoreContext from "../hooks/useStoreContext"

// This component is an alternative to the Link component from
// react-router-dom to be used only inside the store, it handles
// the virtual url navigation by avoiding direct router navigation
// preventing user from being kicked out of the custom store while
// adding products there

interface Props extends LinkProps {}

const StoreLink = ({ to, ...props }: Props) => {
  const { pushNewUrl } = useStoreContext()

  console.log({ to })
  return (
    <Link
      to={to}
      onClick={(e) => {
        e.preventDefault()
        console.log("clicke", to.toString())
        pushNewUrl(to.toString())
      }}
      {...props}
    />
  )
}

export default StoreLink
