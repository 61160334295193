import "twin.macro"
import { useApolloClient } from "@apollo/client"
import { useEffect, useMemo, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom-v5-compat"

import CategorySidebarFrame from "./CategorySidebarFrame"
import GiftOptionsListHeader from "./GiftOptionsListHeader"
import InfoBanner from "./InfoBanner"
import {
  categoryRequiresGiftOptions,
  categoryRequiresProducts,
} from "./utils/categoryRules"
import { fetchEditorialItemBySlug } from "./utils/fetchEditorialItemBySlug"
import WebImageBanner from "./WebImageBanner"
import { ShippingOptionsBanner } from "../components/shipping/ShippingOptionsBanner"
import { useShippingCountriesSelector } from "../hooks/useShippingCountriesSelector"
import useStoreContext from "../hooks/useStoreContext"

import { track } from "@/common/analytics"
import { useGlobalState } from "@/common/GlobalState"
import { getBusinessSegmentFromRealm } from "@/common/realm"
import EditorialCTAs from "@/store/editorial/EditorialCTAs"
import Filters from "@/store/filters/Filters"
import { GiftOptionsListColumn } from "@/store/GiftOption/GiftOptionsListColumn"
import useEditorialCTAs from "@/store/hooks/useEditorialCTAs"
import { useStorefront } from "@/store/hooks/useStorefront"
import { getFeaturedCategoryKey } from "@/store/StoreHelpers"
import { StoreTilesList } from "@/store/storeTile/StoreTilesList"
import { CountryCodeEnum } from "@/types/graphql-types"

export default function CategoryScreen() {
  const {
    pushNewUrl,
    generateBrowseUrl,
    selectedCategorySet,
    setSelectedCategorySlug,
    isEmbeddedCustomStore,
    brandValues,
    setBrandValues,
    selectedCategory,
    selectedSubcategory,
    selectedSupercategory,
    useVirtualUrl,
    setSelectedGiftOptionPreview,
    setSelectedProductPreview,
    mode,
    setMode,
    priceFilterValue,
    setPriceFilterValue,
  } = useStoreContext()

  const {
    selectedShippingCountries,
    selectedShippingCountryCodes,
    showOnlyDomesticShipping,
    toogleShowOnlyDomesticShipping,
    onlyCountrySelected,
  } = useShippingCountriesSelector()

  const [currentRealm] = useGlobalState("currentRealm")
  const { slug: slugParam } = useParams<{ slug?: string }>()
  const categorySlug = slugParam || null

  // Override mode when category requires gift options
  const requireGiftOptions = categoryRequiresGiftOptions(categorySlug)
  const requireProducts = categoryRequiresProducts(
    categorySlug,
    selectedSupercategory?.slug || null,
  )

  const effectiveMode = requireProducts
    ? "products"
    : requireGiftOptions
      ? "brands"
      : mode

  const {
    results: resultsData,
    priceFilterMin,
    loading,
    nextPageIsLoading,
    decideIfNextPageShouldLoad,
    hasStartedLoading,
  } = useStorefront({
    brandValues,
    searchTerm: "",
    priceFilterValue,
    categorySlug: categorySlug,
    segment: getBusinessSegmentFromRealm(currentRealm),
    selectedShippingCountryCodes,
    showOnlyDomesticShipping,
    mode: effectiveMode,
    ...(isEmbeddedCustomStore && { storeType: "custom_store" }),
  })

  const { editorialItems } = useEditorialCTAs({
    categorySlug,
  })

  useEffect(() => {
    setSelectedCategorySlug(categorySlug || getFeaturedCategoryKey("plus"))
  }, [categorySlug])

  const client = useApolloClient()

  // Track prefetched editorial items in a map
  const prefetchedEditorialItems = useRef<{ [slug: string]: Date }>({}).current

  async function prefetchEditorialItem(slug: string) {
    const lastFetched = prefetchedEditorialItems[slug]

    // Never fetched or fetched > 5 minutes ago
    if (
      !lastFetched ||
      new Date().getTime() - lastFetched.getTime() > 1000 * 60 * 5
    ) {
      if (process.env.NODE_ENV === "development") {
        console.log("Prefetching editorial item:", slug)
      }

      await fetchEditorialItemBySlug({
        client,
        slug,
        isPrefetch: true,
      })

      prefetchedEditorialItems[slug] = new Date()
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log(
          `Skipping editorial item prefetch for ${slug}: last prefetched ${
            (new Date().getTime() - lastFetched.getTime()) / 1000
          }s ago`,
        )
      }
    }
  }

  useEffect(() => {
    if (isEmbeddedCustomStore) {
      return
    }

    for (const editorialItem of editorialItems) {
      prefetchEditorialItem(editorialItem.slug)
    }
  }, [editorialItems])

  const scrollElemRef = useRef<HTMLDivElement | null>(null)

  const items = useMemo(
    () =>
      isEmbeddedCustomStore
        ? resultsData.filter((item) => !item.isFlexGift)
        : resultsData,
    [isEmbeddedCustomStore, resultsData],
  )

  const titlePrefix =
    selectedCategory?.seoTitle ||
    selectedCategory?.pageHeading ||
    selectedCategory?.name

  const canonicalPath = generateBrowseUrl({
    categorySlug: selectedCategory?.slug || null,
    giftOptionSlug: null,
    categoryIsFeatured: false,
  })

  const metaDescription = selectedCategory?.pageDescription

  return (
    <>
      {!useVirtualUrl && (
        <Helmet>
          <title>{titlePrefix ?? "Browse"} | Goody</title>
          <link
            rel="canonical"
            href={`https://www.ongoody.com${canonicalPath ?? ""}`}
          />
          <meta
            name="title"
            property="og:title"
            content={`${titlePrefix ?? "Browse"} | Goody`}
          />
          {metaDescription && (
            <meta
              name="description"
              property="og:description"
              content={metaDescription}
            />
          )}
        </Helmet>
      )}
      <CategorySidebarFrame>
        <div tw="px-5 lg:px-8">
          <div tw="pt-8">
            <GiftOptionsListHeader
              selectedCategorySet={selectedCategorySet}
              selectedCategorySlug={selectedSupercategory?.slug || null}
              selectedSubcategorySlug={selectedSubcategory?.slug || null}
            />

            {!isEmbeddedCustomStore && (
              <EditorialCTAs editorialItems={editorialItems} show={true} />
            )}
            <InfoBanner />
            <WebImageBanner />
          </div>
          <div tw="pt-6 pb-4">
            <Filters
              brandValues={brandValues}
              priceFilterValue={priceFilterValue}
              searchTerm=""
              setBrandValues={setBrandValues}
              setPriceFilter={setPriceFilterValue}
              setSearchTerm={() => {}}
              mode={mode}
              setMode={setMode}
              hideModeSwitcher={requireGiftOptions || requireProducts}
            />
          </div>

          {!onlyCountrySelected(CountryCodeEnum.US) && (
            <div tw="pt-3 pb-9">
              <ShippingOptionsBanner
                selectedCountries={selectedShippingCountries}
                domesticOnly={showOnlyDomesticShipping}
                toogleDomesticOnly={toogleShowOnlyDomesticShipping}
              />
            </div>
          )}

          <div ref={scrollElemRef}>
            <GiftOptionsListColumn
              scrollElemRef={scrollElemRef}
              decideIfNextPageShouldLoad={decideIfNextPageShouldLoad}
              giftOptionCount={items.length}
            >
              <StoreTilesList
                key={categorySlug}
                brandValues={brandValues}
                list={items}
                onSelectGiftOption={(giftOption) => {
                  setSelectedGiftOptionPreview(giftOption)
                  track("Store - Click Gift Option", {
                    slug: giftOption.slug,
                  })
                }}
                onSelectProduct={(product) => {
                  setSelectedProductPreview(product)

                  const { slug, id, brand } = product
                  track("Store - Click Product", {
                    id,
                    slug,
                    brandSlug: brand.slug,
                  })
                }}
                pushNewUrl={pushNewUrl}
                loading={loading}
                minDisplayPrice={priceFilterMin || 0}
                nextPageIsLoading={nextPageIsLoading}
                hasStartedLoading={hasStartedLoading}
                decideIfNextPageShouldLoad={decideIfNextPageShouldLoad}
                selectedCategory={selectedCategory}
                showProductShippingPrice={true}
                showProductBrandName={true}
              />
            </GiftOptionsListColumn>
          </div>
        </div>
      </CategorySidebarFrame>
    </>
  )
}
