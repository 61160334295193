import { CountryFlag } from "@/common/CountryFlag"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  countries: ShippingCountry[]
}

const CountryList = ({ countries }: Props) => {
  return (
    <div tw="p-4">
      {countries.map((country) => (
        <div tw="flex items-center gap-3" key={country.code}>
          <CountryFlag code={country.code} size="m" />
          <div tw="text-black font-normal text-base">{country.name}</div>
        </div>
      ))}
    </div>
  )
}

export default CountryList
