import { InternationalShippingTierEnum } from "@/types/graphql-types"

type Options = {
  isShippingTierDisabled: boolean
  isGiftCard?: boolean
  hasProPlan: boolean
  usCountrySelected: boolean
}

export const determineShippingTierForProduct = ({
  isShippingTierDisabled,
  hasProPlan,
  isGiftCard,
  usCountrySelected,
}: Options) => {
  if (isShippingTierDisabled) {
    return InternationalShippingTierEnum.disabled
  }

  if (!usCountrySelected && hasProPlan) {
    if (isGiftCard) {
      return InternationalShippingTierEnum.standard
    } else {
      return InternationalShippingTierEnum.full
    }
  }

  return InternationalShippingTierEnum.standard
}
