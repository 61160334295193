import nl2br from "react-nl2br"
import tw from "twin.macro"

import DevModeProductID from "./DevModeProductID"
import ProductShippingLabel from "./ProductShippingLabel"
import PopoverShippingCountryList from "../components/shipping/PopoverShippingCountryList"
import ShippingTag from "../components/shipping/ShippingTag/ShippingTag"
import StoreLink from "../components/StoreLink"
import { useShippingCountriesGroup } from "../hooks/useShippingCountriesGroup"

import { formatPrice, formatVariablePricing } from "@/common/format"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { generateRealmPath } from "@/common/realm"
import {
  Store_PDP_ProductDetailsFragment,
  Store_PDP_RelatedProductsQuery,
} from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
  primaryGiftOption?: NonNullable<
    Store_PDP_RelatedProductsQuery["productDetails"]
  >["brand"]["primaryGiftOption"]
  isGiftCard?: boolean
}

export default function ProductDetailsHeader({
  product,
  primaryGiftOption,
  isGiftCard,
}: Props) {
  const { brand, name, price, shippingPrice, subtitle, isFlexGift } = product

  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")
  const {
    multipleCountriesSelected,
    displayGlobalShippingTag,
    allDomesticCountries,
  } = useShippingCountriesGroup({
    shippingCountryGroup: product.shippingCountryGroup,
  })

  return (
    <div>
      <div tw="flex flex-col">
        <div tw="flex justify-between items-start">
          <div>
            {primaryGiftOption?.slug && !isGiftCard ? (
              <BrandName
                to={generateRealmPath(
                  "plus",
                  "/browse/gift-option/" + primaryGiftOption.slug,
                )}
              >
                {brand.name}
              </BrandName>
            ) : (
              <BrandNameText>{brand.name}</BrandNameText>
            )}
            <ProductName>{name}</ProductName>
          </div>

          <div tw="lg:hidden">
            <PopoverShippingCountryList
              shippingCountryGroup={product.shippingCountryGroup}
            />
          </div>
        </div>
        {!isGiftCard && !multipleCountriesSelected && (
          <div tw="flex text-xl items-center mt-4">
            <div tw="mr-8">
              {formatPrice(price)}
              {formatVariablePricing(
                product.hasVariablePricing || product.isFlexGift,
              )}
            </div>
            <ProductShippingLabel
              isFlexGift={isFlexGift}
              shippingPrice={shippingPrice ?? null}
              isGlobalShipping={displayGlobalShippingTag}
              withDomesticShippingSupport={!!allDomesticCountries?.length}
            />
          </div>
        )}
      </div>
      <DevModeProductID productID={product.id} />
      <ProductSubtitle>{nl2br(subtitle)}</ProductSubtitle>

      <div tw="pt-4">
        <ShippingTag
          shippingCountryGroup={product.shippingCountryGroup}
          shippingPrice={shippingPrice ?? 0}
          withProUpsell={!hasProPlan}
          withDomesticPopover
        />
      </div>
    </div>
  )
}

const BrandName = tw(StoreLink)`
  block text-gray-500 lg:(mb-2 text-lg)
  hover:text-primary-500 transition-colors
`

const BrandNameText = tw.div`text-gray-500 lg:(mb-2 text-lg)`

const ProductName = tw.div`
  text-2xl font-reckless-neue font-light lg:(text-[32px] leading-[39px])
`

const ProductSubtitle = tw.div`mt-8 lg:mt-10 text-gray-500 font-text`
