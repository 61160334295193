import { compact, isEmpty } from "lodash-es"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import { createHookContext } from "@/common/hooks/createHookContext"
import {
  DEFAULT_COUNTRY,
  useShippingCountries,
} from "@/common/hooks/useShippingCountries"
import { useToggle } from "@/common/hooks/useToogle"
import {
  CountryCodeEnum,
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

const useShippingCountriesSelectorLocal = () => {
  const {
    shippingCountries,
    findCountryByCode,
    findCountryByCodes,
    expandShippingCountries,
  } = useShippingCountries()

  const history = useHistory()
  const initialSearchParams = useRef(history.location.search)

  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  )
  const searchParamsCountryCodes = useMemo(() => {
    const codes = compact(
      searchParams
        .get("country")
        ?.split(",")
        .map((c) => c.toUpperCase() as CountryCodeEnum) || [],
    )
    if (isEmpty(codes)) {
      codes.push(CountryCodeEnum.US)
    }
    return codes
  }, [searchParams])

  const [selectedShippingCountries, setSelectedShippingCountries] = useState<
    ShippingCountry[]
  >(setupSelectedCountries(findCountryByCodes(searchParamsCountryCodes)))

  const [
    showOnlyDomesticShipping,
    toogleShowOnlyDomesticShipping,
    setToogleShowOnlyDomesticShipping,
  ] = useToggle(searchParams.get("domestic") === "true")

  const onlyCountrySelected = useCallback(
    (code: CountryCodeEnum) => {
      return (
        selectedShippingCountries.length === 1 &&
        selectedShippingCountries[0].code === code
      )
    },
    [selectedShippingCountries],
  )

  const onlyDomesticCountriesSelected = useMemo(() => {
    return selectedShippingCountries.every((country) =>
      country.groups.includes(ShippingCountryGroupEnum.domestic),
    )
  }, [selectedShippingCountries])

  const selectedShippingCountryCodes = useMemo(() => {
    return selectedShippingCountries.map((country) => country.code)
  }, [selectedShippingCountries])

  useEffect(() => {
    if (
      searchParams.get("domestic") !== "true" ||
      !searchParamsCountryCodes ||
      onlyCountrySelected(CountryCodeEnum.US) ||
      !onlyDomesticCountriesSelected
    ) {
      setToogleShowOnlyDomesticShipping(false)
    }
  }, [
    onlyCountrySelected,
    onlyDomesticCountriesSelected,
    setToogleShowOnlyDomesticShipping,
  ])

  useEffect(() => {
    if (shippingCountries.length > 1) {
      const countries = findCountryByCodes(searchParamsCountryCodes)
      setSelectedShippingCountries(countries || [shippingCountries[0]])
    }
  }, [shippingCountries, findCountryByCodes])

  useEffect(() => {
    if (shippingCountries.length <= 1) return

    const onlyUSSelected = onlyCountrySelected(CountryCodeEnum.US)

    if (onlyUSSelected) {
      searchParams.delete("country")
    } else {
      const selectedCountryCodes = selectedShippingCountries
        .map((country) => country.code.toLowerCase())
        .join(",")
      searchParams.set("country", selectedCountryCodes)
    }

    if (showOnlyDomesticShipping && !onlyUSSelected) {
      searchParams.set("domestic", "true")
    } else {
      searchParams.delete("domestic")
    }

    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    })
  }, [
    onlyCountrySelected,
    selectedShippingCountries,
    history,
    showOnlyDomesticShipping,
  ])

  useEffect(() => {
    const search = initialSearchParams.current
    return () => history.replace({ search })
  }, [])

  return {
    shippingCountries,
    selectedShippingCountries,
    setSelectedShippingCountries,
    selectedShippingCountry: selectedShippingCountries[0],
    setSelectedShippingCountry: (country: ShippingCountry) =>
      setSelectedShippingCountries([country]),
    selectedShippingCountryCodes,
    showOnlyDomesticShipping,
    toogleShowOnlyDomesticShipping,
    findCountryByCode,
    findCountryByCodes,
    expandShippingCountries,
    onlyCountrySelected,
  }
}

const setupSelectedCountries = (countries?: ShippingCountry[]) => {
  const cleanCountries = compact(countries)
  if (isEmpty(cleanCountries)) return [DEFAULT_COUNTRY]

  return cleanCountries
}

export const {
  Provider: ShippingCountriesSelectorProvider,
  useHook: useShippingCountriesSelector,
} = createHookContext(
  "useShippingCountriesSelector",
  useShippingCountriesSelectorLocal,
)
