import {
  CountryCodeEnum,
  ShippingCountryGroupFragment,
} from "@/types/graphql-types"

type ShippingClass = keyof Omit<
  ShippingCountryGroupFragment,
  "__typename" | "global"
>

/**
 * Users can only combine items in a cart that have overlapping shipping countries,
 * which are a combination of a Country and a Shipping Class (e.g. US/Domestic).
 * This function finds the intersection of shipping groups based on their shipping classes.
 *
 * For that purpose, it takes an array of shipping country groups combines that
 * into a single object where each key represents a shipping class and the value
 * is an array of shipping countries. Then it finds the intersection of those arrays.
 *
 * Example:
 *  findShippingGroupIntersection([
 *   { domestic: ["US", "CA"],  globalRelay: ["UK", "DE"] },
 *   { domestic: ["CA"],        globalRelay: ["UK", "FR"] },
 *   { domestic: ["US", "CA"],  globalRelay: ["UK", "DE"] },
 *  ])
 *  // => { domestic: ["CA"], globalRelay: ["UK"] }
 *
 * @param groups - An array of shipping country groups.
 * @returns An object representing the intersection of shipping groups.
 */

const shippingClasses = [
  "domestic",
  "globalRelay",
  "passthrough",
  "giftCard",
  "digital",
]

export const findShippingGroupIntersection = (
  groups: ShippingCountryGroupFragment[],
) => {
  const groupsByShippingClass = groups.reduce(
    (acc: { [key: string]: string[][] }, group) => {
      shippingClasses.forEach((key) => {
        acc[key] = acc[key] || []
        acc[key].push(group[key as ShippingClass] || [])
      })

      return acc
    },
    {},
  )

  const final = Object.keys(groupsByShippingClass).reduce(
    (intersection: { [key: string]: CountryCodeEnum[] }, key) => {
      intersection[key] = Object.values(groupsByShippingClass[key]).reduce(
        (a, b) => a.filter((c) => b.includes(c)),
      ) as CountryCodeEnum[]

      return intersection
    },
    {},
  )

  return final
}
