import { useMemo } from "react"

import { useShippingCountriesSelector } from "./useShippingCountriesSelector"

import { CountryCodeEnum, ShippingCountryGroup } from "@/types/graphql-types"

interface Params {
  shippingCountryGroup: ShippingCountryGroup
}

export const useShippingCountriesGroup = ({ shippingCountryGroup }: Params) => {
  const {
    onlyCountrySelected,
    selectedShippingCountryCodes,
    findCountryByCodes,
  } = useShippingCountriesSelector()

  const showShippingTag = !onlyCountrySelected(CountryCodeEnum.US)

  const selectedGlobalCountryCodes =
    shippingCountryGroup.global?.filter((code) =>
      selectedShippingCountryCodes.includes(code),
    ) || []

  const selectedDomesticCountryCodes = useMemo(() => {
    return (
      shippingCountryGroup.domestic?.filter((code) =>
        selectedShippingCountryCodes.includes(code),
      ) || []
    )
  }, [shippingCountryGroup.domestic, selectedShippingCountryCodes])

  const displayGlobalShippingTag =
    showShippingTag && selectedGlobalCountryCodes?.length > 0

  const displayDomesticShippingTag =
    showShippingTag &&
    selectedDomesticCountryCodes?.filter((code) => code !== CountryCodeEnum.US)
      .length > 0

  const selectedGlobalCountries =
    findCountryByCodes(selectedGlobalCountryCodes) || []

  const domesticCountryCodes = useMemo(() => {
    return (
      shippingCountryGroup.domestic?.filter(
        (code) => code !== CountryCodeEnum.US,
      ) || []
    )
  }, [shippingCountryGroup.domestic])

  const allDomesticCountries = findCountryByCodes(domesticCountryCodes) || []

  const selectedDomesticCountries = useMemo(() => {
    return findCountryByCodes(selectedDomesticCountryCodes) || []
  }, [selectedDomesticCountryCodes, findCountryByCodes])

  const multipleCountriesSelected = selectedShippingCountryCodes.length > 1

  const domesticCountry =
    selectedDomesticCountries.find(
      (country) => country.code !== CountryCodeEnum.US,
    ) || allDomesticCountries[0]

  const domesticTagLabel = useMemo(() => {
    const domesticCountriesSize = shippingCountryGroup.domestic?.length || 0
    const shipsToMultipleDomesticCountries = domesticCountriesSize > 1

    if (!domesticCountry) return undefined

    const labelStart = onlyCountrySelected(CountryCodeEnum.US)
      ? "Also ships domestically to "
      : "Domestic shipping · "
    const labelEnd =
      !onlyCountrySelected(CountryCodeEnum.US) &&
      shipsToMultipleDomesticCountries
        ? ` + ${domesticCountriesSize - 1} more`
        : ""

    return labelStart + domesticCountry.name + labelEnd
  }, [
    onlyCountrySelected,
    domesticCountry,
    shippingCountryGroup.domestic?.length,
  ])

  return {
    showShippingTag,
    displayDomesticShippingTag,
    displayGlobalShippingTag,
    allDomesticCountries,
    selectedDomesticCountries,
    selectedGlobalCountries,
    multipleCountriesSelected,
    domesticTagLabel,
    domesticCountry,
  }
}
