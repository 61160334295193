import "./opacity.css"
import "react-placeholder/lib/reactPlaceholder.css"

import { useEffect, useMemo, useRef, useState } from "react"
import { Route, Routes } from "react-router-dom-v5-compat"
import { useLocation } from "react-router-dom-v5-compat"
import { Location } from "react-router-dom-v5-compat"

import CategoryScreen from "./categories/CategoryScreen"
import { CategorySet } from "./categories/CategorySetToggle"
import CategorySidebarFrame from "./categories/CategorySidebarFrame"
import { BrandValueKey } from "./components/BrandValueBadge"
import CustomScreen from "./components/CustomScreen"
import HolidayGiftGuideScreen from "./components/HolidayGiftGuideScreen"
import Menu from "./components/Menu"
import SearchScreen from "./components/SearchScreen"
import {
  CustomStoreBrowserProps,
  customStoreBrowserDefaults,
} from "./custom/common"
import EditorialScreen from "./editorial/EditorialScreen"
import { PriceFilterValue, priceFilterInitialValue } from "./filters"
import GiftOptionModal from "./GiftOption/GiftOptionModal"
import useCategoriesData from "./hooks/useCategoriesData"
import { ShippingCountriesSelectorProvider } from "./hooks/useShippingCountriesSelector"
import PDPModal from "./pdp/PDPModal"
import ProductDetailPage from "./pdp/ProductDetailPage"
import StoreContext, { StoreCaller, StoreScreen } from "./StoreContext"
import {
  ProductData,
  generateSyntheticLocation,
  useBrowseUrls,
} from "./StoreHelpers"
import { StoreMode, onSelectProductIDType } from "./types"
import { priceFilterValueToMinMax } from "./utils"
import AgeVerificationModal from "../common/AgeVerificationModal"
import CartReplaceModal from "../common/cart/CartReplaceModal"
import RealmChooseModal from "../common/components/RealmChooseModal"
import { useCartDrawer } from "../common/contexts/cartDrawer"
import { useGlobalState } from "../common/GlobalState"

import { Store_GiftOptionPreviewFragment } from "@/types/graphql-types"

interface StoreProps {
  visible: boolean
  // Is the Store being embedded into another component, not on its own page?
  // This means the Store should have slightly different measurements to account
  // for the lack of footer.
  isEmbedded?: boolean
  // Override the default (setCurrentGift) for when a product is selected.
  onSelectProductID?: onSelectProductIDType
  // Whether to use a "virtual" URL instead of the real one. For embedding of the
  // store where we don't want the actual URL to change.
  useVirtualUrl?: boolean
  customStoreBrowserProps?: CustomStoreBrowserProps
  defaultMode: StoreMode
  caller: StoreCaller
}

interface LocationStack {
  background: Location | null
  giftOption: Location | null
  product: Location | null
}

const SYNTHETIC_BACKGROUND_LOCATION_HOME = generateSyntheticLocation("/browse")

// StoreV2 uses a React Router 6 <Router> component and is primarily driven by
// the browser's URL.
//
//
// OBJECTIVES ----------
//
// Let's first talk about what the objectives of this store structure are.
// The Store in general will have multiple main pages that can be visited:
// Home, Category, Search, Custom, etc. Then, when users click a gift option
// or product on one of these pages, the gift option or product opens in
// a modal, and the user's browser URL will be modified to be the canonical
// URL of the gift option (/browse/gift-option/slug) or product
// (/browse/brands/slug/product/slug). Despite the URL change, the main page
// they were on is still visible in the background. So, we call these main
// pages 'background' pages.
//
// Gift option modals and product modals stack, with product modals always
// over gift option modals. So, if a user clicks a product on a gift option,
// they'll have a modal stack of 2. Closing the product would then show
// the gift option, and closing the gift option would show the background.
// Product modals can also be opened directly from a background page, without
// an intermediate gift option.
//
// If a user goes directly to the canonical gift option or product URL, gift
// options load in a modal, and products load as a standalone PDP. (We might
// have a standalone page for a gift option in the future).
//
// Our challenge is: when the browser URL changes when accessing a gift option
// or product URL, how do we ensure the background page is still rendered?
//
//
// STRUCTURE ----------
//
// The main structure is represented by the `locationStack` state, which has
// three fixed stack layers:
//
// - background: The main page being displayed (home, category, search, etc.)
// - giftOption: The gift option being displayed in a modal
// - product:    The product being displayed in a modal
//
// Each of these variables stores the last visited location for that layer.
// So `background` contains the last visited background page. `giftOption`
// stores the last visited gift option, or null if the gift option modal is
// closed.
//
// In other words, whenever the user navigates to a background page, we set
// the background layer to that location. Whenever the user navigates to a
// gift option URL, we set the gift option layer to that location. Whenever
// navigates to a product URL, we set the product layer to that location.
//
// We have a <Router> component where we feed in the background location,
// which will then render whatever route is represented by the background
// location. That means that even if a gift option or product is opened,
// changing the browser URL, the background location will still be rendered
// with whatever URL the user visited. In a way, we are sort of tricking
// React Router to think the current URL is the last visited background URL.
//
//
// PROCESS ----------
//
// The browser URL drives how the location stack is set, including initial
// navigation, forward/back navigation, and direct navigation by clicking
// a URL. Here's how it works:
//
// On initial load, the location stack is set based on the URL, which is
// typically going to be Home, Category, Search, Custom, etc. The URL could
// also be a product PDP URL, which loads a standalone PDP page. Either way,
// we set the `background` layer to the location.
//
// When a user clicks a gift option, we then replace the gift option layer
// with the location of the gift option. Likewise, if a user clicks a product,
// we replace the product layer with the location of the product.
//
// Whenever there is a non-null gift option, we render the gift option modal.
// When the user closes the gift option, we set the gift option layer to null.
// The same goes for products.
//
// If a user navigates to a new background URL, we set the background layer
// and clear the gift option and product layers.
//
// An important thing is supporting back and forward buttons. Whenever the
// browser back or forward button is clicked, we update the location stack
// with the new location, by detecting whether the URL is a background page,
// gift option, or product. So if they opened a gift option, closed it, and
// then clicked back, their URL would contain the gift option URL, and we
// would then set the gift option layer to that location.
const StoreContent = ({
  isEmbedded,
  onSelectProductID,
  useVirtualUrl,
  visible,
  customStoreBrowserProps,
  defaultMode,
  caller,
}: StoreProps) => {
  const [realmChoiceModalOpen, setRealmChoiceModalOpen] = useGlobalState(
    "realmChoiceModalOpen",
  )
  const [verifyAge, setVerifyAge] = useState(false)
  const [selectedCategorySlug, setSelectedCategorySlug] = useState<
    null | string
  >(null)
  const [selectedCategorySet, setSelectedCategorySet] = useState<CategorySet>(
    CategorySet.CATEGORIES,
  )

  const { openCart } = useCartDrawer()

  // SelectedProductPreview is available when clicking on a PDP from a gift option, but not if navigating directly from
  // a url.
  const [selectedProductPreview, setSelectedProductPreview] =
    useState<ProductData | null>(null)

  const browserLocation = useLocation()

  const [virtualLocation, setVirtualLocation] = useState<Location>({
    pathname: "/browse",
    search: "",
    hash: "",
    state: null,
    key: "",
  })

  const {
    generateBrowseUrl,
    generatePdpUrl,
    getPath,
    pushPath,
    getPartsFromUrl,
  } = useBrowseUrls(useVirtualUrl, virtualLocation, setVirtualLocation)

  const rawLocation = useVirtualUrl ? virtualLocation : browserLocation

  // If location starts with /business, /plus, or /personal, remove it from the path
  // This fixes a bug where the store would break if you navigated to these pages
  // which should redirect to the non-prefixed version, but the `location` variable
  // does not populate with the new location for some reason but only in production
  const location = useMemo(() => {
    const path = rawLocation.pathname
    const newPath = path.replace(/\/(business|plus|personal)\//, "/")
    return {
      ...rawLocation,
      pathname: newPath,
    }
  }, [rawLocation])

  const [priceFilterValue, setPriceFilterValue] = useState<PriceFilterValue>(
    priceFilterInitialValue,
  )
  const [brandValues, setBrandValues] = useState<BrandValueKey[]>([])

  const {
    customStoreProductButton,
    customStoreGiftOptionButton,
    customStoreGiftCardButton,
    isGiftCardInCustomStore,
  } = customStoreBrowserProps ?? customStoreBrowserDefaults

  const isEmbeddedCustomStore = !!customStoreBrowserProps

  const onSelectProduct = () => {
    openCart()
  }

  const [selectedGiftOptionPreview, setSelectedGiftOptionPreview] =
    useState<Store_GiftOptionPreviewFragment | null>(null)

  const pushNewUrl = (path: string, state?: Record<string, string>) => {
    if (getPath() !== path) {
      pushPath(path, state)
    }
  }

  const imagesCached = useRef<Set<string>>(new Set()).current

  const setPDPProduct = (newProductPreviewData: ProductData) => {
    setSelectedProductPreview(newProductPreviewData)
    pushNewUrl(
      generatePdpUrl({
        brandSlug: newProductPreviewData.brand.slug,
        productSlug: newProductPreviewData.slug,
      }),
    )
  }

  const [locationStack, setLocationStack] = useState<LocationStack>({
    background: null,
    giftOption: null,
    product: null,
  })

  useEffect(() => {
    const getLocationType = (
      location: Location,
    ): "gift-option" | "product" | "background" | null => {
      if (location.pathname.startsWith("/browse/gift-option/")) {
        return "gift-option"
      }
      if (location.pathname.startsWith("/browse/brands/")) {
        return "product"
      }
      if (
        location.pathname.startsWith("/browse") ||
        location.pathname.startsWith("/editorial")
      ) {
        return "background"
      }
      return null
    }

    // Function to handle URL navigations and forward/back navigation
    const updateStack = () => {
      const locationType = getLocationType(location)

      // Non-browse page
      if (!locationType) {
        return
      }

      setLocationStack((prevStack) => {
        let newStack = { ...prevStack }

        // Legacy compatibility with old category-parameterized gift option URLs
        // that look like /browse/category/slug/gift-option/slug
        if (
          location.pathname.startsWith("/browse/category") &&
          location.pathname.includes("/gift-option/")
        ) {
          const { categorySlug, giftOptionSlug } = getPartsFromUrl()

          newStack = {
            // Background: category page
            background: generateSyntheticLocation(
              `/browse/category/${categorySlug}`,
            ),
            // Gift option: gift option page
            giftOption: generateSyntheticLocation(
              `/browse/gift-option/${giftOptionSlug}`,
            ),
            product: null,
          }
        } else if (locationType === "gift-option") {
          newStack = {
            // When loading a gift option from direct navigation, the background
            // location is null, so set it to the homepage, so this opens a
            // modal for direct navigation
            background:
              locationStack.background || SYNTHETIC_BACKGROUND_LOCATION_HOME,
            giftOption: location,
            product: null,
          }
        } else if (locationType === "product") {
          if (
            locationStack.background === null ||
            getLocationType(locationStack.background) !== "background"
          ) {
            // Load as standalone PDP
            newStack = {
              background: location,
              giftOption: null,
              product: null,
            }
          } else {
            // Load as modal
            newStack = {
              ...locationStack,
              product: location,
            }
          }
        } else if (locationType === "background") {
          newStack = {
            background: location,
            giftOption: null,
            product: null,
          }
        }

        return newStack
      })
    }

    // Listen for popstate events
    window.addEventListener("popstate", updateStack)

    // Update the stack initially
    updateStack()

    return () => {
      window.removeEventListener("popstate", updateStack)
    }
  }, [location])

  const backgroundLocation = locationStack.background || undefined

  // A background location is a category if it's a category screen or the home screen
  const backgroundLocationIsCategory = useMemo(() => {
    if (!backgroundLocation) {
      return false
    }

    return (
      backgroundLocation.pathname.startsWith("/browse/category/") ||
      backgroundLocation.pathname === "/browse" ||
      backgroundLocation.pathname === "/browse-v2"
    )
  }, [locationStack])

  // Clear the selected category slug if the background location is not a category page
  useEffect(() => {
    if (!backgroundLocationIsCategory && selectedCategorySlug) {
      setSelectedCategorySlug(null)
    }
  }, [backgroundLocationIsCategory])

  // Determine the screen name of the background location
  const backgroundScreen = useMemo<StoreScreen | null>(() => {
    if (!backgroundLocation) {
      return null
    }
    if (backgroundLocation.pathname.startsWith("/browse/category/")) {
      return "category"
    } else if (backgroundLocation.pathname.startsWith("/browse/custom")) {
      return "custom"
    } else if (backgroundLocation.pathname.startsWith("/browse/search")) {
      return "search"
    } else if (backgroundLocation.pathname.startsWith("/browse/brands")) {
      return "pdp_standalone"
    } else if (backgroundLocation.pathname.startsWith("/editorial")) {
      return "editorial"
    }

    return "home"
  }, [backgroundLocation])

  const { priceFilterMin: priceFilterMinNullable, priceFilterMax } =
    priceFilterValueToMinMax(priceFilterValue)
  const priceFilterMin = priceFilterMinNullable ?? 0

  const { selectedCategory, selectedSupercategory, selectedSubcategory } =
    useCategoriesData({
      selectedCategorySlug,
    })

  useEffect(() => {
    if (selectedCategory) {
      setVerifyAge(selectedCategory.isAlcohol)
    }
  }, [selectedCategory])

  useEffect(() => {
    if (locationStack.giftOption || locationStack.product) {
      document.body.classList.add("store-modal-open")
    } else {
      document.body.classList.remove("store-modal-open")
    }

    return () => {
      document.body.classList.remove("store-modal-open")
    }
  }, [locationStack])

  const shouldRenderStandalonePDP =
    locationStack.background?.pathname.startsWith("/browse/brands/") || false

  const path = getPath()

  const standalonePDPParams = useMemo(() => {
    if (shouldRenderStandalonePDP) {
      const { productSlug, brandSlug } = getPartsFromUrl()
      if (productSlug && brandSlug) {
        return {
          slug: productSlug,
          brand: {
            slug: brandSlug,
          },
        }
      }
    }

    return null
  }, [shouldRenderStandalonePDP, getPartsFromUrl, path])

  const modalPDPParams = useMemo(() => {
    if (locationStack.product) {
      const { productSlug, brandSlug } = getPartsFromUrl()

      if (
        selectedProductPreview &&
        selectedProductPreview.slug === productSlug &&
        selectedProductPreview.brand.slug === brandSlug
      ) {
        return selectedProductPreview
      } else if (productSlug && brandSlug) {
        return {
          slug: productSlug,
          brand: {
            slug: brandSlug,
          },
        }
      }
    }

    return null
  }, [getPartsFromUrl, locationStack.product, selectedProductPreview])

  // The downstack item is the item that will be displayed when the user closes
  // the current modal. If the user is on a product, the downstack item is the
  // gift option or background. If the user is on a gift option, the downstack
  // item is the background. If the user is on a background, there is no
  // downstack item.
  const locationStackDownstackItem = useMemo(() => {
    if (locationStack.product) {
      return locationStack.giftOption || locationStack.background
    } else if (locationStack.giftOption) {
      return locationStack.background
    }

    return null
  }, [locationStack])

  const [mode, setMode] = useState<StoreMode>(defaultMode)

  if (!visible) {
    return null
  }

  return (
    <StoreContext.Provider
      value={{
        screen: backgroundScreen,
        selectedCategorySlug,
        setSelectedCategorySlug,
        selectedCategory,
        selectedSupercategory,
        selectedSubcategory,
        pushNewUrl,
        generateBrowseUrl,
        generatePdpUrl,
        selectedCategorySet,
        setSelectedCategorySet,
        isEmbeddedCustomStore,
        useVirtualUrl: useVirtualUrl || false,
        brandValues,
        setBrandValues,
        priceFilterValue,
        setPriceFilterValue,
        setSelectedGiftOptionPreview,
        setSelectedProductPreview,
        locationStackDownstackItem,
        mode,
        setMode,
        defaultMode,
        backgroundLocation: backgroundLocation || null,
        virtualLocation,
        setVirtualLocation,
        caller,
      }}
    >
      <div css={{ gridColumn: "span 3", minHeight: "100vh" }}>
        <AgeVerificationModal verifyAge={verifyAge} />
        <Menu />
        <Routes location={backgroundLocation}>
          <Route path="/browse" element={<CategoryScreen />} />
          <Route path="/browse-v2" element={<CategoryScreen />} />
          <Route path="/browse/category/:slug" element={<CategoryScreen />} />
          <Route path="/browse/search" element={<SearchScreen />} />
          <Route
            path="/browse/custom/*"
            element={
              <CustomScreen
                onSelectProductID={onSelectProductID}
                isEmbeddedCustomStore={isEmbeddedCustomStore}
                useVirtualUrl={useVirtualUrl}
              />
            }
          />
          <Route
            path="/browse/holiday-gifts"
            element={<HolidayGiftGuideScreen />}
          />
          {shouldRenderStandalonePDP && standalonePDPParams && (
            <Route
              path="/browse/brands/:brandSlug/:productSlug"
              element={
                <CategorySidebarFrame>
                  <ProductDetailPage
                    key={
                      standalonePDPParams.slug +
                      "----" +
                      standalonePDPParams.brand.slug
                    }
                    productData={standalonePDPParams}
                    setPDPProduct={(productPreviewData) => {
                      window.scrollTo(0, 0)
                      setPDPProduct(productPreviewData)
                    }}
                    onSelectProductID={onSelectProductID}
                    onAfterSelectProduct={onSelectProduct}
                    storeVisible={visible}
                  />
                </CategorySidebarFrame>
              }
            />
          )}
          <Route path="/editorial/:slug" element={<EditorialScreen />} />
        </Routes>

        {locationStack.giftOption && (
          <ShippingCountriesSelectorProvider>
            <GiftOptionModal
              giftOptionSlug={locationStack.giftOption.pathname.split("/")[3]}
              giftOptionPreview={selectedGiftOptionPreview}
              imagesCached={imagesCached}
              noOverflow={!!isEmbedded}
              resetGiftOptionID={() =>
                pushNewUrl(generateBrowseUrl({ giftOptionSlug: null }))
              }
              selectedBrandValues={brandValues}
              priceFilterMin={priceFilterMin}
              priceFilterMax={priceFilterMax}
              customStoreGiftOptionButton={customStoreGiftOptionButton}
              isGiftCardInCustomStore={isGiftCardInCustomStore}
              selectedCategoryID={selectedCategory?.id || null}
              storeVisible={visible}
              onClose={() => {
                if (locationStack.background) {
                  pushNewUrl(
                    locationStack.background.pathname,
                    locationStack.background.state,
                  )
                }
              }}
              isProductOpen={!!locationStack.product}
              isEmbeddedCustomStore={isEmbeddedCustomStore}
            />
          </ShippingCountriesSelectorProvider>
        )}

        {locationStack.product && modalPDPParams && (
          <ShippingCountriesSelectorProvider>
            <PDPModal
              productData={modalPDPParams}
              onSelectProductID={onSelectProductID}
              onAfterSelectProduct={onSelectProduct}
              setPDPProduct={setPDPProduct}
              customStoreProductButton={customStoreProductButton}
              customStoreGiftCardButton={customStoreGiftCardButton}
              isGiftCardInCustomStore={isGiftCardInCustomStore}
              unsetPDPProduct={() => {
                if (locationStack.giftOption) {
                  pushNewUrl(
                    locationStack.giftOption.pathname,
                    locationStack.giftOption.state,
                  )
                } else if (locationStack.background) {
                  pushNewUrl(
                    locationStack.background.pathname,
                    locationStack.background.state,
                  )
                }
              }}
              useVirtualUrl={useVirtualUrl}
              storeVisible={visible}
              isEmbeddedCustomStore={isEmbeddedCustomStore}
            />
          </ShippingCountriesSelectorProvider>
        )}
      </div>

      <CartReplaceModal />
      <RealmChooseModal
        close={() => setRealmChoiceModalOpen(false)}
        isOpen={realmChoiceModalOpen}
      />
    </StoreContext.Provider>
  )
}

export default StoreContent
