import tw from "twin.macro"

import { MenuItem } from "./MenuItem"
import useStoreContext from "../../hooks/useStoreContext"
import { ReactComponent as SearchIcon } from "../../images/search.svg"
import StoreLink from "../StoreLink"

import { useGlobalState } from "@/common/GlobalState"
import { generateRealmPath } from "@/common/realm"

const StandardCategories = [
  {
    slug: "gift-of-choice",
    label: "Gift of Choice",
  },
  {
    slug: "employee-appreciation",
    label: "Employee Gifts",
  },
  {
    slug: "client-gifts",
    label: "Client Gifts",
  },
  {
    slug: "sales-prospecting",
    label: "Sales Prospecting",
  },
  {
    slug: "best-sellers",
    label: "Best Sellers",
  },
  {
    slug: "branded-swag",
    label: "Branded Swag",
  },
]

export default function Menu() {
  const { screen, pushNewUrl, selectedSupercategory } = useStoreContext()
  const [hideMenuItems] = useGlobalState("hideMenuItems")

  function supercategoryIsSelected(category: string) {
    return screen === "category" && selectedSupercategory?.slug === category
  }

  const searchArea = (
    <StoreLink
      tw="block h-[44px] w-[44px] flex flex-row items-center justify-center transition-all text-gray-600 rounded-full hover:bg-gray-100 active:(bg-gray-200 scale-95)"
      to={generateRealmPath(null, "/browse/search")}
      css={[
        screen === "search" &&
          tw`bg-primary-new-050 font-medium text-primary-new-600 hover:bg-primary-new-050 active:bg-primary-new-050`,
      ]}
    >
      <SearchIcon tw="stroke-current" />
    </StoreLink>
  )

  return (
    <div
      tw="flex flex-row items-center border-b border-[#ebe8ff] gap-4"
      css={[hideMenuItems && tw`hidden`]}
    >
      <div tw="flex-1 flex flex-row items-center gap-2 w-full py-4 overflow-x-auto px-4">
        <MenuItem
          to={generateRealmPath(null, "/browse")}
          selected={screen === "home" || supercategoryIsSelected("all")}
          label="Home"
          pushNewUrl={pushNewUrl}
        />
        {StandardCategories.map((category) => (
          <MenuItem
            to={generateRealmPath(null, `/browse/category/${category.slug}`)}
            selected={supercategoryIsSelected(category.slug)}
            label={category.label}
            pushNewUrl={pushNewUrl}
            key={category.slug}
          />
        ))}
        <div tw="mr-auto">
          <div tw="hidden lg:block">{searchArea}</div>
        </div>
      </div>
      <div tw="lg:hidden pr-2">{searchArea}</div>
    </div>
  )
}
