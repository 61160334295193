import tw from "twin.macro"

import { ReactComponent as GlobalFlag } from "@/assets/icons/global.svg"
import staticAssetUrl from "@/common/staticAssetUrl"
import { CountryCodeEnum } from "@/types/graphql-types"

interface Props {
  code: CountryCodeEnum
  size?: "s" | "m" | "l"
}

export const CountryFlag = ({ code, size = "m" }: Props) => {
  if (code === CountryCodeEnum.GLOBAL) {
    return (
      <GlobalFlag
        css={[
          tw`text-gray-400`,
          size === "s" && tw`w-3 h-3`,
          size === "m" && tw`w-5 h-5`,
          size === "l" && tw`w-8 h-8`,
        ]}
      />
    )
  }

  const src = staticAssetUrl(`static/flags/${size}/${code}.svg`)

  return (
    <img tw="rounded-sm text-gray-400" src={src} alt={`${code} country flag`} />
  )
}
