import { gql } from "@apollo/client"

import { IMAGE_FRAGMENT } from "@/graphql"

export const PDP_RELATED_PRODUCTS = gql`
  query Store_PDP_RelatedProducts(
    $brandSlug: String!
    $productSlug: String!
    $shippingCountries: [CountryCodeEnum!]
  ) {
    productDetails(brandSlug: $brandSlug, productSlug: $productSlug) {
      id
      brand {
        id
        primaryGiftOption {
          id
          slug
          name
          products(shippingCountries: $shippingCountries) {
            id
            name
            price
            slug
            imagesScalable
            productImages {
              imageXmedium {
                ...Image
              }
              imageThumb {
                ...Image
              }
            }
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`
