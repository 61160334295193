import CountryList from "./CountryList"

import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  icon: React.ReactNode
  title: string
  description: string
  countries: ShippingCountry[]
  onClick?: () => void
}

const CountryGroupOption = ({
  icon,
  title,
  description,
  countries,
  onClick,
  ...props
}: Props) => {
  return (
    <div
      tw="grid grid-cols-2 hover:bg-gray-075 active:bg-gray-150 cursor-pointer"
      onClick={onClick}
    >
      <div tw="pl-4 py-4" {...props}>
        <div tw="flex gap-2 pb-2">
          {icon}
          <div>{title}</div>
        </div>
        <div tw="font-normal leading-5">{description}</div>
      </div>

      <CountryList countries={countries} />
    </div>
  )
}

export default CountryGroupOption
