import ShippingCountryListFilter from "./ShippingCountryListFilter/ShippingCountryListFilter"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useShippingCountriesSelector } from "@/store/hooks/useShippingCountriesSelector"
import { ShippingCountryGroup } from "@/types/graphql-types"

interface Props {
  shippingCountryGroup: ShippingCountryGroup
}

const PopoverShippingCountryList = ({ shippingCountryGroup }: Props) => {
  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")
  const {
    selectedShippingCountries,
    setSelectedShippingCountries,
    expandShippingCountries,
  } = useShippingCountriesSelector()

  return (
    <ShippingCountryListFilter
      countries={{
        domestic: expandShippingCountries(shippingCountryGroup.domestic || []),
        global: expandShippingCountries(shippingCountryGroup.global || []),
      }}
      selectedCountries={selectedShippingCountries}
      setSelectedCountries={setSelectedShippingCountries}
      withinScrollable={true}
      withProUpsell={!hasProPlan}
    />
  )
}

export default PopoverShippingCountryList
