import React from "react"
import tw from "twin.macro"

import { DesktopImage, MobileImage } from "./DropShaddowImage"
import { GridCenterContainer } from "./PositionHelper"
import TitleContainer from "./TitleContainer"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import Accordion from "../../components/Accordion"

interface Props {
  linkTo: string
  options: SlideData[]
  image: {
    desktop: string
    mobile: string
  }
}

const HowGoodyIsDifferent = ({ linkTo, options, image }: Props) => (
  <GridCenterContainer>
    <AccordionContainer>
      <div tw="max-w-xl lg:pr-24">
        <TitleContainer title="How Goody is different" align="left" />
        <MobileImage tw="pt-5 pb-7" src={image.mobile} />
        <div tw="pb-7 md:py-6">
          <Accordion items={options} />
        </div>
        <PurpleGradientLink to={linkTo}>How Goody Works</PurpleGradientLink>
      </div>
    </AccordionContainer>
    <DesktopImage
      tw="pr-10 z-0 object-contain 2xl:h-[800px]"
      src={image.desktop}
    />
  </GridCenterContainer>
)

const AccordionContainer = tw.div`
  px-5 z-0
  lg:justify-self-end
`

export default HowGoodyIsDifferent
