import { CountryFlag } from "@/common/CountryFlag"
import FilterButton from "@/store/filters/FilterButton"
import { CountryCodeEnum, ShippingCountry } from "@/types/graphql-types"

interface Props {
  countries: ShippingCountry[]
  open: boolean
  onOpen: () => void
}

const CountryListFilterButton = ({ countries, onOpen, open }: Props) => {
  const isActive =
    countries.length !== 1 &&
    !countries.map((c) => c.code).includes(CountryCodeEnum.US)
  const displayCountries = countries.slice(0, 2)
  const hiddenCountriesSize = countries.length - displayCountries.length
  const hiddenCountriesText = ` +${hiddenCountriesSize}`

  const countryNames = displayCountries
    .map((country) =>
      country.code === CountryCodeEnum.US ? "USA" : country.name,
    )
    .join(", ")

  return (
    <FilterButton
      onClick={onOpen}
      filterIsActive={isActive}
      filterDropdownIsOpen={open}
    >
      {displayCountries.map((country) => (
        <div key={country.code} tw="h-6 w-5 flex flex-col justify-center">
          <CountryFlag code={country.code} size="m" />
        </div>
      ))}
      <span tw="hidden lg:block whitespace-nowrap overflow-ellipsis">
        {countryNames}
        {hiddenCountriesSize > 0 && hiddenCountriesText}
      </span>
      {hiddenCountriesSize > 0 && (
        <span tw="lg:hidden">{hiddenCountriesText}</span>
      )}
    </FilterButton>
  )
}

export default CountryListFilterButton
