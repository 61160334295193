import styled from "@emotion/styled"
import { ChangeEvent, useMemo, useState } from "react"
import tw from "twin.macro"

import SearchField from "../../../common/SearchField"

import { CountryFlag } from "@/common/CountryFlag"
import { useShippingCountriesSelector } from "@/store/hooks/useShippingCountriesSelector"
import {
  CountryCodeEnum,
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

interface Props {
  onClick: (country: ShippingCountry) => void
  insideDropdown?: boolean
  onlyInternationalCountries?: boolean
}

const CountryList = ({
  onClick,
  insideDropdown,
  onlyInternationalCountries = true,
}: Props) => {
  const { selectedShippingCountry, shippingCountries } =
    useShippingCountriesSelector()

  const [searchTerm, setSearchTerm] = useState("")
  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(value)
  }

  const nonGlobalCountries = useMemo(
    () =>
      shippingCountries.filter(
        (country) => country.code !== CountryCodeEnum.GLOBAL,
      ),
    [shippingCountries],
  )
  const eligibleCountries = onlyInternationalCountries
    ? nonGlobalCountries.filter((country) =>
        country.groups.includes(ShippingCountryGroupEnum.global),
      )
    : nonGlobalCountries

  const filteredCountries = eligibleCountries.filter((country) =>
    country.name.toLowerCase().match(searchTerm.toLowerCase().trim()),
  )

  const countryListItems = () => {
    if (filteredCountries.length === 0) {
      return <div tw="text-center text-gray-500 mt-4">No results</div>
    }

    return filteredCountries.map((country: ShippingCountry) => (
      <CountryListItem
        active={selectedShippingCountry.code === country.code}
        onClick={() => onClick(country)}
        key={country.code}
      >
        <CountryFlag code={country.code} size="m" />
        <h2 tw="pl-3">{country.name}</h2>
      </CountryListItem>
    ))
  }

  return (
    <Container insideDropdown={insideDropdown}>
      <SearchField
        onChange={handleSearch}
        value={searchTerm}
        tw="w-full mb-2"
      />
      {countryListItems()}
    </Container>
  )
}

const Container = styled.div<{ insideDropdown: boolean | undefined }>`
  ${({ insideDropdown }) =>
    !insideDropdown && tw`lg:w-3/5 p-7 lg:pl-4 overflow-y-scroll`}
`

const CountryListItem = styled.button<{ active: boolean }>`
  ${tw`flex items-center py-2 px-4 w-full rounded transition-colors`}
  ${({ active }) => active && tw`text-primary-500 bg-primary-050 font-medium`}
  &:hover {
    ${tw`bg-gray-100`}
  }
  &:active {
    ${tw`bg-gray-150`}
  }
`

export default CountryList
