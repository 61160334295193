import "react-day-picker/lib/style.css"

import update from "immutability-helper"
import { lowerCase } from "lodash-es"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import Confetti from "react-confetti"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import Modal from "react-modal"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"
import { useDebouncedCallback } from "use-debounce"

import { AddBalanceModal } from "./AddBalanceModal"
import CardAndMessage from "./CardAndMessage"
import AlcoholInfo from "./components/AlcoholInfo"
import DirectSendValidation from "./components/DirectSendValidation"
import { ReviewGiftsModal } from "./components/modals/ReviewGiftsModal"
import OnboardingWelcomeWithCart from "./components/OnboardingWelcomeWithCart"
import OnlyChargedForAcceptedGiftsNotice from "./components/OnlyChargedForAcceptedGiftsNotice"
import {
  DiscardGiftButton,
  EventAttachmentIcon,
  PageBanner,
  RestrictionText,
} from "./components/PageBanner"
import SendMethodMenu from "./components/SendMethodMenu"
import SendSummary from "./components/SendSummary"
import CSVModal from "./csv/CSVModal"
import EditScheduledGiftHeader from "./EditScheduledGiftHeader"
import { PaymentSection } from "./PaymentSection"
import { CurrentGiftSendForm } from "./sendTypes"
import useSalesforceSend from "./useSalesforceSend"
import Options from "./v3/Options"
import ProductList from "./v3/ProductList"
import Recipients from "./v3/Recipients"
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg"
import AgeVerificationModal from "../common/AgeVerificationModal"
import { track, useScreen } from "../common/analytics"
import Notice from "../common/components/Notice"
import SendBlock from "../common/components/SendBlock"
import FancyButton from "../common/FancyButton"
import { getProduct } from "../common/gifts"
import { BatchRecipient, useGlobalState } from "../common/GlobalState"
import { useAddBalanceModal } from "../common/hooks/addBalanceModal"
import { useCurrentGift } from "../common/hooks/currentGift"
import { useFeatureAccess } from "../common/hooks/featureAccess"
import { eventEligibleForSchedule, useGiftData } from "../common/hooks/giftData"
import {
  BALANCE_PAYMENT_METHOD_ID,
  CORPORATE_ACCOUNT_PAYMENT_METHOD_ID,
} from "../common/hooks/payment"
import { useAbandonedCarts } from "../common/hooks/useAbandonedCarts"
import useSendBlock from "../common/hooks/useSendBlock"
import { ReactComponent as LayoutIcon } from "../common/images/layout.svg"
import { ReactComponent as UserIcon } from "../common/images/user.svg"
import { modalStyle } from "../common/modal"
import RainbowHeading from "../common/RainbowHeading"
import { generateRealmPath } from "../common/realm"
import {
  generateEmptyRecipients,
  getNonEmptyRecipientRows,
} from "../common/recipient"
import SmallButton, { OutlinedSmallButton } from "../common/SmallButton"
import { successToast } from "../common/toast"
import {
  filterOutBlankRecipients,
  isBlank,
  isPresent,
} from "../common/utilities"

import GradientBackground from "@/common/GradientBackground"
import { useBusinessSend } from "@/common/hooks/send"
import {
  USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_KEY,
  USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_VALUE,
} from "@/common/userManuallyOmittedCreditSessionStorage"
import GiftDispenserConfigure from "@/send/components/GiftDispenserConfigure"
import { GiftPreviewButton } from "@/send/components/giftPreview/GiftPreviewButton"
import { GiftPreviewModal } from "@/send/components/giftPreview/GiftPreviewModal"
import { GiftPreviewNoCartModal } from "@/send/components/giftPreview/GiftPreviewNoCartModal"
import { CreditCardWarningModal } from "@/send/components/modals/CreditCardWarningModal"
import StartGift from "@/send/components/startGift/StartGift"
import { trackClickPreview, useGiftPreview } from "@/send/hooks/giftPreview"
import GiftPreviewGray from "@/send/images/gift-preview-gray.svg"
import SendErrorAlertBubble from "@/send/SendErrorAlertBubble"
import RecipientTypeHeader from "@/send/v3/RecipientTypeHeader"
import SmartLink from "@/send/v3/SmartLink"
import { GIFT_CARDS_BRAND_NAME } from "@/store/utils"
import {
  BatchSendMethod,
  InternationalShippingTierEnum,
  PlusEnrollmentStatus,
} from "@/types/graphql-types"

const COLLAPSED_RECIPIENTS_THRESHOLD = 500

interface Props {
  fromSalesforce?: boolean
}

const SendV3 = ({ fromSalesforce }: Props) => {
  useScreen("Business - Send V3")
  const [currentGift] = useCurrentGift()
  useAbandonedCarts(currentGift)

  const { previewVisible, openPreview } = useGiftPreview()

  const currentGiftForm = useForm<CurrentGiftSendForm>({
    defaultValues: {
      fromName: currentGift.fromName,
      message: currentGift.message,
    },
  })

  const {
    setExpireAtOption,
    setIsGiftDispenser,
    setScheduledSend,
    onSetCVC,
    setFromData,
    updateGiftCalendarSetting,
    resetCurrentGift,
    isDirectSend,
    setSendMethod,
    setIsSmartLink,
    setSmartLinkQuantity,
    incrementSmartLinkQuantity,
    decrementSmartLinkQuantity,
    setsmartLinkApprovalRequired,
    setOmitCredit,
  } = useGiftData()

  // Global state was being updated on every input change which was causing performance issues.
  // Using a debouncer, we update state only this component and then sync to global state half a
  // second after the last input change.
  const debouncedSetCurrentGift = useDebouncedCallback(
    (newValues) => setFromData(newValues.fromName!, newValues.message!),
    500,
  )
  useEffect(() => {
    const subscription = currentGiftForm.watch((value) =>
      debouncedSetCurrentGift(value),
    )
    return () => subscription.unsubscribe()
  }, [currentGiftForm, debouncedSetCurrentGift])

  const {
    clearCurrentGift,
    priceEstimate,
    userData,
    sendPlusGift,
    runPriceEstimate,
    priceEstimateLoading,
    priceEstimateIsCalculated,
    setPotentialRecipientsCount,
    clearError,
    clearErrors,
  } = useBusinessSend()

  const [globalRecipients, setGlobalRecipients] = useGlobalState("recipients")
  const [, setIsProPlanModalOpen] = useGlobalState("isProPlanModalOpen")
  const [recipients, setRecipients] = useState(globalRecipients)
  const debouncedSetGlobalRecipients = useDebouncedCallback(
    (recipients) => setGlobalRecipients(recipients),
    1000,
  )
  useEffect(() => {
    debouncedSetGlobalRecipients(recipients)
  }, [recipients, debouncedSetGlobalRecipients])

  useEffect(() => {
    setRecipients(globalRecipients)
  }, [globalRecipients, setRecipients])

  useEffect(() => {
    if (
      !recipients.every(
        (recipient) =>
          isBlank(recipient.email) &&
          isBlank(recipient.firstName) &&
          isBlank(recipient.lastName),
      )
    ) {
      clearError("recipients")
    }
  }, [recipients])

  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const [sendPageMode, setSendPageMode] = useGlobalState("sendPageMode")
  const [user] = useGlobalState("user")
  const [selectedRecipientIndex, setSelectedRecipientIndex] = useGlobalState(
    "sendPageRecipientIndex",
  )
  const { hasFeature } = useFeatureAccess()

  const history = useHistory()

  const { sendBlockType } = useSendBlock()

  const [csvModalOpen, setCSVModalOpen] = useState(false)
  const [creditCardWarningModalOpen, setCreditCardWarningModalOpen] =
    useState(false)
  const [reviewModalOpen, setReviewModalOpen] = useState(false)
  const [isSending, setSending] = useState(false)
  const [displayAgeVerification, setDisplayAgeVerification] = useState(false)

  const { addBalanceModalOpen, closeAddBalanceModal, defaultBalance } =
    useAddBalanceModal()

  const scheduledEventAttached = recipients.some(
    (recipient) => recipient.scheduledEventId && recipient.scheduledEventKind,
  )

  const eventRecipient = recipients[0]

  const eventDate =
    scheduledEventAttached && eventRecipient.eventDate
      ? moment(eventRecipient.eventDate).toDate()
      : null

  const [scheduledSendOnEventDate, setScheduledSendOnEventDate] =
    useState<boolean>(false)

  useEffect(() => {
    const scheduledForEventDate = moment(eventDate).isSame(
      moment(currentGift.scheduledSendOnDate),
      "day",
    )
    const selectScheduledSendOnEventDateOption =
      !!eventDate &&
      eventEligibleForSchedule(eventDate) &&
      scheduledForEventDate
    setScheduledSendOnEventDate(selectScheduledSendOnEventDateOption)
  }, [])

  // Get current valid recipients
  const cleanedRecipients = filterOutBlankRecipients(recipients)

  useEffect(() => {
    // If the number of recipients has changed to be lower
    // than the last selected recipient's index we set the selected recipient to the last recipient in the list
    if (
      selectedRecipientIndex &&
      selectedRecipientIndex > cleanedRecipients.length - 1
    ) {
      setSelectedRecipientIndex(cleanedRecipients.length - 1)
    }
  }, [cleanedRecipients.length, selectedRecipientIndex])

  const potentialRecipients = useMemo(() => {
    return getNonEmptyRecipientRows(recipients)
  }, [recipients])

  function clearRecipientEvent() {
    setScheduledSendOnEventDate(false)
    setRecipients(
      update(recipients, {
        0: {
          scheduledEventId: { $set: null },
          scheduledEventKind: { $set: null },
          eventDate: { $set: null },
        },
      }),
    )
  }

  useEffect(() => {
    setPotentialRecipientsCount(potentialRecipients)
  }, [potentialRecipients])

  const me = userData?.me

  // If the batch is associated to an event, select schedule for event by default option
  useEffect(() => {
    if (scheduledSendOnEventDate) {
      setScheduledSend({ option: true })
    }
  }, [scheduledSendOnEventDate])

  const isEnrolled = enrollmentStatus !== PlusEnrollmentStatus.NONE

  useSalesforceSend(fromSalesforce ?? false, isEnrolled)

  // When enrollment status changes, check if user is not enrolled and redirect them to the signup page
  // This was moved above the "send" method since it was causing a React conditional hook error
  useEffect(() => {
    if (!isEnrolled && window.location.hash !== "#debug") {
      history.replace(generateRealmPath("business", "/signup"))
    }
  }, [isEnrolled])

  useEffect(() => {
    if (
      currentGift.autopayPaymentMethodID === BALANCE_PAYMENT_METHOD_ID &&
      currentGift.expireAtOption === "none"
    ) {
      alert(
        "Gifts paid with balance must have an expiration date, so that any gifts that haven't been accepted will refund to your balance.\n\nThe expiration date has been set to 6 weeks.",
      )
      setExpireAtOption("sixWeeks")
    }
  }, [currentGift.autopayPaymentMethodID, currentGift.expireAtOption])

  const addRecipientRows = (count: number) => {
    setRecipients((previousRecipients) => [
      ...previousRecipients,
      ...generateEmptyRecipients(count),
    ])
  }

  const collapseRecipients =
    cleanedRecipients.length >= COLLAPSED_RECIPIENTS_THRESHOLD

  const products = currentGift.cart
  const product = getProduct(currentGift)

  const isGiftCard = product?.brandName === GIFT_CARDS_BRAND_NAME
  const isCustomStoreWithCreditExcludedItems =
    product?.customStore?.hasCreditExcludedItems ?? false

  const hasCredits = !!userData?.me?.credit && userData?.me?.credit > 0

  const showSuccessToast = () => {
    toast((t) => <SuccessToast onClose={() => toast.dismiss(t.id)} />, {
      duration: 8000,
      style: { padding: 0, maxWidth: "none" },
    })
  }

  const handleSend = async () => {
    setSending(true)
    await send()
    setSending(false)
  }

  const scrollToSelector = (selector: string, center: boolean = true) => {
    setTimeout(() => {
      document.querySelector(selector)?.scrollIntoView({
        behavior: "smooth",
        ...(center && {
          block: "center",
          inline: "center",
        }),
      })
    }, 1000)
  }

  const send = async () => {
    setReviewModalOpen(false)
    await sendPlusGift({
      onValidationError: () => {
        scrollToSelector(".send-error-alert-bubble:not([style='display:none'])")
      },
      onError: () => {
        scrollToSelector(".field-error")
      },
      onSuccess: () => {
        if (
          me?.calendarIntegration?.giftSetting &&
          me?.calendarIntegration?.giftSetting !=
            currentGift.giftCalendarSetting
        ) {
          updateGiftCalendarSetting(me?.calendarIntegration?.giftSetting)
        }
        showSuccessToast()
      },
      sendPageMode,
      setSendPageMode,
      setDisplayAgeVerification,
      isSalesforceSend: fromSalesforce ?? false,
    })
  }

  const handleReviewGiftButtonClick = () => {
    const balance = priceEstimate?.effectiveBalanceData?.effectiveBalance
    const giftTotalAmount = priceEstimate?.totalPriceEstimate.estGroupTotalHigh

    if (
      currentGift.paymentMethod?.id !== BALANCE_PAYMENT_METHOD_ID &&
      currentGift.paymentMethod?.id !== CORPORATE_ACCOUNT_PAYMENT_METHOD_ID &&
      balance &&
      giftTotalAmount &&
      balance >= giftTotalAmount
    ) {
      track("Info", { name: "Send - Balance Credit Card Warning - Display" })
      setCreditCardWarningModalOpen(true)
    } else {
      setReviewModalOpen(true)
    }
  }

  function handleReset() {
    if (window.confirm("Are you sure you want to reset your gift?")) {
      resetCurrentGift()
      clearErrors()
      currentGiftForm.reset({
        fromName: "",
        message: "",
      })
      setGlobalRecipients(generateEmptyRecipients(3))
      scrollToSelector("body", false)
    }
  }

  const [showPhoneField, setShowPhoneField] = useState(false)

  // If the user has not yet sent a gift batch, and they have credit, then
  // automatically apply credit to the gift batch.
  useEffect(() => {
    if (
      currentGift.omitCredit &&
      user &&
      !user.hasCreatedAGiftBatch &&
      user.credit > 0 &&
      // Do not apply credit if the user has manually un-applied credit
      window.sessionStorage.getItem(
        USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_KEY,
      ) !== USER_MANUALLY_OMITTED_CREDIT_SESSION_STORAGE_VALUE
    ) {
      setOmitCredit(false)
    }
  }, [user, setOmitCredit])

  if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
    return null
  }

  const sendButtonOrSendBlock = (
    <>
      {sendBlockType === "NONE" ? (
        <>
          <OnlyChargedForAcceptedGiftsNotice tw="text-lg hidden lg:flex mb-3.5" />
          {currentGift.sendMethod === BatchSendMethod.direct_send && (
            <div tw="mt-4 pb-4 text-gray-800 mx-auto max-w-[480px]">
              By sending this gift batch, you confirm that all recipient
              addresses are correct. If a gift item is returned to sender due to
              incorrect address, you may be responsible for a re-shipment fee to
              have the gift re-sent to the correct location.
            </div>
          )}
          <div tw="text-center pt-4">
            <FancyButton
              label="Review gift"
              onClick={handleReviewGiftButtonClick}
              loading={isSending}
            />
          </div>
        </>
      ) : (
        <SendBlock
          sendBlockType={sendBlockType}
          paymentMethod={currentGift.paymentMethod}
          onSetCVC={() => {
            if (currentGift.paymentMethod) {
              successToast(
                "Updated payment card. You can now check out with this card.",
              )
              onSetCVC()
            }
          }}
          potentialRecipientsCount={potentialRecipients}
          plusLimitedGiftCount={user?.plusLimitedGiftCount || undefined}
        />
      )}

      <div tw="pt-8 flex flex-row justify-center gap-5">
        {previewVisible && (
          <button
            onClick={() => {
              openPreview()
              trackClickPreview({ location: "Bottom", type: "Gift" })
            }}
            tw="text-gray-500 gap-2 px-2 py-1 rounded transition-colors hover:bg-gray-100 active:bg-gray-200 flex flex-row justify-center"
          >
            <img src={GiftPreviewGray} alt="Gift preview" />
            Preview gift
          </button>
        )}
        <button
          onClick={handleReset}
          tw="text-gray-500 px-2 py-1 rounded transition-colors hover:bg-gray-100 active:bg-gray-200"
        >
          Reset gift
        </button>
      </div>
    </>
  )

  const cartIsEmpty = currentGift.cart.length === 0

  return (
    <div
      tw="lg:border-t border-primary-100 relative"
      css={{ overflowX: "clip" }}
    >
      <Helmet>
        <title>Send a Gift – Goody for Business</title>
      </Helmet>
      <GradientBackground visible={cartIsEmpty} />
      {/*<div*/}
      {/*  tw="h-[200px] z-[-10] absolute translate-y-[-200px] w-full"*/}
      {/*  style={{*/}
      {/*    background: "linear-gradient(360deg, #FAFBFE 0%, #FDFDFF 100%)",*/}
      {/*    animationDuration: "0.5s",*/}
      {/*    animationTimingFunction: "ease-out",*/}
      {/*  }}*/}
      {/*  className="animated fadeInOpacity"*/}
      {/*/>*/}
      <div tw="w-full lg:w-[1080px] xl:w-[1280px] 2xl:w-[1440px] mx-auto flex flex-col lg:flex-row items-stretch z-10 relative">
        <div
          tw="w-full flex-1 lg:(pr-8) xl:(pr-0 px-[5rem]) 2xl:pl-[12rem] 2xl:pr-[8rem]"
          css={[
            cartIsEmpty &&
              tw`2xl:pl-0 2xl:pr-0 lg:px-0 lg:max-w-[720px] lg:mx-auto`,
          ]}
        >
          {cartIsEmpty ? (
            <div tw="mx-6 lg:-mx-40">
              <SendErrorAlertBubble
                text="Please select a gift"
                subtext="What would you like to send?"
                error="product"
                position="below"
              >
                <StartGift />
              </SendErrorAlertBubble>
              <div tw="h-6" />
            </div>
          ) : (
            <OnboardingWelcomeWithCart />
          )}

          <div tw="p-6 pt-6 lg:pt-12 pb-0">
            <SendMethodMenu sendV3={true} />
          </div>
          {
            // When editing, display the scheduled gift edit header as the page
            // banner. When not editing (i.e. new gift batch), only display the
            // banner when a scheduled event is attached.
          }
          {sendPageMode === "updateGiftBatch" ? (
            <EditScheduledGiftHeader
              currentGift={currentGift}
              eventRecipient={eventRecipient}
              onDiscardChanges={() => {
                clearCurrentGift()
                setSendPageMode("createGiftBatch")
              }}
            />
          ) : (
            scheduledEventAttached && (
              <div tw="p-6 pt-0">
                <PageBanner>
                  <EventAttachmentIcon
                    eventKind={eventRecipient.scheduledEventKind}
                    className="banner"
                  />
                  <div>
                    <div
                      tw="text-primary-500 text-xl pb-1"
                      className="data-hj-suppress ph-no-capture fs-mask"
                    >
                      {`Sending a gift for ${recipients[0].firstName} ${
                        recipients[0].lastName
                      }’s ${lowerCase(recipients[0].scheduledEventKind || "")}`}
                    </div>
                    <RestrictionText>
                      When sending a gift for a contact’s birthday, you can’t
                      add other gift recipients.
                    </RestrictionText>
                  </div>
                  <DiscardGiftButton onClick={() => clearRecipientEvent()}>
                    <div tw="pr-4">Discard gift</div>
                    <StyledCloseIcon />
                  </DiscardGiftButton>
                </PageBanner>
              </div>
            )
          )}

          <div tw="p-6 pt-12">
            <div>
              <CardAndMessage
                currentGift={currentGift}
                currentGiftForm={currentGiftForm}
                sendV3={true}
              />
              {previewVisible && (
                <div tw="flex flex-row justify-center transition-opacity mt-[-20px] ">
                  <GiftPreviewButton location="Message" />
                </div>
              )}
            </div>
            <DirectSendValidation sendV3={true} />

            {!hasFeature("smart_links") ? (
              <div tw="pt-10 pb-10">
                <div tw="flex flex-col md:flex-row items-center gap-3 md:gap-9 pt-6">
                  <SendErrorAlertBubble
                    text="Please add your recipients"
                    subtext="At least one recipient is required."
                    error="recipients"
                  >
                    <div tw="font-medium text-primary-600 text-3xl">
                      Recipients
                    </div>
                  </SendErrorAlertBubble>
                  {!scheduledEventAttached && (
                    <div tw="flex flex-row items-center gap-4">
                      <div tw="lg:hidden">
                        <SmallButton
                          label={showPhoneField ? "Hide phone" : "Add phone"}
                          onClick={() => setShowPhoneField(!showPhoneField)}
                          hideArrow={true}
                        />
                      </div>

                      {currentGift.isGiftDispenser && (
                        <OutlinedSmallButton
                          onClick={() => setIsGiftDispenser(false)}
                          label="Return to normal gift"
                          light={true}
                        />
                      )}
                      {!currentGift.isGiftDispenser &&
                        currentGift.sendMethod !==
                          BatchSendMethod.direct_send &&
                        hasFeature("plus_gift_dispensers") && (
                          <OutlinedSmallButton
                            onClick={() => setIsGiftDispenser(true)}
                            label="Set up giveaway"
                            light={true}
                          />
                        )}
                    </div>
                  )}
                  <div tw="pl-4"></div>
                </div>
                {isDirectSend ? (
                  <>
                    <div tw="pt-3">
                      Shipping directly to an address is only available for US
                      addresses. To gift internationally,{" "}
                      <button
                        tw="text-gray-600 hover:text-primary-new-600 transition-colors"
                        onClick={() => {
                          setSendMethod(null)
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }}
                      >
                        switch to digital notifications
                      </button>
                      .
                    </div>
                  </>
                ) : (
                  !hasFeature("pro_plan") && (
                    <div tw="pt-3">
                      Sending international?{" "}
                      <button
                        tw="font-medium text-primary-600 hover:text-primary-500 active:text-primary-800 transition-colors"
                        onClick={() => setIsProPlanModalOpen(true)}
                      >
                        Upgrade to Pro
                      </button>{" "}
                      to gift to 140+ countries worldwide.
                    </div>
                  )
                )}
              </div>
            ) : (
              <div tw="h-[60px]" />
            )}

            {currentGift.isGiftDispenser && <GiftDispenserConfigure />}
            {!currentGift.isGiftDispenser && (
              <SendErrorAlertBubble
                text="Please add your recipients"
                subtext="At least one recipient is required."
                error="recipients"
                open={(errors) =>
                  errors.recipients && hasFeature("smart_links")
                }
              >
                <RecipientContainer>
                  <div tw="lg:border border-gray-200 rounded-lg flex flex-col">
                    {hasFeature("smart_links") &&
                      currentGift.sendMethod !== "direct_send" && (
                        <RecipientTypeHeader
                          isSmartLink={currentGift.isSmartLink}
                          setIsSmartLink={(isSmartLink) => {
                            setIsSmartLink(isSmartLink)
                            if (isSmartLink) clearError("recipients")
                          }}
                        />
                      )}
                    {!currentGift.isSmartLink ? (
                      <Recipients
                        scheduledEventAttached={scheduledEventAttached}
                        addRecipientRows={addRecipientRows}
                        clearRecipientEvent={clearRecipientEvent}
                        recipients={recipients}
                        setRecipients={setRecipients}
                        collapseRecipients={collapseRecipients}
                        isAutogift={!!currentGift.isAutogift}
                        showPhoneField={showPhoneField}
                        onClickUploadCSV={() => setCSVModalOpen(true)}
                      />
                    ) : (
                      <SmartLink
                        smartLinkQuantity={currentGift.smartLinkQuantity}
                        setSmartLinkQuantity={setSmartLinkQuantity}
                        decrementSmartLinkQuantity={decrementSmartLinkQuantity}
                        incrementSmartLinkQuantity={incrementSmartLinkQuantity}
                        smartLinkApprovalRequired={
                          currentGift.smartLinkApprovalRequired
                        }
                        setsmartLinkApprovalRequired={
                          setsmartLinkApprovalRequired
                        }
                      />
                    )}
                  </div>
                  {!scheduledEventAttached && !currentGift.isSmartLink && (
                    <div tw="mt-[-2.5rem] lg:mt-0 lg:pt-3 flex flex-row items-center justify-between">
                      <div tw="flex flex-row">
                        <TableButton onClick={() => addRecipientRows(1)}>
                          Add 1 row
                        </TableButton>
                        <AddTenButton onClick={() => addRecipientRows(10)}>
                          Add 10 rows
                        </AddTenButton>
                      </div>
                      <TableButton
                        onClick={() => setShowPhoneField?.(!showPhoneField)}
                      >
                        {showPhoneField ? "Hide phone" : "Add phone"}
                      </TableButton>
                    </div>
                  )}
                </RecipientContainer>
              </SendErrorAlertBubble>
            )}
            <div tw="h-4" />

            <Options
              recipients={recipients}
              priceEstimate={priceEstimate}
              calendarIntegration={me?.calendarIntegration}
            />
            <div tw="h-12" />

            <div tw="w-full mx-auto">
              <PaymentSection
                priceEstimate={priceEstimate}
                isAlcohol={products.some((product) => product.isAlcohol)}
              />
            </div>
            <div tw="h-8" />
            <div tw="hidden lg:block">
              <div>{sendButtonOrSendBlock}</div>
              <div tw="h-32" />
            </div>
          </div>
        </div>
        {!cartIsEmpty && (
          <div tw="flex-1 max-w-[400px] mx-auto lg:mx-0 lg:bg-gray-050 relative p-6 lg:p-12 after:(content-[''] z-[-10] absolute left-0 right-[-9999px] top-0 bottom-0 lg:bg-gray-050)">
            <div tw="w-full lg:(sticky top-6 top-12)">
              <ProductList
                priceEstimate={priceEstimate}
                setGiftMessage={(message) => {
                  currentGiftForm.setValue("message", message)
                }}
              />

              {/* Alcohol is not supported by cart, so this is safe */}
              {priceEstimate && products.length > 0 && (
                <SendSummary
                  isAlcohol={products[0].isAlcohol}
                  priceEstimate={priceEstimate}
                  hideTax={false}
                  runPriceEstimate={runPriceEstimate}
                  priceEstimateLoading={priceEstimateLoading}
                  priceEstimateIsCalculated={priceEstimateIsCalculated}
                  sendV3={true}
                />
              )}

              <div tw="w-full mx-auto">
                <div tw="h-6" />
                {displayAgeVerification && product && (
                  <AgeVerificationModal verifyAge={product.isAlcohol} />
                )}
                {product?.isAlcohol && (
                  <>
                    <AlcoholInfo isBusiness={true} />

                    {hasCredits &&
                      sendBlockType !== "CORPORATE_ACCOUNT_NOT_ALLOWED" && (
                        <div tw="mb-8">
                          <Notice
                            colorName="yellow"
                            title="Credit cannot be used for alcohol purchases"
                            message="Your selected payment method will be charged for this gift."
                          />
                        </div>
                      )}
                  </>
                )}

                {isGiftCard && hasCredits && (
                  <div tw="mb-8">
                    <Notice
                      colorName="yellow"
                      title="Credit cannot be used for gift card purchases"
                      message="Your selected payment method will be charged for this gift."
                    />
                  </div>
                )}

                {isCustomStoreWithCreditExcludedItems && hasCredits && (
                  <div tw="mb-8">
                    <Notice
                      colorName="yellow"
                      title="Credit cannot be used for custom stores with gift cards or alcohol"
                      message="Your selected payment method will be charged for this gift."
                    />
                  </div>
                )}

                {currentGift.internationalShippingTier ===
                  InternationalShippingTierEnum.full && (
                  <div tw="pt-2 text-gray-500 text-sm font-text">
                    <span tw="font-medium">
                      International shipping incurs an additional fee of up to
                      $50 per international recipient
                    </span>{" "}
                    for cross-border shipping, tariffs, and taxes in the
                    destination country.
                  </div>
                )}

                <div tw="lg:hidden">{sendButtonOrSendBlock}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={csvModalOpen}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setCSVModalOpen(false)
        }}
        onAfterClose={() => {}}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <CSVModal
          onClickClose={() => setCSVModalOpen(false)}
          onSetRecipients={(recipients: BatchRecipient[]) => {
            setRecipients(recipients)
            setCSVModalOpen(false)
          }}
        />
      </Modal>
      <CreditCardWarningModal
        isOpen={creditCardWarningModalOpen}
        displayReviewGiftModal={() => setReviewModalOpen(true)}
        onRequestClose={() => {
          setCreditCardWarningModalOpen(false)
        }}
      />
      <ReviewGiftsModal
        isOpen={reviewModalOpen}
        onSubmit={() => handleSend()}
        onRequestClose={() => {
          setReviewModalOpen(false)
        }}
        currentGift={currentGift}
        isDirectSend={isDirectSend}
        hasRecipientWithContact={cleanedRecipients.some(
          (recipient) =>
            isPresent(recipient.email) || isPresent(recipient.phone),
        )}
        potentialRecipientsCount={potentialRecipients}
      />
      <AddBalanceModal
        isOpen={addBalanceModalOpen}
        close={closeAddBalanceModal}
        defaultBalance={defaultBalance}
        runPriceEstimate={runPriceEstimate}
      />
      <GiftPreviewModal />
      <GiftPreviewNoCartModal />
    </div>
  )
}

const TableButton = styled.button`
  ${tw`text-gray-500 py-2 px-3 flex flex-row items-center gap-2 rounded-[10px] `}
  ${tw`transition-all active:(scale-95) hover:bg-gray-075`}
`

const AddTenButton = styled(TableButton)`
  ${tw`transition-opacity opacity-0`}
`

const RecipientContainer = styled.div`
  &:hover {
    ${AddTenButton} {
      ${tw`opacity-100`}
    }
  }
`

interface SuccessToastProps {
  onClose: () => void
}
const SuccessToast: React.FC<SuccessToastProps> = ({ onClose }) => {
  const width = 560
  const height = 173

  useEffect(() => {
    const { body } = document
    body.addEventListener("click", onClose)

    return () => {
      body.removeEventListener("click", onClose)
    }
  }, [onClose])

  return (
    <SuccessToastContainer css={{ width }}>
      <div tw="overflow-hidden relative py-16">
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={300}
          gravity={0.05}
        />
        <RainbowHeading as="h2">Gifts sent!</RainbowHeading>
      </div>
      <ul>
        <li>
          <LayoutIcon />
          <p>
            The <strong>Overview</strong> tab shows statistics and thank you
            notes.
          </p>
        </li>
        <li>
          <UserIcon tw="stroke-2" />
          <p>
            The <strong>Recipients</strong> tab shows gift links and other
            actions.
          </p>
        </li>
      </ul>
    </SuccessToastContainer>
  )
}

const SuccessToastContainer = styled.div`
  ${tw`bg-white`}

  canvas {
    ${tw`w-full h-full opacity-30`}
  }

  ${RainbowHeading} {
    ${tw`text-3xl text-center bg-gradient-to-r`}
  }

  ul {
    ${tw`flex flex-col px-8 py-6 text-gray-500 border-t border-gray-200 gap-3`}
  }

  li {
    ${tw`flex items-center gap-5`}
  }

  strong {
    ${tw`font-medium`}
  }

  svg {
    ${tw`stroke-current stroke-2`}
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  g {
    ${tw`opacity-60`};
  }
`

export default SendV3
