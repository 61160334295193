import { gql, useLazyQuery, useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { capitalize } from "lodash-es"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useHistory, useLocation } from "react-router-dom"
import { Statsig } from "statsig-react"
import tw, { styled } from "twin.macro"

import MainContainer from "./components/MainContainer"
import VerticalPurpleBanner from "./components/VerticalPurpleBanner"
import purpleBannerDeco from "./images/purple-banner-deco.png"
import { getRestoreToken, setRestoreToken } from "./restoreToken"
import { track, useScreen } from "../common/analytics"
import { useGlobalState } from "../common/GlobalState"
import GradientButton from "../common/GradientButton"
import { getUtmParms } from "../common/gtm"
import { useAuth, useAuthErrors, useCurrentWorkspace } from "../common/hooks"
import OmniAuthButton from "../common/OmniAuthButton"
import PasswordInputField from "../common/PasswordInputField"
import { generateRealmPath } from "../common/realm"
import { getColor } from "../common/tailwind"
import TextInputField from "../common/TextInputField"
import { LoaderInverted } from "../common/UI"
import { generateUUID } from "../common/utilities"
import VerifyModal from "../common/VerifyModal"
import { ROOT_DATA_QUERY } from "../graphql"
import { GET_WORKSPACE_INVITE } from "../workspaceInvitation/queries"

import SSOSignUpButton from "@/signUp/SSOSignUpButton"
import {
  Auth_SignUpMutation,
  GetWorkspaceInviteQuery,
  GetWorkspaceInviteQueryVariables,
  Landing_PlusEnrollMutation,
  Landing_PlusEnrollMutationVariables,
  OrganizationRequireSsoSetting,
  PlusEnrollmentStatus,
} from "@/types/graphql-types"

// Right now because of a bug, we refresh the page instead of refetching and reloading
const SKIP_REFETCH_ON_SIGNUP = true

// When loading the signup page, a token can be loaded from:
// - The URL state as a hash
// - Local storage
//
// This object stores the current token state for whether the token fetch has
// been completed, and if there is a token.
interface TokenState {
  fetchComplete: boolean
  token: string | null
}

type GiftingToType = "Employees" | "Customers" | "Sales Prospects"

// The sign up page allows users to sign up as new users creating a new
// organization, or as a new user invited by an existing user to a workspace, when
// the token hash parameter is provided. In that case, the user is redirected
// from /workspace-invite (which detects the user is not logged in) to this page.
const SignUp: React.FC = () => {
  useScreen("Business - Signup", {
    formVariant: "v1",
  })

  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const [signedIn] = useGlobalState("signedIn")
  const [user] = useGlobalState("user")

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [companyName, setCompanyName] = useState("")
  const { resetCurrentWorkspace, updateCurrentWorkspace } =
    useCurrentWorkspace()
  const _enrollmentInProgress = useRef(false)
  const history = useHistory()

  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const [giftingToTypes, setGiftingToTypes] = useState(new Set<GiftingToType>())
  const [hasRequireSSO, setHasRequireSSO] = useState(false)

  const location = useLocation()

  const saveAndDeleteSearchParams = (
    searchParams: URLSearchParams,
    name: string,
  ) => {
    if (searchParams.has(name)) {
      window.localStorage?.setItem(name, searchParams.get(name)!)
      searchParams.delete(name)
    }
  }

  useEffect(() => {
    if (!signedIn) {
      const searchParams = new URLSearchParams(location.search)

      saveAndDeleteSearchParams(searchParams, "referring_code")
      saveAndDeleteSearchParams(searchParams, "referring_gift_id")
      location.search = searchParams.toString()
      history.replace(location)
    }
  }, [])

  const toggleGiftingToType = (type: GiftingToType) => {
    if (giftingToTypes.has(type)) {
      giftingToTypes.delete(type)
    } else {
      giftingToTypes.add(type)
    }
    setGiftingToTypes(new Set(giftingToTypes))
  }
  const [source, setSource] = useState("")

  const [verifyOpen, setVerifyOpen] = useState(false)

  const [effectiveDate] = useState(new Date())
  const [agreementChecked, setAgreementChecked] = useState(false)
  const [agreementCheckedAt, setAgreementCheckedAt] = useState<Date | null>(
    null,
  )

  const [getWorkspaceInvite, workspaceInviteResult] = useLazyQuery<
    GetWorkspaceInviteQuery,
    GetWorkspaceInviteQueryVariables
  >(GET_WORKSPACE_INVITE, { fetchPolicy: "network-only" })

  const [plusEnroll, { loading }] = useMutation<
    Landing_PlusEnrollMutation,
    Landing_PlusEnrollMutationVariables
  >(PLUS_ENROLL_MUTATION)

  const { handleSignUpPlus, signUpLoading } = useAuth()
  useAuthErrors()

  // This page supports workspace invites, so display that here.
  const { hash, search } = useLocation()
  const { called } = workspaceInviteResult

  const tokenFromHash = hash.split("#token=")[1]
  const [tokenState, setTokenState] = useState<TokenState>({
    // Set fetchComplete to true if a token was restored.
    fetchComplete: !!tokenFromHash,
    token: tokenFromHash,
  })
  const token = tokenState.token

  const queryParams = new URLSearchParams(search)
  const redirectTo = queryParams.get("redirect")
  const isEE = queryParams.has("ee")
  const isPro = queryParams.has("pro")

  // Add autoenroll param to automatically enroll after OAuth redirect
  queryParams.set("autoenroll", "true")

  // Get correct redirect path for post-OmniAuth signup.
  const redirectPath = `/signup?${queryParams.toString()}`

  const fillFormDev = () => {
    setFirstName("Goody")
    setLastName("User")
    setEmail(
      generateUUID().replaceAll("-", "").substring(0, 8) + "@ongoody.com",
    )
    setPassword(import.meta.env.VITE_FILL_FORM_DEV_PASSWORD || "")
    setCompanyName("Goody")
    toggleGiftingToType("Employees")
    setSource("Received a gift")
    setAgreementChecked(true)
    setAgreementCheckedAt(new Date())
  }

  // Restore the token from localstorage, if it exists.
  useEffect(() => {
    if (tokenState.fetchComplete) {
      return
    }

    const restoredToken = getRestoreToken()

    if (restoredToken && enrollmentStatus === PlusEnrollmentStatus.NONE) {
      setTokenState({
        fetchComplete: true,
        token: restoredToken,
      })
    } else {
      setTokenState({
        fetchComplete: true,
        token: null,
      })
    }
  }, [])

  // For restoring a token, this will only be called when the new hash is seen
  // on the subsequent rerender.
  if (token && !called) {
    getWorkspaceInvite({ variables: { workspaceInviteToken: token } })
  }

  const getGiftingTo = () => {
    const giftingTo = Array.from<string>(giftingToTypes)
    return giftingTo
  }

  const handleEnroll = async () => {
    _enrollmentInProgress.current = true

    let statsigStableID: string | null = null

    try {
      statsigStableID = Statsig.getStableID()
    } catch (e) {
      // Ignore errors to prevent them from blocking signup, but send to Sentry
      Sentry.captureException(e)
    }

    const enroll = await plusEnroll({
      variables: {
        accessCode: "",
        companyName,
        companyTitle: "",
        giftingTo: getGiftingTo(),
        effectiveDate: effectiveDate.toISOString(),
        agreementChecked,
        agreementCheckedAt: agreementCheckedAt?.toISOString() || null,
        source,
        workspaceInviteToken: token,
        utmParams: getUtmParms(),
        employeeEngagementTrialEnroll: isEE,
        statsigStableID,
      },
      refetchQueries: SKIP_REFETCH_ON_SIGNUP
        ? []
        : [{ query: ROOT_DATA_QUERY }],
      awaitRefetchQueries: !SKIP_REFETCH_ON_SIGNUP,
    })

    if (enroll?.data?.plusEnroll?.ok) {
      // Root should refresh
      if (token && enroll.data.plusEnroll.joinedWorkspace) {
        updateCurrentWorkspace({
          id: enroll.data.plusEnroll.joinedWorkspace.id,
          name: enroll.data.plusEnroll.joinedWorkspace.name,
          ttype: enroll.data.plusEnroll.joinedWorkspace.ttype,
        })
      } else {
        resetCurrentWorkspace()
      }

      const signupSource = token ? "Workspace Invite" : source
      const signupIsWorkspaceSource = token ? "workspaceInvite" : "nonWorkspace"

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "Plus_Signup_Enroll",
        signupSource,
        signupIsWorkspaceSource,
        enhanced_conversion_data: {
          email,
          address: {
            first_name: firstName,
            last_name: lastName,
          },
        },
      })

      track("Business - Signup - Enroll", {
        signupSource,
        signupIsWorkspaceSource,
        formVariant: "v1",
      })

      if (token) {
        if (redirectTo) {
          window.location.href = redirectTo
        } else {
          // history.push(generateRealmPath("plus", "/send"));
          // TEMP: Mitigate signup bug
          window.location.href = generateRealmPath("plus", "/send")
        }
      } else {
        if (redirectTo) {
          sessionStorage.setItem("signupRedirectTo", redirectTo)
        }
        if (isPro) {
          // Direct pro signup users to the subscribe path
          // history.push(generateRealmPath("plus", "/subscribe-pro?post-signup"));
          // TEMP: Mitigate signup bug
          window.location.href = generateRealmPath(
            "plus",
            "/subscribe-pro?post-signup",
          )
        } else {
          // Direct new users creating new orgs to the post-signup screens
          // history.push(generateRealmPath("plus", "/post-signup/workspace"));
          // TEMP: Mitigate signup bug
          window.location.href = generateRealmPath(
            "plus",
            "/post-signup/onboarding-redirect",
          )
        }
      }
    } else {
      if (enroll?.data?.plusEnroll?.error) {
        alert(enroll.data.plusEnroll.error)
      } else {
        alert("An unknown error occurred.")
      }
      _enrollmentInProgress.current = false
      track("Business - Signup - Error", {
        error: enroll?.data?.plusEnroll?.error,
      })
    }
  }

  // Autoenroll happens when the user clicks a Google or Microsoft sign-in
  // button, after which they are redirected back to this page with the query-
  // string ?autoenroll=true. In that case, automatically process the enrollment
  // when they land on this page and token fetching is complete.
  useEffect(() => {
    const qs = new URLSearchParams(search)

    if (
      tokenState.fetchComplete &&
      enrollmentStatus === PlusEnrollmentStatus.NONE &&
      qs.get("autoenroll") === "true"
    ) {
      handleEnroll()
    }
  }, [tokenState])

  const scrollToError = () => {
    const errorElement = document.querySelector(".text-input-error")

    if (errorElement) {
      errorElement.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }

  const handleSignUpErrors = (response?: Auth_SignUpMutation["authSignUp"]) => {
    if (response?.ok || response?.error == null) return

    setEmailError(response?.errorIsEmail ? response?.error : "")
    setPasswordError(response?.errorIsPassword ? response?.error : "")

    if (response?.error) {
      alert(response.error)
    }

    track("Business - Signup - Error", {
      error: response?.error,
    })

    scrollToError()
  }

  useEffect(() => {
    if (enrollmentStatus !== PlusEnrollmentStatus.NONE) {
      // This can happen because a user logged in to their existing, Plus-
      // enrolled account.
      //  - Redirect to the workspace invite accept page if a token is present.
      //  - Redirect to the browse page if a token is not present.
      if (token) {
        history.push(
          generateRealmPath("plus", `/workspace-invite#token=${token}`),
        )
      } else {
        if (redirectTo) {
          history.push(redirectTo)
        }
        history.push(generateRealmPath("plus", "/browse"))
      }
    }
  }, [])

  const fullSignup = () => {
    handleSignUpPlus({
      email,
      password,
      firstName,
      lastName,
      onFail: handleSignUpErrors,
      onSuccess: handleEnroll,
      skipRefetch: SKIP_REFETCH_ON_SIGNUP,
    })
  }

  const workspaceInviteData = workspaceInviteResult.data?.workspaceInvite

  // Note that workspaceInviteData is null, due to some error like invite expired,
  // then this will be undefined and display an empty space. /workspace-invite
  // redirects here when the user is not logged in, but before it does so, it
  // checks if the invite is valid. If it isn't valid, we should never get here,
  // so in normal cases this shouldn't ever be undefined if there's a token.
  const workspaceInviteInfo = workspaceInviteData && (
    <div tw="rounded-xl bg-white px-8 py-8 border border-primary-300 mb-12">
      <TopBoxPrimary>
        {workspaceInviteData.senderName} invited you to the{" "}
        {workspaceInviteData.workspaceName} workspace
      </TopBoxPrimary>
      <TopBoxSecondary>
        <p>Sign up for a Goody for Business account to join.</p>
        <p>
          Already have an account?{" "}
          <Link
            to={generateRealmPath("business", `/signin#redirect-invite`)}
            tw="cursor-pointer text-primary-600 hover:text-primary-800 transition"
            onClick={() => {
              if (token) {
                // If token exists, set the restore token before going to
                // Sign In, so it can be restored later.
                setRestoreToken(token)
              }
            }}
          >
            Sign in.
          </Link>
        </p>
      </TopBoxSecondary>
    </div>
  )

  const signUpText = isEE ? "Employee Engagement Trial" : "Sign up for free"
  const headerText = "Start gifting in a few clicks"

  const displaySamlSignUpButton =
    workspaceInviteData?.samlEnabled &&
    (workspaceInviteData?.requireSsoSetting ===
      OrganizationRequireSsoSetting.saml ||
      workspaceInviteData?.requireSsoSetting ===
        OrganizationRequireSsoSetting.disabled)

  const displayGoogleSignUpButton =
    workspaceInviteData?.requireSsoSetting ===
      OrganizationRequireSsoSetting.google ||
    workspaceInviteData?.requireSsoSetting ===
      OrganizationRequireSsoSetting.disabled ||
    !workspaceInviteData?.requireSsoSetting

  const displayMicrosoftSignUpButton =
    workspaceInviteData?.requireSsoSetting ===
      OrganizationRequireSsoSetting.microsoft ||
    workspaceInviteData?.requireSsoSetting ===
      OrganizationRequireSsoSetting.disabled ||
    !workspaceInviteData?.requireSsoSetting

  const requireSSOText = () => {
    const setting =
      workspaceInviteData?.requireSsoSetting ===
      OrganizationRequireSsoSetting.saml
        ? "SSO"
        : capitalize(workspaceInviteData?.requireSsoSetting)

    return "Your organization requires you to sign up with " + setting + "."
  }

  useEffect(() => {
    if (
      workspaceInviteData?.requireSsoSetting &&
      workspaceInviteData.requireSsoSetting !==
        OrganizationRequireSsoSetting.disabled
    ) {
      setHasRequireSSO(true)
    }
  }, [workspaceInviteData?.requireSsoSetting])

  return (
    <>
      <Helmet>
        <title>Sign up — Create a Free Business Account | Goody</title>
      </Helmet>
      <div tw="container mx-auto lg:grid grid-cols-10">
        <VerticalPurpleBanner tw="pb-0 col-span-4" compact={true}>
          <div className="text">
            <h1>
              <small tw="mb-4">{signUpText}</small>
              <span tw="text-4xl">{headerText}</span>
            </h1>
            {token ? (
              <p>Join your team’s workspace on Goody.</p>
            ) : (
              <p>
                Get a <em>$20 credit</em> when you sign up.
                <br />
                No credit card required.
              </p>
            )}
            <div tw="text-xl mt-12">More than 300 unique gift brands</div>
          </div>
          <img
            src={purpleBannerDeco}
            alt="Gifts and brands"
            css={[{ width: 613, maxWidth: "none" }]}
            tw="hidden lg:inline-block mt-4"
          />
        </VerticalPurpleBanner>
        <MainContainer tw="col-span-5 pt-8 col-span-6">
          {import.meta.env.MODE === "development" && (
            <div tw="fixed bottom-0 right-0 text-center">
              <button
                tw="px-4 py-3 bg-purple-100 hover:bg-purple-200 active:bg-purple-300 text-purple-700 rounded transition-colors"
                onClick={fillFormDev}
              >
                Fill form (dev)
              </button>
            </div>
          )}
          {!signedIn ? (
            <>
              {workspaceInviteInfo}
              {hasRequireSSO && <div tw="text-center">{requireSSOText()}</div>}
              {displaySamlSignUpButton && (
                <div tw="grid flex justify-center mt-6">
                  <SSOSignUpButton
                    email={workspaceInviteData?.email}
                    token={token}
                    origin={generateRealmPath("business", redirectPath)}
                  />
                </div>
              )}
              <div
                css={[
                  !hasRequireSSO &&
                    tw`grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-4`,
                ]}
              >
                {displayGoogleSignUpButton && (
                  <div css={[hasRequireSSO && tw`mt-6`]}>
                    <OmniAuthButton
                      key="google"
                      provider="google"
                      labelText="Sign up with"
                      origin={generateRealmPath("business", redirectPath)}
                      token={token}
                      trackClicks={true}
                      eventPrefix="Business - Signup - "
                    />
                  </div>
                )}
                {displayMicrosoftSignUpButton && (
                  <div css={[hasRequireSSO && tw`mt-6`]}>
                    <OmniAuthButton
                      key="microsoft"
                      provider="microsoft"
                      labelText="Sign up with"
                      origin={generateRealmPath("business", redirectPath)}
                      token={token}
                      trackClicks={true}
                      eventPrefix="Business - Signup - "
                    />
                  </div>
                )}
              </div>

              {!hasRequireSSO && (
                <>
                  <SectionTitle compact={true}>Name</SectionTitle>

                  <InputContainer compact={true}>
                    <TextInputField
                      type="text"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value)
                      }}
                      autoFocus={true}
                    />
                    <TextInputField
                      type="text"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value)
                      }}
                    />
                  </InputContainer>

                  <SectionTitle compact={true}>Work Email</SectionTitle>
                  <InputContainer compact={true}>
                    <TextInputField
                      type="email"
                      placeholder="Work email address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      error={emailError}
                      className="data-hj-suppress ph-no-capture fs-exclude"
                      message={
                        token ? (
                          ""
                        ) : (
                          <p>
                            A work email is required for Goody for Business.
                            Don't have one?{" "}
                            <Link
                              to={generateRealmPath("consumer_redirect", "")}
                              tw="text-primary-900"
                              tabIndex={-1}
                            >
                              Try Personal Gifting
                            </Link>
                          </p>
                        )
                      }
                    />
                  </InputContainer>
                  <SectionTitle compact={true}>Password</SectionTitle>
                  <InputContainer compact={true}>
                    <PasswordInputField
                      placeholder="Set a password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      className="data-hj-suppress ph-no-capture fs-exclude"
                      error={passwordError}
                      userInputs={[firstName, lastName, companyName]}
                    />
                  </InputContainer>
                </>
              )}
            </>
          ) : (
            <div tw="flex flex-row items-center bg-primary-050 rounded-md h-20 pl-8">
              <SignedInIcon />
              <span tw="text-lg text-primary-500 ml-6">
                Signed in as{" "}
                <span tw="font-bold">
                  {user?.firstName} {user?.lastName}
                </span>
              </span>
            </div>
          )}
          <label tw="flex flex-row mt-3">
            <div tw="text-sm opacity-50">
              By signing up, you agree to the Goody{" "}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.ongoody.com/terms"
                tw="underline"
              >
                Terms and Conditions
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.ongoody.com/privacy"
                tw="underline"
              >
                Privacy Policy
              </a>
              , and{" "}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.ongoody.com/static/terms/PlusTermsAndConditions.pdf"
                tw="underline"
              >
                Enterprise Service Agreement
              </a>
              .
            </div>
          </label>
          {!hasRequireSSO && (
            <div tw="flex justify-center items-center mt-6">
              <GradientButton
                disabled={loading || signUpLoading}
                onClick={signedIn ? handleEnroll : fullSignup}
                tw="px-10 py-4 text-2xl"
              >
                {loading || signUpLoading ? (
                  <LoaderInverted />
                ) : (
                  "Start gifting"
                )}
              </GradientButton>
            </div>
          )}
        </MainContainer>
      </div>
      <VerifyModal
        isOpen={verifyOpen}
        setClosed={() => setVerifyOpen(false)}
        onSuccess={handleEnroll}
        email={email}
        phone=""
        firstName={firstName}
        lastName={lastName}
      />
    </>
  )
}

export const BackgroundGradientContainer = styled.div`
  background: repeat-x
    linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    );
  ${tw`px-5 pb-24`}
  background-size: 100% 548px;

  @media (max-width: 768px) {
    background-size: 100% 800px;
  }
`

export const TopBoxPrimary = styled.div`
  ${tw`font-medium text-lg md:text-xl text-primary-600`}
`

export const TopBoxSecondary = styled.div`
  ${tw`text-gray-600 pt-3`}
  line-height: 1.875rem;
`

export const SectionTitle = styled.div<{
  compact?: boolean
}>`
  ${tw`font-medium text-lg text-primary-600 first:mt-0`}
  ${({ compact }) => (compact ? tw`mt-4` : tw`mt-9`)}
`

export const InputContainer = styled.div<{
  compact?: boolean
}>`
  ${tw`flex flex-row mt-2`}
  ${({ compact }) => (compact ? tw`gap-4` : tw`gap-8`)}
`

const SignedInIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 28V25.3333C21.3333 23.9188 20.7713 22.5623 19.7712 21.5621C18.771 20.5619 17.4144 20 15.9999 20H6.66659C5.2521 20 3.89554 20.5619 2.89535 21.5621C1.89516 22.5623 1.33325 23.9188 1.33325 25.3333V28"
        stroke={getColor("primary-500")}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 14.6667C14.2789 14.6667 16.6667 12.2789 16.6667 9.33333C16.6667 6.38781 14.2789 4 11.3333 4C8.38781 4 6 6.38781 6 9.33333C6 12.2789 8.38781 14.6667 11.3333 14.6667Z"
        stroke={getColor("primary-500")}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6665 14.6667L25.3332 17.3333L30.6665 12"
        stroke={getColor("primary-500")}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const PLUS_ENROLL_MUTATION = gql`
  mutation Landing_PlusEnroll(
    $accessCode: String!
    $companyName: String!
    $companyTitle: String!
    $giftingTo: [String!]
    $effectiveDate: ISO8601DateTime!
    $agreementChecked: Boolean!
    $agreementCheckedAt: ISO8601DateTime
    $source: String!
    $workspaceInviteToken: String
    $utmParams: UtmParams
    $employeeEngagementTrialEnroll: Boolean
    $statsigStableID: String
  ) {
    plusEnroll(
      accessCode: $accessCode
      companyName: $companyName
      companyTitle: $companyTitle
      giftingTo: $giftingTo
      effectiveDate: $effectiveDate
      agreementChecked: $agreementChecked
      agreementCheckedAt: $agreementCheckedAt
      source: $source
      workspaceInviteToken: $workspaceInviteToken
      utmParams: $utmParams
      employeeEngagementTrialEnroll: $employeeEngagementTrialEnroll
      statsigStableId: $statsigStableID
    ) {
      ok
      error
      joinedWorkspace {
        id
        name
        ttype
      }
    }
  }
`

export default SignUp
