import { gql, useMutation, useQuery } from "@apollo/client"
import { useFlow } from "@frigade/react"
import * as Sentry from "@sentry/react"
import hash from "hash-it"
import update, { Spec } from "immutability-helper"
import { E164Number } from "libphonenumber-js/max"
import { cloneDeep, isNil, omit, pick, pickBy } from "lodash-es"
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { toast } from "react-hot-toast"
import { Link, useHistory } from "react-router-dom"
import { Statsig } from "statsig-react"
import { useDebouncedCallback } from "use-debounce"

import { useGiftData } from "./giftData"
import { useFetchGift } from "./gifts"
import {
  BALANCE_PAYMENT_METHOD_ID,
  CORPORATE_ACCOUNT_PAYMENT_METHOD_ID,
} from "./payment"
import { usePaymentMethods } from "./paymentMethods"
import { ArrowRight } from "../../assets/icons"
import { ROOT_DATA_QUERY } from "../../graphql"
import useRecipientsData from "../../send/hooks/useRecipientsData"
import { GIFT_BATCH_RECIPIENTS_QUERY } from "../../track/queries"
import { ageAttested } from "../AgeVerificationModal"
import { funnelyticsTrack, track } from "../analytics"
import { client } from "../apollo"
import {
  formatPrice,
  isBlank,
  validateAndFormatPhoneE164,
  validateEmail,
} from "../format"
import {
  SCHEDULED_MAX_DAYS_AHEAD_EXTENDED,
  SCHEDULED_MAX_DAYS_AHEAD_STANDARD,
  getProduct,
  useNumScheduledGiftMaxDaysAhead,
} from "../gifts"
import {
  BatchRecipient,
  CurrentGift,
  SendPageMode,
  useGlobalState,
} from "../GlobalState"
import { generateRealmPath } from "../realm"
import { generateEmptyRecipients } from "../recipient"
import { errorToast, successToast } from "../toast"
import {
  convertDateAndTimeToDate,
  convertDateAndTimeToUTCDate,
  daysFromNow,
  toEndOfDayHawaii,
} from "../utilities"
import { omitSingleKey } from "../utils/omitSingleKey"

import { createHookContext } from "@/common/hooks/createHookContext"
import { disableOnboardingFlowAutoComplete } from "@/onboarding/ChecklistUI/useAutoComplete"
import { FLOW_ID } from "@/onboarding/OnboardingChecklist"
import { SEND_CREATE_GIFT_BATCHES_DRAFT } from "@/send/graphql/SendGiftBatchesDraftCreate"
import {
  BatchSendMethod,
  EmailDeliveryMethods,
  GiftBatchInput,
  GiftBatchRecipientError,
  GiftBatchRecipientInput,
  GiftRecipientType,
  GiftSeriesFilter,
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
  PaymentMethodCreditCardInput,
  SendCreateGiftBatchesDraftMutation,
  SendCreateGiftBatchesDraftMutationVariables,
  SendTypeEnum,
  Send_GetUserQuery,
  Send_GiftBatchCreateMutation,
  Send_GiftBatchCreateMutationVariables,
  Send_GiftBatchUpdateMutation,
  Send_GiftBatchUpdateMutationVariables,
  Send_PriceEstimateMutation,
  Send_PriceEstimateMutationVariables,
  Track_GiftBatchRecipientsQuery,
  Track_GiftBatchRecipientsQueryVariables,
  gift_meeting_setting,
} from "@/types/graphql-types"

const FRIGADE_FLOW_STEP_4_ID = "send"

// Removes the __typename from price estimates so our input doesn't complain
const removeTypename = (obj: any) => {
  if (Array.isArray(obj)) {
    obj.forEach(removeTypename)
  } else if (obj && typeof obj === "object") {
    delete obj.__typename
    Object.values(obj).forEach(removeTypename)
  }
  return obj
}

const paymentMethodTypeFromId = (paymentMethodId: string | null) => {
  switch (paymentMethodId) {
    case BALANCE_PAYMENT_METHOD_ID:
    case CORPORATE_ACCOUNT_PAYMENT_METHOD_ID:
      return paymentMethodId.toLowerCase()
    case null:
      return "none"
    default:
      return "credit_card"
  }
}

const NoWorkspaceError = (
  <Link
    to={generateRealmPath("plus", "/workspaces/new")}
    onClick={() => toast.dismiss()}
    tw="svg:inline flex flex-col gap-1 items-center font-semibold text-sm"
  >
    <div>You need to be part of a Workspace to send a gift.</div>
    <div>
      Click here to create a workspace{" "}
      <ArrowRight tw="stroke-[#8167FF] inline" />
    </div>
  </Link>
)

export const useSendReset = () => {
  const [, setRecipients] = useGlobalState("recipients")

  const resetRecipients = () => {
    setRecipients(generateEmptyRecipients(3))
  }

  const resetGiftState = () => {
    resetRecipients()
  }

  return { resetGiftState }
}

interface UseSendParams {
  isPlus: boolean
  sendV3: boolean
}

export interface SendErrors {
  product: boolean
  fromName: boolean
  message: boolean
  card: boolean
  recipients: boolean
  paymentMethod: boolean
}

const INITIAL_ERRORS = {
  product: false,
  fromName: false,
  message: false,
  card: false,
  recipients: false,
  paymentMethod: false,
}

const useSend = (
  { isPlus, sendV3 }: UseSendParams = {
    isPlus: false,
    sendV3: false,
  },
) => {
  const [errors, setErrors] = useState<SendErrors>(INITIAL_ERRORS)
  const clearError = (key: keyof SendErrors) => {
    setErrors((oldErrors) =>
      oldErrors[key] ? { ...oldErrors, [key]: false } : oldErrors,
    )
  }

  const clearErrors = () => setErrors(INITIAL_ERRORS)

  const [consumerRecipient, setConsumerRecipient] =
    useGlobalState("consumerRecipient")

  const [promoCode, setPromoCode] = useGlobalState("promoCode")
  const [, setRecipients] = useGlobalState("recipients")
  const [potentialRecipientsCount, setPotentialRecipientsCount] = useState(
    isPlus ? 0 : 1,
  )

  // We use effective recipients instead of the global recipients since
  // effective recipients take the send method into account. For link-only sends
  // for a single recipient, effective recipients will return only one recipient
  // even when there are more recipients in the global state.
  const { effectiveRecipients: recipients } = useRecipientsData()

  const { resetGiftState } = useSendReset()
  const { resetCurrentGift, currentGift, getCartInput } = useGiftData()

  const history = useHistory()
  const { fetchGift } = useFetchGift()

  const { data: userData, refetch: userDataRefetch } =
    useQuery<Send_GetUserQuery>(SEND_GET_USER)

  const statsigStableIDRef = useRef<string | null>(null)

  function getStatsigStableID() {
    if (!statsigStableIDRef.current) {
      try {
        statsigStableIDRef.current = Statsig.getStableID()
      } catch (e) {
        // Ignore errors to prevent them from blocking send, but send to Sentry
        Sentry.captureException(e)
      }
    }

    return statsigStableIDRef.current
  }

  const { flow: frigadeFlow } = useFlow(FLOW_ID)

  function completeOnboardingFlow() {
    if (frigadeFlow && frigadeFlow.isStarted && frigadeFlow.isVisible) {
      disableOnboardingFlowAutoComplete()
      frigadeFlow.steps.get(FRIGADE_FLOW_STEP_4_ID)?.complete()
      frigadeFlow.complete()
      track("Business - Onboarding Checklist - Complete All")
    }
  }

  const [priceEstimate, setPriceEstimate] = useState<
    Send_PriceEstimateMutation["priceEstimate"] | null
  >(null)
  const [priceEstimateMutation, { loading: priceEstimateLoading }] =
    useMutation<
      Send_PriceEstimateMutation,
      Send_PriceEstimateMutationVariables
    >(GET_PRICE_ESTIMATE_MUTATION)

  const [giftBatchCreate, { loading: giftBatchCreateLoading }] = useMutation<
    Send_GiftBatchCreateMutation,
    Send_GiftBatchCreateMutationVariables
  >(CREATE_GIFT_BATCH_MUTATION)

  const [giftBatchUpdate] = useMutation<
    Send_GiftBatchUpdateMutation,
    Send_GiftBatchUpdateMutationVariables
  >(UPDATE_GIFT_BATCH_MUTATION)

  const [giftBatchesDraftCreate, { loading: giftBatchesDraftCreateLoading }] =
    useMutation<
      SendCreateGiftBatchesDraftMutation,
      SendCreateGiftBatchesDraftMutationVariables
    >(SEND_CREATE_GIFT_BATCHES_DRAFT)

  const { refreshPaymentMethods } = usePaymentMethods()

  const product = getProduct(currentGift)

  const lastPriceEstimateVariables = useRef<any | null>(null)

  const noValidPromoCode =
    !promoCode || !!priceEstimate?.totalPriceEstimate?.promoCodeError

  const [consumerRecipientErrors, setConsumerRecipientErrors] = useState<
    Send_GiftBatchCreateMutation["giftBatchCreate"]["recipientErrors"]
  >([])

  // Re-run price estimate for non flex gifts when
  // recipient count changes, excluding direct send
  useEffect(() => {
    if (product?.isFlexGift && !product?.flexGiftPrice) {
      return
    }

    if (currentGift.sendMethod === BatchSendMethod.direct_send) {
      return
    }

    runPriceEstimate()
  }, [
    product,
    currentGift.cart,
    potentialRecipientsCount,
    currentGift.isGiftDispenser,
    currentGift.giftDispenserQuantity,
    currentGift.omitCredit,
    currentGift.sendMethod,
    currentGift.isSmartLink,
    currentGift.smartLinkQuantity,
  ])

  // Use a hash to determine when the recipients array changes, excluding the
  // errors field. This is because we want to re-run the price estimate when
  // the recipients change, but then there is a race condition if the errors
  // are modified after a price estimate runs and errors are returned that
  // are copied into the recipient array.
  const recipientsWithoutErrorsHash = useMemo(() => {
    return hash(
      recipients.map((r) => {
        const { errors, ...relevantFields } = r // Destructuring to exclude 'errors'
        return relevantFields
      }),
    )
  }, [recipients])

  // Re-run price estimate for direct send when recipients changes at all
  // (other than the errors field)
  useEffect(() => {
    if (currentGift.sendMethod !== BatchSendMethod.direct_send) {
      return
    }

    runPriceEstimate()
  }, [
    product,
    currentGift.cart,
    potentialRecipientsCount,
    recipientsWithoutErrorsHash,
    currentGift.isGiftDispenser,
    currentGift.giftDispenserQuantity,
    currentGift.omitCredit,
    currentGift.sendMethod,
  ])

  const getRecipientFirstName = () => consumerRecipient.name.split(" ")[0] ?? ""
  const getRecipientLastName = () => consumerRecipient.name.split(" ")[1] ?? ""
  const getRecipientPhone = () => {
    try {
      return String(validateAndFormatPhoneE164(consumerRecipient.recipientInfo))
    } catch (e) {
      return null
    }
  }

  const getRecipientEmail = () =>
    validateEmail(consumerRecipient.recipientInfo)
      ? consumerRecipient.recipientInfo
      : null

  // If we don't have link selected - tries to parse
  // the recipient as either email or phone
  const getRecipientType = () =>
    currentGift.recipientType === "link"
      ? GiftRecipientType.SELF_SEND
      : getRecipientEmail()
        ? GiftRecipientType.MANUAL_EMAIL
        : getRecipientPhone()
          ? GiftRecipientType.MANUAL_PHONE
          : null

  // Is the last price estimate calculated tax?
  const [priceEstimateIsCalculated, setPriceEstimateIsCalculated] =
    useState(false)

  // There are two types of price estimates:
  //
  // - Normal price estimate: Only uses the recipient count for the estimated total.
  // - Calculated price estimate: For Direct Send, when the user has entered in
  //   recipient addresses, we use those addresses to calculate the tax and total
  //   estimate.
  //
  // Due to the cost of running a calculated price estimate, a user must click the
  // "Calculate" button in the price estimate area to run the calculated price
  // estimate.
  //
  // When a user edits the recipient table, we re-run the price estimate with a
  // normal price estimate. This is because we don't know if the edits they made
  // could have affected the calculated price estimate, so we clear it.
  const runPriceEstimate = async (calculatedTaxEstimate?: boolean) => {
    if (!isPlus && isDirectSend) {
      calculatedTaxEstimate = true
    }

    if (product?.id) {
      const variables: Send_PriceEstimateMutationVariables = {
        giftCardAmount: product.giftCardAmount,
        recipientCount:
          currentGift.isGiftDispenser && currentGift.giftDispenserQuantity
            ? currentGift.giftDispenserQuantity
            : potentialRecipientsCount,
        flexGiftPrice: product?.flexGiftPrice,
        recipientType: currentGift.isGiftDispenser
          ? GiftRecipientType.GIFT_DISPENSER
          : null,
        isPlus,
        promoCode,
        cart: getCartInput(),
        scheduledGiftBatchID: currentGift.giftBatchId,
        omitCredit: currentGift.omitCredit,
        isSmartLink: currentGift.isSmartLink,
        smartLinkQuantity: currentGift.smartLinkQuantity,
      }

      if (calculatedTaxEstimate) {
        if (!isPlus) {
          if (consumerRecipient.mailingAddress) {
            variables["recipients"] = [
              {
                firstName: consumerRecipient.mailingAddress.firstName,
                lastName: consumerRecipient.mailingAddress.lastName,
                mailingAddress: {
                  ...omit(consumerRecipient.mailingAddress, [
                    "firstName",
                    "lastName",
                  ]),
                },
              },
            ]
          }
        } else {
          variables["recipients"] = convertPlusRecipients()
        }
      }

      setPriceEstimateIsCalculated(!!calculatedTaxEstimate)

      lastPriceEstimateVariables.current = variables

      const estimate = await priceEstimateMutation({ variables })

      // If we have multiple price estimates sent out at the same time, need to make sure we have the latest.
      if (lastPriceEstimateVariables.current !== variables) {
        return
      }

      if (estimate.data?.priceEstimate && estimate.data.priceEstimate.ok) {
        setPriceEstimate(estimate.data?.priceEstimate)

        if (estimate.data?.priceEstimate?.totalPriceEstimate?.promoCodeError) {
          setPromoCode(null)
        }

        // If recipientErrors is not null, it means that we validated recipients
        // and we want to replace all the errors with the ones from the server.
        // If it is null, then we did not validate recipients, so do not clear
        // the errors, otherwise they would go away the first time a user edits
        // a recipient with an error which would be janky.
        if (estimate.data?.priceEstimate?.recipientErrors) {
          // This update set collects all updates we'll be making to recipients
          // by the index to be passed to immutability-helper's update function.
          const updateSet: { [index: string]: Spec<BatchRecipient, never> } = {}

          // Clear all recipient errors
          for (let i = 0; i < recipients.length; i++) {
            updateSet[i] = {
              errors: {
                $set: {},
              },
            }
          }

          for (const error of estimate.data.priceEstimate.recipientErrors) {
            const idx = error.index
            if (recipients[idx]) {
              // @ts-ignore - TypeScript doesn't think errors exists
              if (updateSet[idx]?.errors?.$set) {
                // @ts-ignore - TypeScript doesn't think errors exists
                updateSet[idx].errors.$set[error.fieldName] = error.error
              } else {
                updateSet[idx] = {
                  errors: {
                    $set: {
                      [error.fieldName]: error.error,
                    },
                  },
                }
              }
            }
          }

          setRecipients(update(recipients, updateSet))
        }
      } else {
        alert("An error occurred when generating a price estimate.")
        Sentry.captureException(
          new Error(
            `Error generating price estimate: ${estimate?.data?.priceEstimate?.error}`,
          ),
        )
      }
    }
  }

  const debouncedRunCalculatedPriceEstimate = useDebouncedCallback(() => {
    runPriceEstimate(true)
  }, 500)

  useEffect(() => {
    if (currentGift.sendMethod === BatchSendMethod.direct_send) {
      debouncedRunCalculatedPriceEstimate()
    }
  }, [consumerRecipient.mailingAddress])

  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send

  const convertConsumerRecipients = (): GiftBatchRecipientInput[] => {
    return [
      {
        firstName: isDirectSend
          ? consumerRecipient.mailingAddress?.firstName || ""
          : getRecipientFirstName(),
        lastName: isDirectSend
          ? consumerRecipient.mailingAddress?.lastName || ""
          : getRecipientLastName(),
        phone: getRecipientPhone(),
        email: getRecipientEmail(),
        scheduledEventId: null,
        eventDate: null,
        salesforceRecipientData: null,
        mailingAddress: consumerRecipient.mailingAddress
          ? omit(consumerRecipient.mailingAddress, ["firstName", "lastName"])
          : null,
      },
    ]
  }

  // If we don't have errors, returns list of converted plus recipients
  const convertPlusRecipients = () => {
    const convertedRecipients: GiftBatchRecipientInput[] = []
    let validationErrors = false

    for (let i = 0; i < recipients.length; i++) {
      const recipient = recipients[i]

      let phone: E164Number | null = null

      if (!isBlank(recipient.phone)) {
        try {
          phone = validateAndFormatPhoneE164(recipient.phone as string)
        } catch (e) {
          validationErrors = true
          setRecipients(
            update(recipients, {
              [i]: {
                errors: {
                  $set: {
                    phone: "Phone is invalid (US numbers only).",
                  },
                },
              },
            }),
          )
        }
      }

      convertedRecipients.push({
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        phone: phone ? phone.toString() : null,
        email: recipient.email?.trim(),
        scheduledEventId: recipient.scheduledEventId,
        eventDate: recipient.eventDate,
        salesforceRecipientData: recipient.salesforceRecipientData,
        mailingAddress:
          currentGift.sendMethod === BatchSendMethod.direct_send
            ? recipient.mailingAddress
            : null,
      })
    }

    return validationErrors ? null : convertedRecipients
  }

  const validatePriceEstimate = () =>
    priceEstimate &&
    priceEstimate.cartPriceEstimate &&
    priceEstimate.productPriceEstimates &&
    priceEstimate.totalPriceEstimate

  // Rerun price estimate when promo code changes
  useEffect(() => {
    if (!!promoCode) {
      runPriceEstimate()
    } else if (
      priceEstimate?.totalPriceEstimate?.promoCodeError &&
      !promoCode
    ) {
      window.alert(
        priceEstimate.totalPriceEstimate?.promoCodeError ??
          "Could not validate promo code!",
      )
    }
  }, [promoCode])

  const numMaxDaysAhead = useNumScheduledGiftMaxDaysAhead()

  const validateScheduledSendDate = () => {
    if (!currentGift.scheduledSendOnOption) {
      return true
    }

    const scheduledSendDateAndTime = convertDateAndTimeToDate(
      currentGift.scheduledSendOnDate,
      currentGift.scheduledSendOnTime,
    )

    if (scheduledSendDateAndTime > daysFromNow(numMaxDaysAhead)) {
      const scheduledSendLimitText = (() => {
        switch (numMaxDaysAhead) {
          case SCHEDULED_MAX_DAYS_AHEAD_EXTENDED:
            return "a year away"
          case SCHEDULED_MAX_DAYS_AHEAD_STANDARD:
            return "3 months away"
        }
      })()

      alert(`Scheduled send date must be less than ${scheduledSendLimitText}.`)
      return false
    }

    return true
  }

  const validateCurrentGift = () => {
    const type = isPlus ? "Business" : "Consumer"

    const handleError = (errorMessage: string, shouldEstimatePrice = false) => {
      track(`${type} - Send - Send Gift Error`, {
        error: errorMessage,
      })
      alert(errorMessage)

      return false
    }

    // TODO: convert to new errors object entry
    if (!validateScheduledSendDate()) {
      track(`${type} - Send - Send Gift Error`, {
        error: "Scheduled send date is invalid",
      })

      return false
    }

    if (product && !priceEstimate) {
      runPriceEstimate()
      return handleError("Please wait for a price estimate before continuing.")
    }
    if (product && !validatePriceEstimate()) {
      runPriceEstimate()
      return handleError("Price estimate is invalid. Rerunning price estimate.")
    }

    const sendsNotifications =
      !isDirectSend || currentGift.landingPageSendNotifs
    const coveredByCredit =
      (priceEstimate?.totalPriceEstimate?.creditApplied || 0) > 0 &&
      priceEstimate?.totalPriceEstimate?.estGroupTotalHigh === 0
    const errors = {
      product: !product,
      fromName: sendsNotifications && isBlank(currentGift.fromName),
      message: sendsNotifications && isBlank(currentGift.message),
      card: sendsNotifications && currentGift.card === null,
      recipients:
        !currentGift.isSmartLink &&
        recipients.every(
          (recipient) =>
            isBlank(recipient.email) &&
            isBlank(recipient.firstName) &&
            isBlank(recipient.lastName),
        ),
      paymentMethod:
        !coveredByCredit && currentGift.autopayPaymentMethodID === null,
    }
    setErrors(errors)

    if (isPlus) {
      const valid = Object.values(errors).every((x) => !x)
      if (!valid)
        track(`${type} - Send - Send Validation Errors`, {
          errors: Object.keys(pickBy(errors)),
        })
      return valid
    }

    // consumer does not have error bubbles yet, continue with alert style validations

    if (!product) {
      return handleError(
        "A product is required. Try selecting one on the Browse tab.",
      )
    }

    if (
      !getRecipientType() &&
      currentGift.sendMethod !== BatchSendMethod.direct_send
    ) {
      return handleError(
        "Please enter in a valid email or phone number for the recipient.",
      )
    }

    return true
  }

  const clearCurrentGift = () => {
    resetCurrentGift()
    resetGiftState()
    setConsumerRecipient({ name: "", recipientInfo: "", mailingAddress: null })
    setPromoCode(null)
  }

  const getSharedVariables = ({
    convertedRecipients,
  }: {
    convertedRecipients: GiftBatchRecipientInput[]
  }) => ({
    cardId: currentGift.card?.id || null,
    message: currentGift.message,
    recipients: convertedRecipients,
    scheduledSendOn: currentGift.scheduledSendOnOption
      ? convertDateAndTimeToUTCDate(
          currentGift.scheduledSendOnDate,
          currentGift.scheduledSendOnTime,
        )
      : null,
    alcoholAgeVerificationAttested: ageAttested(),
    flexGiftPrice: product?.flexGiftPrice,
    giftCardAmount: product?.giftCardAmount,
    referringCategoryId: currentGift.referringCategoryID,
    cart: getCartInput(),
    omitCredit: currentGift.omitCredit,
    priceEstimates: priceEstimate
      ? removeTypename(
          cloneDeep(
            pick(
              priceEstimate,
              "cartPriceEstimate",
              "productPriceEstimates",
              "totalPriceEstimate",
            ),
          ),
        )
      : null,
    statsigStableId: getStatsigStableID(),
  })

  const getConsumerVariables = ({
    convertedRecipients,
    cardInput,
  }: {
    convertedRecipients: GiftBatchRecipientInput[]
    cardInput?: PaymentMethodCreditCardInput
  }): GiftBatchInput => {
    const vars = {
      id: null,
      expiresAt: null,
      fromName: null,
      emailDeliveryMethod: EmailDeliveryMethods.goody,
      batchName: null,
      swapEnabled: true,
      swapType: GiftSwapTypeEnum.swap_single,
      autopayPaymentMethodId:
        currentGift.autopayEnabled || isDirectSend
          ? currentGift.autopayPaymentMethodID
          : null,
      autopayPaymentMethodName:
        currentGift.autopayEnabled || isDirectSend
          ? currentGift.autopayPaymentMethodName
          : null,
      landingPageEnabled: false,
      landingPageSendNotifs: currentGift.landingPageSendNotifs,
      isGiftDispenser: false,
      giftDispenserQuantity: 0,
      internationalShippingTier: InternationalShippingTierEnum.disabled,
      salesforceGiftBatchData: null,
      giftCalendarSetting: gift_meeting_setting.NO_MEETING,
      sendMethod: currentGift.sendMethod,
      sendAudience: null,
      sendType: SendTypeEnum.standard,
      customEmailSubject: null,
      isSmartLink: false,
      smartLinkQuantity: 0,
      smartLinkApprovalRequired: false,
      promoCode,
      cardInput,
      settings: { giftCardsEnabled: false },
      ...getSharedVariables({ convertedRecipients }),
    }

    if (isDirectSend && !currentGift.landingPageSendNotifs) {
      vars.message = ""
      vars.cardId = null
    }

    return vars
  }

  const getPlusVariables = ({
    id,
    convertedRecipients,
  }: {
    id?: string
    convertedRecipients: GiftBatchRecipientInput[]
  }): GiftBatchInput => {
    const variables: GiftBatchInput = {
      id,
      expiresAt:
        (currentGift.expireAtOption !== "none" &&
          currentGift.expiresAt &&
          toEndOfDayHawaii(currentGift.expiresAt)) ||
        null,
      fromName: currentGift.fromName,
      emailDeliveryMethod: currentGift.emailDeliveryMethod,
      batchName: currentGift.batchName,
      swapEnabled: currentGift.swapType !== GiftSwapTypeEnum.swap_disabled,
      swapType: currentGift.swapType,
      autopayPaymentMethodId: currentGift.autopayPaymentMethodID,
      autopayPaymentMethodName: currentGift.autopayPaymentMethodName,
      landingPageEnabled: currentGift.landingPageEnabled,
      landingPageSendNotifs: currentGift.landingPageSendNotifs,
      isGiftDispenser: currentGift.isGiftDispenser,
      giftDispenserQuantity: currentGift.giftDispenserQuantity || 0,
      internationalShippingTier: currentGift.internationalShippingTier,
      settings: omitSingleKey("__typename", currentGift.settings),
      salesforceGiftBatchData: currentGift.salesforceGiftBatchData,
      giftCalendarSetting: currentGift.giftCalendarSetting,
      sendMethod: currentGift.sendMethod,
      sendAudience: currentGift.audience,
      sendType: SendTypeEnum.batch,
      customEmailSubject: currentGift.customEmailSubject,
      cardInput: null,
      giftTemplate: getGiftTemplateInput(currentGift),
      isSmartLink: currentGift.isSmartLink,
      smartLinkQuantity: currentGift.smartLinkQuantity,
      smartLinkApprovalRequired: currentGift.smartLinkApprovalRequired,
      topUpAmount: currentGift.topUpAmount,
      topUpPaymentMethodId: currentGift.topUpPaymentMethod?.id,
      ...getSharedVariables({ convertedRecipients }),
    }

    // Rectify variables for direct send for hidden UI elements
    if (currentGift.sendMethod === BatchSendMethod.direct_send) {
      variables["swapEnabled"] = false
      variables["swapType"] = GiftSwapTypeEnum.swap_disabled
      variables["expiresAt"] = null

      if (!currentGift.landingPageSendNotifs) {
        variables["fromName"] = ""
        variables["message"] = ""
        variables["cardId"] = null
      }
    }

    return variables
  }

  // Send consumer gift
  const sendConsumerGift = async (
    setDisplayAgeVerification: Dispatch<SetStateAction<boolean>>,
    cardInput?: PaymentMethodCreditCardInput,
    onError?: () => void,
  ) => {
    const convertedRecipients = convertConsumerRecipients()

    if (!validateCurrentGift()) {
      return
    }

    const result = await giftBatchCreate({
      variables: {
        giftBatch: getConsumerVariables({ convertedRecipients, cardInput }),
      },
      refetchQueries: [{ query: ROOT_DATA_QUERY }],
    })

    const batchCreateObject = result?.data?.giftBatchCreate

    if (batchCreateObject?.ok) {
      setConsumerRecipientErrors([])

      const giftSenderViewAccessId =
        batchCreateObject.giftBatch?.gifts?.[0]?.senderViewAccessId

      // cache gift for post send page
      if (giftSenderViewAccessId) {
        await fetchGift({
          variables: { senderViewAccessId: giftSenderViewAccessId },
        })
      }

      const giftTotalValue = !isNil(
        priceEstimate?.cartPriceEstimate.priceEstTotalLow,
      )
        ? priceEstimate!.cartPriceEstimate.priceEstTotalLow / 100.0
        : null
      const giftTotalValueString = giftTotalValue?.toFixed(2) || null
      let sendVia = null
      if (currentGift.recipientType === "link") {
        sendVia = "ViaLink"
      } else {
        if (consumerRecipient.recipientInfo) {
          if (consumerRecipient.recipientInfo.indexOf("@") >= 0) {
            sendVia = "ViaEmail"
          } else {
            sendVia = "ViaPhone"
          }
        }
      }

      userDataRefetch()

      clearCurrentGift()

      refreshPaymentMethods()

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "Web_Send_ConsumerGiftSend",
        sendVia,
        giftTotalValue,
        giftTotalValueString,
      })
      track("Consumer - Send - Send Gift", {
        sendVia,
        giftTotalValue,
        giftTotalValueString,
      })

      if (giftSenderViewAccessId) {
        history.push(
          generateRealmPath("consumer", `/send/${giftSenderViewAccessId}`),
        )
      } else {
        history.push(generateRealmPath("consumer", "/send/scheduled-gift"))
      }
    } else {
      if (batchCreateObject?.refreshPrice) {
        runPriceEstimate()
      }

      const errors = batchCreateObject?.errors
      const recipientErrors =
        batchCreateObject?.recipientErrors?.map(
          (el) =>
            "There was an issue with your recipient information: " + el.error,
        ) || []

      errors?.push(...recipientErrors)
      setConsumerRecipientErrors(batchCreateObject?.recipientErrors || [])

      if (errors && errors.length > 0) {
        if (
          errors.includes(
            "You have not yet attested to being over 21 years of age.",
          )
        ) {
          setDisplayAgeVerification(true)
        }
        alert(errors.join("\n"))

        track("Consumer - Send - Send Gift Error", {
          error: errors.join("\n"),
        })

        if (recipientErrors.length > 0) {
          onError && onError()
        }

        return
      } else {
        track("Consumer - Send - Send Gift Error", {
          error: "An unknown error occurred.",
        })

        alert("An unknown error occurred.")
      }
    }
  }

  const sendPlusGift = async ({
    onValidationError,
    onError,
    onSuccess,
    sendPageMode,
    setSendPageMode,
    setDisplayAgeVerification,
    isSalesforceSend,
  }: {
    onValidationError?: () => void
    onError: () => void
    onSuccess: () => void
    sendPageMode: SendPageMode
    setSendPageMode: (sendPageMode: SendPageMode) => void
    setDisplayAgeVerification: Dispatch<SetStateAction<boolean>>
    isSalesforceSend: boolean
  }) => {
    const convertedRecipients = convertPlusRecipients()

    if (!convertedRecipients) {
      track("Business - Send - Send Gift Error", {
        error: "Converted recipients resulted in an error",
      })

      onError()
      return
    }

    if (!validateCurrentGift()) {
      onValidationError?.()
      return
    }

    let statsigStableID: string | null = null
    try {
      statsigStableID = Statsig.getStableID() || null
    } catch (e) {
      // No issue if it fails
    }

    // Gift batch must have an id and payment method selected when being updated
    // If we get to this point and either are missing then something went wrong,
    // so error out
    if (sendPageMode === "updateGiftBatch") {
      if (currentGift.giftBatchId === null) {
        alert("An error has occurred. This gift batch cannot be updated.")
        return
      }
    }

    if (product) {
      // Variables for either create or update gift batch mutation
      let batch
      let res

      // sendPageMode global state determines whether to do an update or create mutation
      if (sendPageMode === "updateGiftBatch") {
        if (currentGift.giftBatchId) {
          batch = await giftBatchUpdate({
            variables: {
              giftBatch: getPlusVariables({
                id: currentGift.giftBatchId,
                convertedRecipients,
              }),
            },
            refetchQueries: [{ query: ROOT_DATA_QUERY }],
          })
        } else {
          alert("An error occurred")
          return
        }
      } else {
        batch = await giftBatchCreate({
          variables: {
            giftBatch: getPlusVariables({ convertedRecipients }),
            isSalesforceSend,
          },
          refetchQueries: [{ query: ROOT_DATA_QUERY }],
        })
      }

      // Check that the mutation completed successfully before proceeding
      if (sendPageMode === "updateGiftBatch") {
        // @ts-ignore
        if (!batch.data?.giftBatchUpdate) {
          alert("An error occurred.")
          return
        }
      } else {
        // @ts-ignore
        if (!batch.data?.giftBatchCreate) {
          alert("An error occurred.")
          return
        }
      }

      if (sendPageMode === "updateGiftBatch") {
        // @ts-ignore
        res = batch.data.giftBatchUpdate
      } else {
        // @ts-ignore
        res = batch.data.giftBatchCreate
      }

      if (res.ok) {
        // Populate query cache with recipients before navigating to recipients page
        // Not needed for a scheduled send
        if (!res.isScheduledSend) {
          await client.query<
            Track_GiftBatchRecipientsQuery,
            Track_GiftBatchRecipientsQueryVariables
          >({
            query: GIFT_BATCH_RECIPIENTS_QUERY,
            variables: { id: res.id!, page: 1, filter: GiftSeriesFilter.all },
            fetchPolicy: "network-only",
          })
        }

        userDataRefetch()

        // If sending inside Salesforce, tell parent to close window.
        const isSalesforceSend = currentGift.isSalesforceSend ?? false
        if (isSalesforceSend) {
          // @ts-ignore
          const events = batch?.data?.giftBatchCreate?.salesforceEventData ?? []

          // Only send message if events exist. They can be empty for a scheduled send.
          if (events.length > 0) {
            window.parent?.postMessage({ status: "SUCCESS", events }, "*")
          }
        }

        // set current gift back to defaults
        clearCurrentGift()

        window.scrollTo(0, 0)

        // Display different toasts and perform different redirects based on
        // whether this was a scheduled send or not
        if (res.isScheduledSend) {
          history.push(generateRealmPath("plus", `/track`))
          successToast("Gift scheduled!")
        } else {
          history.push(
            generateRealmPath("plus", `/track/${res.id}/recipients`),
            { postSend: true },
          )
          onSuccess()
        }

        const giftBatchTotalValueCents = !isNil(
          priceEstimate?.totalPriceEstimate.estGroupTotalLow,
        )
          ? priceEstimate?.totalPriceEstimate.estGroupTotalLow!
          : null
        const giftBatchTotalValue = !isNil(
          priceEstimate?.totalPriceEstimate.estGroupTotalLow,
        )
          ? priceEstimate?.totalPriceEstimate.estGroupTotalLow! / 100.0
          : null
        const giftBatchTotalValueString =
          giftBatchTotalValue?.toFixed(2) || null
        const giftBatchRecipientCount = potentialRecipientsCount

        const giftBatchProductPriceCents = !isNil(
          priceEstimate?.cartPriceEstimate.priceProduct,
        )
          ? priceEstimate!.cartPriceEstimate.priceProduct
          : null
        const giftBatchProductPrice = !isNil(
          priceEstimate?.cartPriceEstimate.priceProduct,
        )
          ? priceEstimate!.cartPriceEstimate.priceProduct / 100.0
          : null
        const giftBatchProductPriceString =
          giftBatchProductPrice?.toFixed(2) || null
        const giftBatchProductID = product.id
        const giftBatchIsScheduledSend = res.isScheduledSend || false

        const giftBatchProductPriceCentsTimesRecipients =
          giftBatchProductPriceCents != null
            ? giftBatchProductPriceCents * 100 * giftBatchRecipientCount
            : null

        const giftBatchAutopayPaymentMethodType = paymentMethodTypeFromId(
          currentGift.autopayPaymentMethodID,
        )

        const paidWithRecentlyAddedBalance =
          giftBatchAutopayPaymentMethodType === "balance" &&
          currentGift.hasRecentlyAddedBalance
        const paidWithRecentlyAddedCreditCard =
          giftBatchAutopayPaymentMethodType === "credit_card" &&
          currentGift.hasRecentlyAddedCreditCard

        const topUpAmount = currentGift.topUpAmount
        const topUpPaymentMethod = currentGift.topUpPaymentMethod

        if (
          topUpAmount &&
          topUpPaymentMethod &&
          giftBatchAutopayPaymentMethodType === "balance"
        ) {
          track("Business - Send - Top Up Account Balance", {
            topUpAmount: formatPrice(topUpAmount),
            topUpBalanceType: "credit_card",
            giftBatchTotalValueHigh: priceEstimate?.totalPriceEstimate
              .estGroupTotalHigh
              ? formatPrice(priceEstimate?.totalPriceEstimate.estGroupTotalHigh)
              : 0,
            giftBatchId: res.id,
          })
        }

        if (sendPageMode === "createGiftBatch") {
          completeOnboardingFlow()
        }

        resetCurrentGift()
        resetGiftState()

        // Restore send page mode to create, since this could have just been
        // a scheduled gift we were editing.
        setSendPageMode("createGiftBatch")

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "Plus_Send_GiftBatchSend",
          giftBatchRecipientCount,
          giftBatchTotalValue,
          giftBatchTotalValueString,
          giftBatchProductPrice,
          giftBatchProductPriceString,
          giftBatchProductID,
          giftBatchIsScheduledSend,
          giftBatchAutopayPaymentMethodType,
        })
        track("Business - Send - Send Gift Batch", {
          giftBatchRecipientCount,
          giftBatchTotalValue,
          giftBatchTotalValueString,
          giftBatchProductPrice,
          giftBatchProductPriceString,
          giftBatchProductID,
          giftBatchIsScheduledSend,
          giftBatchAutopayPaymentMethodType,
          sendV3,
          paidWithRecentlyAddedBalance,
          paidWithRecentlyAddedCreditCard,
        })

        funnelyticsTrack("__commerce_action__", {
          __total_in_cents__: giftBatchTotalValueCents,
          product_price_times_recipients:
            giftBatchProductPriceCentsTimesRecipients,
        })
      } else {
        const errors = res.errors ?? []
        const recipientErrors =
          res.recipientErrors?.map(
            (el: GiftBatchRecipientError) =>
              "There was an issue with your recipient information: " + el.error,
          ) || []

        errors?.push(...recipientErrors)

        if (errors && errors.length > 0) {
          track("Business - Send - Send Gift Error", {
            error: errors.join("\n"),
          })

          if (
            res.errors.includes(
              "You have not yet attested to being over 21 years of age.",
            )
          ) {
            setDisplayAgeVerification(true)
          }

          if (
            res.errors.includes(
              "You need to be part of a Workspace to send a gift. Please create a Workspace.",
            )
          ) {
            errorToast(NoWorkspaceError, {
              duration: Infinity,
              closeOnClick: true,
              style: {
                maxWidth: "fit-content",
              },
            })
          } else {
            alert(res.errors.join("\n"))
          }
        }

        if (res.refreshPrice) {
          runPriceEstimate()
        }

        if (res.recipientErrors) {
          // This update set collects all updates we'll be making to recipients
          // by the index to be passed to immutability-helper's update function.
          const updateSet: { [index: string]: Spec<BatchRecipient, never> } = {}

          // Clear all recipient errors
          for (let i = 0; i < recipients.length; i++) {
            updateSet[i] = {
              errors: {
                $set: {},
              },
            }
          }

          for (const error of res.recipientErrors) {
            const idx = error.index
            if (recipients[idx]) {
              // @ts-ignore - TypeScript doesn't think errors exists
              if (updateSet[idx]?.errors?.$set) {
                // @ts-ignore - TypeScript doesn't think errors exists
                updateSet[idx].errors.$set[error.fieldName] = error.error
              } else {
                updateSet[idx] = {
                  errors: {
                    $set: {
                      [error.fieldName]: error.error,
                    },
                  },
                }
              }
            }
          }

          setRecipients(update(recipients, updateSet))

          onError()
        }
      }
    }
  }

  const saveGiftDraft = async () => {
    const result = await giftBatchesDraftCreate({
      variables: {
        giftBatch: getPlusVariables({
          convertedRecipients: convertPlusRecipients() || [],
        }),
      },
    })
    return !!result?.data?.giftBatchesDraftCreate?.ok
  }

  return {
    sendConsumerGift,
    sendPlusGift,
    priceEstimate,
    runPriceEstimate,
    priceEstimateLoading,
    priceEstimateIsCalculated,
    promoCode,
    setPromoCode,
    noValidPromoCode,
    giftBatchCreateLoading,
    clearCurrentGift,
    getPlusVariables,
    validateCurrentGift,
    convertPlusRecipients,
    userData,
    consumerRecipientErrors,
    setPotentialRecipientsCount,
    errors,
    clearError,
    clearErrors,
    saveGiftDraft,
    giftBatchesDraftCreateLoading,
  }
}

const getGiftTemplateInput = (currentGift: CurrentGift) => {
  if (currentGift.giftTemplate === null) {
    return null
  }

  return {
    id: currentGift.giftTemplate.id,
    cardId: currentGift.giftTemplate.card.id,
    message: currentGift.giftTemplate.message,
    productIds: currentGift.giftTemplate.products.map((product) => {
      return product.id
    }),
  }
}

export const GET_PRICE_ESTIMATE_MUTATION = gql`
  mutation Send_PriceEstimate(
    $productID: ID
    $isPlus: Boolean!
    $recipientCount: Int
    $flexGiftPrice: Int
    $promoCode: String
    $recipientType: GiftRecipientType
    $giftDispenserQuantity: Int
    $giftCardAmount: Int
    $bookEan: String
    $cart: [CartProductInput!]
    $scheduledGiftBatchID: ID
    $omitCredit: Boolean
    $recipients: [GiftBatchRecipientInput!]
    $isSmartLink: Boolean
    $smartLinkQuantity: Int
  ) {
    priceEstimate(
      productId: $productID
      isPlus: $isPlus
      plusRecipients: $recipientCount
      flexGiftPrice: $flexGiftPrice
      promoCode: $promoCode
      recipientType: $recipientType
      giftDispenserQuantity: $giftDispenserQuantity
      giftCardAmount: $giftCardAmount
      bookEan: $bookEan
      cart: $cart
      scheduledGiftBatchId: $scheduledGiftBatchID
      omitCredit: $omitCredit
      recipients: $recipients
      isSmartLink: $isSmartLink
      smartLinkQuantity: $smartLinkQuantity
    ) {
      ok
      error
      plusPriceEstimate {
        recipients
        creditApplied
        creditAvailable
        creditAvailableToApply
        estGroupTotalLow
        estGroupTotalHigh
        estPreCreditSubtotalLow
        estPreCreditSubtotalHigh
        balance
      }
      effectiveBalanceData {
        effectiveBalance
        scheduledGiftBatchReservedBalance
      }
      productPriceEstimates {
        isFlexGift
        priceProduct
        priceShipping
        pricePreTax
        priceProcessingFee
        priceEstTaxLow
        priceEstTaxHigh
        priceEstTotalLow
        priceEstTotalHigh
      }
      cartPriceEstimate {
        priceProduct
        priceShipping
        pricePreTax
        priceProcessingFee
        priceEstTaxLow
        priceEstTaxHigh
        priceEstTotalLow
        priceEstTotalHigh
      }
      totalPriceEstimate {
        recipients
        creditApplied
        creditAvailable
        creditAvailableToApply
        estGroupTotalLow
        estGroupTotalHigh
        estPreCreditSubtotalLow
        estPreCreditSubtotalHigh
        promoCodeError
        taxText
        estTaxCalculated
      }
      recipientErrors {
        index
        fieldName
        error
        recipientName
      }
    }
  }
`

export const SEND_GET_USER = gql`
  query Send_GetUser {
    me {
      id
      firstName
      hasPaidPlan
      lastName
      giftBatchTypeCount
      credit
      plusEnrollmentUpgradeRequested
      hasSendingInProgressGiftBatch
      calendarIntegration {
        calendlyApiUrl
        giftSetting
      }
    }
  }
`

export const CREATE_GIFT_BATCH_MUTATION = gql`
  mutation Send_GiftBatchCreate(
    $giftBatch: GiftBatchInput!
    $isSalesforceSend: Boolean
  ) {
    giftBatchCreate(
      giftBatch: $giftBatch
      isSalesforceSend: $isSalesforceSend
    ) {
      ok
      errors
      recipientErrors {
        error
        fieldName
        index
      }
      refreshPrice
      id
      isScheduledSend
      giftBatch {
        id
        gifts {
          senderViewAccessId
        }
      }
      salesforceEventData {
        brand
        brandAndProduct
        campaignName
        eventType
        giftPriceFinal
        giftPriceInitialEstimate
        goodyGiftBatchId
        goodyGiftId
        isSwapped
        meetingScheduled
        orgId
        preSwapBrand
        preSwapProduct
        product
        productCategory
        productId
        productImageUrl
        recipientEmail
        salesforceContactId
        salesforceLeadId
        salesforceOpportunityId
        salesforceUserId
        sendSource
        senderEmail
        senderName
        teamName
        thankYouNote
        timestamp
      }
    }
  }
`

export const UPDATE_GIFT_BATCH_MUTATION = gql`
  mutation Send_GiftBatchUpdate($giftBatch: GiftBatchInput!) {
    giftBatchUpdate(giftBatch: $giftBatch) {
      ok
      errors
      recipientErrors {
        error
        fieldName
        index
      }
      refreshPrice
      id
      isScheduledSend
    }
  }
`

export const {
  Provider: BusinessSendProvider,
  useHook: useBusinessSend,
  useHookUnsafe: useBusinessSendUnsafe,
} = createHookContext("BusinessSend", useSend, { isPlus: true, sendV3: true })

export const { Provider: ConsumerSendProvider, useHook: useConsumerSend } =
  createHookContext("ConsumerSend", useSend, { isPlus: false, sendV3: false })
