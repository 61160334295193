import tw, { styled } from "twin.macro"

import ShippingTag from "../components/shipping/ShippingTag/ShippingTag"
import { useShippingCountriesGroup } from "../hooks/useShippingCountriesGroup"

import { ReactComponent as ChevronRight } from "@/assets/icons/chevron-right-gray.svg"
import Image from "@/common/Image"
import { ReactComponent as ShippingIcon } from "@/common/images/icon-shipping.svg"
import BrandValueBadge, {
  isBrandValueKey,
  sortBrandValuesBySelected,
} from "@/store/components/BrandValueBadge"
import {
  ShippingCountryGroup,
  Store_ProductStoreTileFragment,
} from "@/types/graphql-types"

interface Props {
  onClick: () => void
  href: string
  imageUrl?: string | null
  priceLabel?: string | null
  shippingLabel?: string | null
  name?: string | null
  subtitle?: string | null
  brandName?: string | null
  brandValues?: string[]
  brandValuesFilterValue?: string[]
  showShippingPrice?: boolean
  variantsLabel?: string | null
  isGiftCardProduct?: boolean
  shouldScale?: boolean
  product?: Store_ProductStoreTileFragment
  shippingCountryGroup: ShippingCountryGroup
}

export default function StoreTile({
  onClick,
  href,
  imageUrl,
  priceLabel,
  shippingLabel,
  name,
  subtitle,
  brandName,
  brandValues,
  brandValuesFilterValue,
  showShippingPrice,
  variantsLabel,
  isGiftCardProduct,
  shouldScale,
  product,
  shippingCountryGroup,
}: Props) {
  const {
    showShippingTag,
    displayDomesticShippingTag,
    displayGlobalShippingTag,
    multipleCountriesSelected,
  } = useShippingCountriesGroup({ shippingCountryGroup })

  // We display the shipping row under a product if:
  const displayShippingOrSubtitleRow =
    // The shipping price is being shown
    (showShippingPrice && shippingLabel) ||
    // The shipping tag (international shipping options) is hidden but it is
    // a gift option and has a subtitle
    (!showShippingTag && !product && !!subtitle) ||
    // We are showing the shipping tag
    (showShippingTag &&
      (displayDomesticShippingTag || displayGlobalShippingTag))

  const hideShippingInfo =
    multipleCountriesSelected &&
    displayDomesticShippingTag &&
    displayGlobalShippingTag
  const shippingInfo = !hideShippingInfo &&
    showShippingPrice &&
    shippingLabel && (
      <div tw="font-text flex flex-row items-end items-center text-gray-400">
        <ShippingIcon tw="stroke-current text-gray-300 flex-shrink-0" />
        <div tw="ml-1.5">
          {!displayGlobalShippingTag && shippingLabel}
          {displayGlobalShippingTag &&
            (shippingLabel !== "Free" ? `${shippingLabel} + Global` : "Global")}
        </div>
      </div>
    )

  return (
    <TileContainer
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      href={href}
    >
      {imageUrl && (
        <Image
          tw="overflow-hidden rounded-xl object-cover w-full"
          css={[
            { aspectRatio: isGiftCardProduct ? "16 / 10" : "5 / 4" },
            shouldScale && tw`object-contain bg-white`,
          ]}
          src={imageUrl}
          alt=""
          width={460}
        />
      )}

      {isGiftCardProduct ? (
        <div tw="pt-2 font-medium text-center">{name}</div>
      ) : (
        <>
          <div tw="flex flex-col mt-5">
            <div tw="flex flex-row justify-between">
              <h3 tw="font-medium text-lg">{name}</h3>

              {priceLabel && <div tw="font-text text-lg">{priceLabel}</div>}
            </div>

            {brandName ? (
              <>
                <div tw="flex flex-row justify-between items-start mt-1">
                  <Subtitle tw="pt-0.5">{brandName}</Subtitle>

                  {shippingInfo}
                </div>

                <div tw="my-2">
                  <ShippingTag
                    shippingCountryGroup={shippingCountryGroup}
                    variant="compact"
                  />
                </div>

                {subtitle && <Subtitle>{subtitle}</Subtitle>}
              </>
            ) : (
              <>
                {displayShippingOrSubtitleRow && (
                  <div tw="flex flex-row space-x-2 justify-between items-start my-2">
                    <div>
                      <ShippingTag
                        shippingCountryGroup={shippingCountryGroup}
                        variant="compact"
                      />

                      {!showShippingTag && !product && subtitle && (
                        <Subtitle tw="flex-shrink">{subtitle}</Subtitle>
                      )}
                    </div>

                    {shippingInfo}
                  </div>
                )}

                {(showShippingTag || (!showShippingTag && product)) && (
                  <Subtitle tw="mt-1.5">{subtitle}</Subtitle>
                )}
              </>
            )}
          </div>
        </>
      )}

      {variantsLabel && (
        <div tw="pt-2 flex flex-row items-center gap-1 text-gray-450 text-[15px]">
          {variantsLabel}
          <ChevronRight tw="w-4 h-4 pt-[2px]" />
        </div>
      )}
      {brandValues &&
        brandValues.length > 0 &&
        brandValuesFilterValue &&
        brandValuesFilterValue.length > 0 && (
          <div tw="flex flex-wrap -mx-1 pt-3">
            {sortBrandValuesBySelected(
              brandValues,
              brandValuesFilterValue || [],
            ).map(
              (brandValue) =>
                isBrandValueKey(brandValue) && (
                  <BrandValueBadge
                    active={brandValuesFilterValue.includes(brandValue)}
                    value={brandValue}
                    small={true}
                    key={brandValue}
                  />
                ),
            )}
          </div>
        )}
    </TileContainer>
  )
}

const TileContainer = styled.a`
  ${tw`
    relative z-[1] w-full h-full lg:rounded-2xl transition-colors outline-none transition-all duration-150 ease-out block
    active:scale-[0.98]
    after:(content[''] z-[-10] absolute inset-[-1rem] rounded-2xl bg-transparent border border-transparent transition-all duration-300 ease-out)
  `}

  &:hover {
    &:after {
      ${tw`bg-[#FCFBFF]! border-primary-new-100`};
    }
  }

  &:active {
    &:after {
      ${tw`after:bg-primary-new-000`};
    }
  }
`

const Subtitle = tw.div`text-gray-600 text-[15px]`
