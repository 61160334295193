import "twin.macro"
import { gql } from "@apollo/client"

import StoreTile from "./StoreTile"

import { formatPrice, formatPriceMinRange } from "@/common/format"
import { GIFT_OPTION_PREVIEW_FRAGMENT } from "@/store/GiftOption/graphql/GiftOptionPreviewFragment"
import { giftOptionIsGiftCard } from "@/store/utils"
import { flex_gift_product_price_type } from "@/types/graphql-types"
import { Store_GiftOptionStoreTileFragment } from "@/types/graphql-types"

interface Props {
  giftOption: Store_GiftOptionStoreTileFragment
  onClick: () => void
  href: string
  brandValuesFilterValue?: string[]
  minDisplayPrice: number
}

export default function GiftOptionStoreTile({
  onClick,
  href,
  brandValuesFilterValue,
  giftOption,
  minDisplayPrice,
}: Props) {
  return (
    <StoreTile
      onClick={onClick}
      href={href}
      imageUrl={giftOption?.primaryImage?.imageLarge?.url}
      priceLabel={formattedPrice(giftOption, minDisplayPrice)}
      shippingLabel={getShippingLabel(giftOption)}
      name={giftOption.name}
      subtitle={giftOption.subtitle}
      brandValues={giftOption.brand.brandValues}
      brandValuesFilterValue={brandValuesFilterValue}
      showShippingPrice={true}
      shippingCountryGroup={giftOption.shippingCountryGroup}
    />
  )
}

const formattedPrice = (
  giftOption: Store_GiftOptionStoreTileFragment,
  totalPriceMin: number,
) => {
  const giftOptionIsBook = giftOption.name === "Gift any book"
  const productPriceMin = totalPriceMin - (giftOption.brand.shippingPrice || 0)
  const filteredPrices = giftOption.prices.filter(
    (price: number) => price >= productPriceMin,
  )
  const displayPriceMin = Math.min(...filteredPrices)

  if (giftOption.priceMin == null || giftOptionIsBook) {
    return null
  }

  if (filteredPrices.length === 0 && giftOption.prices.length > 0) {
    // No prices in this gift option are greater than totalPriceMin. This really
    // can only happen when we have a product that has bypass_min_price_filter
    // set (typically gifts of choice), where the product and gift option is
    // displayed for all price points. In this case, just display the lowest
    // price in the gift option.
    return formatPriceMinRange(
      Math.min(...giftOption.prices),
      giftOption.priceMax ?? null,
      // We always display the + if it's a flex gift
      giftOption.isFlexGift,
    )
  }

  if (giftOption.isFlexGift) {
    return `${formatPrice(displayPriceMin)}+`
  }

  if (giftOptionIsGiftCard(giftOption)) {
    return "$5+"
  }

  return formatPriceMinRange(displayPriceMin, giftOption.priceMax ?? null)
}

function getShippingLabel(giftOption: Store_GiftOptionStoreTileFragment) {
  if (
    giftOption.brand.shippingPrice === null ||
    giftOption.brand.shippingPrice === undefined
  ) {
    return null
  }

  const freeShippingMinimumGiftOption =
    (giftOption.brand.freeShippingMinimum &&
      giftOption.priceMin &&
      giftOption.priceMin >= giftOption.brand.freeShippingMinimum) ||
    false

  let shippingLabel = formatPrice(giftOption.brand.shippingPrice)

  if (
    giftOption.isFlexGift &&
    giftOption.products[0]?.swapStoreSettings?.priceType ===
      flex_gift_product_price_type.VARIABLE
  ) {
    shippingLabel = "Included"
  } else if (
    !freeShippingMinimumGiftOption &&
    giftOption.brand.freeShippingMinimum &&
    giftOption.brand.shippingPrice !== 0
  ) {
    shippingLabel = formatPrice(giftOption.brand.shippingPrice) + " or free"
  } else if (
    giftOption.brand.shippingPrice === 0 ||
    freeShippingMinimumGiftOption
  ) {
    shippingLabel = "Free"
  }

  return shippingLabel
}

export const GIFT_OPTION_STORE_TILE_FRAGMENT = gql`
  fragment Store_GiftOptionStoreTile on GiftOption {
    id
    name
    subtitle
    prices
    priceMin
    priceMax
    isFlexGift
    slug
    brand {
      id
      name
      brandValues
      shippingPrice
      freeShippingMinimum
      slug
      segments
    }
    primaryImage {
      id
      imageLarge {
        url
        width
        height
      }
    }
    products {
      id
      price
      swapStoreSettings {
        priceType
      }
    }
    singleProductSlug
    ...Store_GiftOptionPreview
  }

  ${GIFT_OPTION_PREVIEW_FRAGMENT}
`
