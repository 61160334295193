import tw from "twin.macro"

import Checkbox from "@/common/Checkbox"
import { CountryFlag } from "@/common/CountryFlag"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  country: ShippingCountry
  selected: boolean
  onSingleSelectClick: (country: ShippingCountry) => void
  onMultiSelectClick: (country: ShippingCountry) => void
  withProUpsell?: boolean
}

export const CountrySelectorItem = ({
  country,
  selected,
  onSingleSelectClick,
  onMultiSelectClick,
  withProUpsell,
}: Props) => {
  return (
    <div
      css={[
        tw`flex justify-between items-center rounded-lg cursor-pointer`,
        selected
          ? tw`bg-primary-new-050`
          : tw`hover:(bg-gray-100) active:(bg-gray-150)`,
      ]}
      onClick={() => onSingleSelectClick(country)}
    >
      <div tw="flex flex-row gap-3 items-center py-2 px-4">
        <CountryFlag code={country.code} size="m" />
        <span tw="text-black text-base font-normal">{country.name}</span>
      </div>

      {withProUpsell && (
        <div tw="text-gray-400 text-sm font-normal flex-1 flex justify-end">
          Pro
        </div>
      )}

      {/*
        Using the div as the onClick event to add some extra margin
        to the button. This is suppose to make it easier for the customer
        to click the checkbox and not accidentally click the country name.
      */}
      <div
        tw="py-2 px-3"
        onClick={(e) => {
          e.stopPropagation()
          onMultiSelectClick(country)
        }}
      >
        <Checkbox selected={selected} />
      </div>
    </div>
  )
}
